import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { encryptAndStore, decryptAndRetrieve,} from "../../Utils/Encryption/Encryption";

const user = decryptAndRetrieve("user");
const initialState = {
  user: user ? user : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//////////////////////Sign Up///////////////////////////
export const SignUp_User = createAsyncThunk(
  "auth/SignUp",
  async (userData, thunkAPI) => {
    try {
      // const API_URL = 'https://mobility.fori.co/api/v1/owner/signup'
      const API_URL = `${process.env.REACT_APP_SERVER_URL}/generic/signup`;
      const response = await axios.post(API_URL, userData);

      if (response.data.success === true) {
        localStorage.removeItem("verification_data");
        encryptAndStore(response.data.payload, "user");
      }
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

////////////////////Sign In////////////////////////
export const SignIn_User = createAsyncThunk(
  "auth/SignIn",
  async (userData, thunkAPI) => {
    try {
      const API_URL = `${process.env.REACT_APP_SERVER_URL}/generic/signin`;
      const response = await axios.post(API_URL, userData, {
        withCredentials: true,
      });
      console.log(response.data);
      if (response.data.success === true) {
        encryptAndStore(response.data.payload, "user");
      }
      return response.data;
    } catch (error) {
      const message = error?.response?.data?.message || error?.response?.data?.status || error?.response?.headers || error.message ||  error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// ========================logout===========

export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  //logout user
  try {
    localStorage.removeItem("user");
    // Clear cookies
    document.cookie.split(";").forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
  } catch (error) {
    return thunkAPI.rejectWithValue("Logout Unsuccessful");
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      /////////////Sign Up//////////////
      .addCase(SignUp_User.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(SignUp_User.fulfilled, (state, action) => {
        if (action.payload.success === true) {
          state.user = action.payload.payload;
        }
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
        toast.info(action.payload.message);
      })
      .addCase(SignUp_User.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
        toast.error(action.payload);
      })
      /////////////Sign In/////////////
      .addCase(SignIn_User.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(SignIn_User.fulfilled, (state, action) => {
        if (action.payload.success === true) {
          state.user = action.payload.payload;
        }
        state.isLoading = false;
        state.isSuccess = true;
        toast.info(action.payload.message);
      })
      .addCase(SignIn_User.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
        toast.error(action.payload);
      })
      .addCase(logout.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
        state.user = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        toast.error(action.payload);
      });
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
