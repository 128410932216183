import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const width =  window.innerWidth
let height
if(width<=900){
   height = 250
}




export default function BarChart({data, title, showTitle,color, Xaxis, Yaxis}) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
          display:false,
        position: 'top',
      },
      title: {
        display: showTitle,
        text: title,
      },
  
    },
    scales: {
      x: {
        title: {
          display: true,
          text:Xaxis?Xaxis: "Date", // X-axis title
        },
        grid: {
          display: false, // Turn off x-grid lines
        },
      },
      y: {
        title: {
          display: true,
          text: Yaxis?Yaxis:"Detailed Analysis of Trees and fuel consumed", // Y-axis title
        },
        grid: {
          display: true, // Turn off y-grid lines
        },
      },
    },
    maintainAspectRatio: false ,
    borderRadius:'50',
    
  };

  if(color){
    data = {
      ...data,
      datasets: [
        {
          ...data.datasets[0],
          borderColor: color.secondary | '#007bff',
          backgroundColor:color.primary || '#0e0e0e' // Update borderColor here
        },
        {
          ...data.datasets[1],
          borderColor : color.primary || '#0e0e0e',
          backgroundColor:color.secondary || '#007bff' // Update borderColor here
        },
      ]
    }
  }

  return <Bar options={options} data={data} height={height} style={{backgroundColor:"transparent"}}/>;
}
