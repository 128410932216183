import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {toast} from 'react-toastify'

const initialState = {
    allTasks:[],
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:'',
}




//////////////////////get all tasks///////////////////////////
export const getAllTasks = createAsyncThunk('operatorHome/getAllTasks',
async(thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/task/getTasks`
        const response = await axios.get(API_URL, {withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.response.data.status || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


//////////////////////operater Response To Broadcast///////////////////////////
export const operaterResponseToBroadcast = createAsyncThunk('operatorHome/operaterResponseToBroadcast',
async({taskId,response,projectId},thunkAPI)=>{
    const data = {taskId,response,projectId}
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/task/updateBroadcastTask`
        const response = await axios.post(API_URL, data, {withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.response.data.status || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})



//////////////////////operater Response To assigned task///////////////////////////
export const operaterResponseToAssignedTask = createAsyncThunk('operatorHome/operaterResponseToAssignedTask',
async({taskId,response,projectId},thunkAPI)=>{
    const data = {taskId,response,projectId}
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/task/taskResponse`
        const response = await axios.post(API_URL, data, {withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.response.data.status || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


//////////////////////operater Response mark as complete///////////////////////////
export const operaterResponseMarkAsComplete = createAsyncThunk('operatorHome/operaterResponseMarkAsComplete',
async({id},thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/task/updateStatus/${id}`
        const response = await axios.put(API_URL, null, {withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.response.data.status || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const OperatorHomeSlice = createSlice({
    name:'OperatorHome',
    initialState,
    reducers:{
        reset:(state)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        },
    },
    extraReducers:(builder) => {
        builder
        ////////get all Tasks//////
        .addCase(getAllTasks.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(getAllTasks.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            // state.message = action.payload.message
            state.allTasks=action.payload.result
            // toast.info(action.payload.message)
        })
        .addCase(getAllTasks.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            toast.info(action.payload.message)
        })
        ////////operater Response To Broadcast//////
        .addCase(operaterResponseToBroadcast.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(operaterResponseToBroadcast.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            toast.info(action.payload.message)
        })
        .addCase(operaterResponseToBroadcast.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            toast.info(action.payload.message)
        })
        ////////operater Response To Assigned task//////
        .addCase(operaterResponseToAssignedTask.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(operaterResponseToAssignedTask.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            toast.info(action.payload.message)
        })
        .addCase(operaterResponseToAssignedTask.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            toast.info(action.payload.message)
        })
        ////////operater Response mark as complete//////
        .addCase(operaterResponseMarkAsComplete.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(operaterResponseMarkAsComplete.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            toast.info(action.payload.message)
        })
        .addCase(operaterResponseMarkAsComplete.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            toast.info(action.payload.message)
        })

    }
})

export const { reset } = OperatorHomeSlice.actions
export default OperatorHomeSlice.reducer