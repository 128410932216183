// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Main_SignUp_Container {
    color: var(--black);
    height: 90vh;
    text-transform: uppercase;
}
.form{
    text-align: left;
    gap: 1rem;
    width: 50%;
    flex-shrink: 0;
    padding: 2rem 5rem;
    background-color: var(--white);
}
.form_right{
    padding: 3rem 5rem;
    gap: 2rem;
    width: 50%;
    height: 100%;
    color: var(--black);
}
.form-group{
    gap: 0.5rem;
}
.form-group label{
    font-weight: bold;
}
.special_a_tag{
    color: var(--primary_color);
}
.site_icon{
    width: 1.1rem;
    margin-left: 0.5rem;
}
@media screen and (max-width: 1025px){
    .Main_SignUp_Container {
        height: auto;
        flex-direction: column-reverse;
    }
    .form{
        width: 100%;
        padding: 2rem;
    }
    .form_right{
        padding: 5rem 2rem 2rem 2rem;
        width: 100%;
    }
}
@media screen and (max-width: 350px){

    .form{
        width: 100%;
        padding: .5rem;
    }

}`, "",{"version":3,"sources":["webpack://./src/Pages/SignUp/SignUp.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;IACZ,yBAAyB;AAC7B;AACA;IACI,gBAAgB;IAChB,SAAS;IACT,UAAU;IACV,cAAc;IACd,kBAAkB;IAClB,8BAA8B;AAClC;AACA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,YAAY;IACZ,mBAAmB;AACvB;AACA;IACI,WAAW;AACf;AACA;IACI,iBAAiB;AACrB;AACA;IACI,2BAA2B;AAC/B;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI;QACI,YAAY;QACZ,8BAA8B;IAClC;IACA;QACI,WAAW;QACX,aAAa;IACjB;IACA;QACI,4BAA4B;QAC5B,WAAW;IACf;AACJ;AACA;;IAEI;QACI,WAAW;QACX,cAAc;IAClB;;AAEJ","sourcesContent":[".Main_SignUp_Container {\n    color: var(--black);\n    height: 90vh;\n    text-transform: uppercase;\n}\n.form{\n    text-align: left;\n    gap: 1rem;\n    width: 50%;\n    flex-shrink: 0;\n    padding: 2rem 5rem;\n    background-color: var(--white);\n}\n.form_right{\n    padding: 3rem 5rem;\n    gap: 2rem;\n    width: 50%;\n    height: 100%;\n    color: var(--black);\n}\n.form-group{\n    gap: 0.5rem;\n}\n.form-group label{\n    font-weight: bold;\n}\n.special_a_tag{\n    color: var(--primary_color);\n}\n.site_icon{\n    width: 1.1rem;\n    margin-left: 0.5rem;\n}\n@media screen and (max-width: 1025px){\n    .Main_SignUp_Container {\n        height: auto;\n        flex-direction: column-reverse;\n    }\n    .form{\n        width: 100%;\n        padding: 2rem;\n    }\n    .form_right{\n        padding: 5rem 2rem 2rem 2rem;\n        width: 100%;\n    }\n}\n@media screen and (max-width: 350px){\n\n    .form{\n        width: 100%;\n        padding: .5rem;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
