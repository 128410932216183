// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.under-construction-container {
    text-align: center;
  }
  
  .construction-icon {
    font-size: 48px;
    color:var(--black);/* Change the color as needed */
    margin-bottom: 20px;
  }
  
  .construction-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .construction-text {
    font-size: 16px;
    color: #555; /* Change the text color as needed */
  }
  `, "",{"version":3,"sources":["webpack://./src/Pages/NotFoundPage/NotFoundPage.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,kBAAkB,CAAC,+BAA+B;IAClD,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,WAAW,EAAE,oCAAoC;EACnD","sourcesContent":[".under-construction-container {\n    text-align: center;\n  }\n  \n  .construction-icon {\n    font-size: 48px;\n    color:var(--black);/* Change the color as needed */\n    margin-bottom: 20px;\n  }\n  \n  .construction-heading {\n    font-size: 24px;\n    font-weight: bold;\n    margin-bottom: 10px;\n  }\n  \n  .construction-text {\n    font-size: 16px;\n    color: #555; /* Change the text color as needed */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
