// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loaderContainerForInput {
    width: 78px;
    height: 78px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .loaderForInput {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }
  
  .dot {
    width: 20px;
    height: 20px;
    background-color: #3b27d0;
    border-radius: 50%;
    animation: animloader1 1.5s ease-in-out infinite;
    border: 2px solid #3b27d0;
  }
  
  .dot:nth-child(1) {
    animation-delay: 0.1s;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.3s;
  }
  
  @keyframes animloader1 {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-15px);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/LoaderForInputs/LoaderForInputs.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,aAAa;IACb,SAAS;IACT,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,gDAAgD;IAChD,yBAAyB;EAC3B;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE;MACE,wBAAwB;IAC1B;IACA;MACE,4BAA4B;IAC9B;EACF","sourcesContent":[".loaderContainerForInput {\n    width: 78px;\n    height: 78px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .loaderForInput {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    gap: 1rem;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .dot {\n    width: 20px;\n    height: 20px;\n    background-color: #3b27d0;\n    border-radius: 50%;\n    animation: animloader1 1.5s ease-in-out infinite;\n    border: 2px solid #3b27d0;\n  }\n  \n  .dot:nth-child(1) {\n    animation-delay: 0.1s;\n  }\n  \n  .dot:nth-child(2) {\n    animation-delay: 0.2s;\n  }\n  \n  .dot:nth-child(3) {\n    animation-delay: 0.3s;\n  }\n  \n  @keyframes animloader1 {\n    0%, 100% {\n      transform: translateY(0);\n    }\n    50% {\n      transform: translateY(-15px);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
