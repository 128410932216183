import React, { useState, useRef, useEffect } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { toast } from "react-toastify";
import { useParams ,useNavigate,Link} from "react-router-dom";
import { useDispatch ,useSelector} from 'react-redux'

const PolygonEditor = ({projectId,parkingId,parkCoordinates}) => {
  const mapRef = useRef();
  const polygonRef = useRef();
  const [initialCoordinates,setInitialCoordinates] =useState(parkCoordinates)
 
  useEffect(() => {
    const map = new window.google.maps.Map(mapRef.current, {
      center: { lat: 56.93, lng: 32.81 },
      zoom: 5,
    });

    const polygon = new window.google.maps.Polygon({
      paths: initialCoordinates,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      map: map,
      editable: true,
    });

    polygonRef.current = polygon;

    // Add listener for polygon edit events
    const path = polygon.getPath();
    window.google.maps.event.addListener(path, "set_at", handleEdit);
    window.google.maps.event.addListener(path, "insert_at", handleEdit);
        // Set map center and zoom based on polygon bounds
        const bounds = new window.google.maps.LatLngBounds();
        initialCoordinates.forEach((coordinate) => {
          bounds.extend(new window.google.maps.LatLng(coordinate.lat, coordinate.lng));
        });
        map.fitBounds(bounds);
  }, []);

  const handleEdit = () => {
    console.log("Polygon edited");
  };

  const getUpdatedCoordinates = () => {
    return polygonRef.current
      .getPath()
      .getArray()
      .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));
  };
  const resetPolygon = () => {
    polygonRef.current.setPaths(initialCoordinates);
  };

  return (
    <div >
      <div className="DrivingZone_S1">
        <button onClick={resetPolygon} className="btn">
          Reset Parking
        </button>
        <button
          onClick={() =>
            console.log("Updated Coordinates:", getUpdatedCoordinates())
          }
          className="btn"
        >
          Update parking
        </button>
      </div>

      <div ref={mapRef} style={{ height: "400px" }}></div>
    </div>
  );
};

const Render = (status,projectId,parkingId,parkCoordinates) => {
  switch (status) {
    case Status.LOADING:
      return <p>{status} loading....</p>;
    case Status.FAILURE:
      return <p>{status} Error...</p>;
    case Status.SUCCESS:
      return <PolygonEditor  projectId={projectId} parkingId={parkingId} parkCoordinates={parkCoordinates}  />;
    default:
      return null;
  }
};

const ShowParking = () => {
  const { projectId, parkingId,parkingName} = useParams();
  const [parkCoordinates,setParkCoordinates]=useState([])

  const dispatch = useDispatch()
  const {parkings}=useSelector((store)=>store.parking)
  const {user}=useSelector((store)=>store.auth)
  const navigate = useNavigate()

  
  useEffect(()=>{
    if(user && projectId && parkingName){
      if(parkings.length!==0){
        const findParkById = (parkingId) => {
          
          return parkings.find((park) =>{return park.parkingId === parseInt(parkingId,10)});
        };
        if(findParkById(parkingId)!==undefined){
          const coordinates=findParkById(parkingId).parking.parkingCenters[0].parkingRegions.map((coord)=>{return{lat:coord.lat,lng:coord.lon}})
          setParkCoordinates(coordinates)
        }
      }else{
        navigate(-1)
      }
   
    }else{
      navigate('/signin');
    }
  },[])

  return (
    <div className="Project_Container">
      <div style={{textAlign:'center', width:'100%'}}>
        <h5>Parking name:<span>{parkingName}</span></h5>
      </div>  
    <div className="flex_column form form_project">    
    <div>
      {parkCoordinates.length>0?        <Wrapper
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        render={(status)=>Render(status,projectId,parkingId,parkCoordinates)}
        libraries={["drawing", "places","geometry"]}
      />:<p>Something went wrong.....</p>}

    </div>
  </div>
  <div className="nav_btn_group">
      <Link className="secondary_btn" to={`/project-details/${projectId}/parkings/view-all-parkings`}> View all Parking </Link>
      <Link className="secondary_btn" to={`/project-details/${projectId}/parkings/add-parking`}> Add Parking +</Link>
  </div>
    </div>

  );
};

export default ShowParking;



