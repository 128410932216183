import React, { useState, useRef, useEffect } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { toast } from "react-toastify";
import "./AddDrivingZone.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";
import {registerDrivingZone} from "../../../Features/ProjectDetail/DrivingZoneSlice"
import CheckRole from "../../../Utils/CheckRole/CheckRole";
import NotAuthorized from "../../NotAuthorized/NotAuthorized";
import Loader from '../../../Components/Loader/Loader'
import Tooltip from "../../../Components/ToolTip/Tooltip"

const DrivingZoneComponent = ({ projectId }) => {

  const [zoneName, setZoneName] = useState("");
  const [placeName, setPlaceName] = useState("");
  const [shapes, setShapes] = useState([]);
  const mapRef = useRef();
  const inputRef = useRef();
  const savebtnRef=useRef()
  const drawingManagerRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const map = new window.google.maps.Map(mapRef.current, {
      center: { lat: 44.9, lng: 0.0 },
      zoom: 3,
    });
    const autocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current
    );
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();

      if (!place.geometry) {
        toast.info("No details available for input: '" + place.name + "'");
      } else {
        mapRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
        map.setCenter(place.geometry.location);
        map.setZoom(14);
      }
    });
    const drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
      },
      polygonOptions: {
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        draggable: false,
        editable: false, // Set the polygon to be editable
        zIndex: 1,
      },
    });
    window.google.maps.event.addListener(
      drawingManager,
      "overlaycomplete",
      (event) => {
        
        setShapes(event.overlay);
        // // Extract and log coordinates for each shape
        // if (event.type === window.google.maps.drawing.OverlayType.POLYGON) {
        //   const coordinates = event.overlay.getPath().getArray().map((latLng) => ({lat: latLng.lat(), lng: latLng.lng()}));

        //   // console.log("Polygon Coordinates:", coordinates);
        // }
        // restrict user from further drawing
        drawingManager.setOptions({ drawingControl: false, drawingMode: null });
        savebtnRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }
    );

    drawingManager.setMap(map);
    drawingManagerRef.current = drawingManager;
  }, []);

  const resetShapes = () => {
    Object.keys(shapes).length !== 0&& shapes.setMap(null);
    setShapes([]);

    if (drawingManagerRef.current) {
      drawingManagerRef.current.setOptions({drawingControl: true, drawingMode: window.google.maps.drawing.OverlayType.POLYGON});
    }

  };
  const saveZone=()=>{
    if(Object.keys(shapes).length !== 0 && zoneName!==""){
      const DrivingZoneData={
        name: zoneName,
        projectId: projectId,
        center: shapes.getPath().getArray().map((latLng) => ({lat: latLng.lat(), lon: latLng.lng()}))[0],
        region: shapes.getPath().getArray().map((latLng) => ({lat: latLng.lat(), lon: latLng.lng()}))
    }
    dispatch(registerDrivingZone(DrivingZoneData)).then((res)=>res.payload.success==true && navigate(-1))
    }else{
      toast.info("please provide name and select area of zone ")
    }
  }

  return (
    (('owner' === CheckRole() || 'manager' === CheckRole()) ? (
      <div className='Project_Container'>
          <div style={{textAlign:'center', width:'100%'}}>
            <h4>Add Driving Zone</h4>
          </div> 
        <div className="flex_column form form_project">     
          <div className="DrivingZone_S1">
            <div className="DrivingZone_S1_C1">
              <label htmlFor="zoneName"  className="tooltipClass">Zone <Tooltip text={["Add memorable name for the driving zone to facilitate easy reference in the future."]}/></label>
              <input
                name="zoneName"
                placeholder="Enter driving zone name"
                value={zoneName}
                onChange={(e) => {
                  e.preventDefault();
                  setZoneName(e.target.value);
                }}
              />
              <label htmlFor="placeName"  className="tooltipClass">Place <Tooltip text={["search place on google map to get started"]}/></label>

              <input
                name="placeName"
                placeholder="Search place on map"
                value={placeName}
                onChange={(e) => setPlaceName(e.target.value)}
                ref={inputRef}
              />
            </div>
            <div className="nav_btn_group">
              <Link className="secondary_btn" to={`/project-details/${projectId}/drivingzones/view-all-drivingzones`}> View all driving Zone </Link>
              <button className="secondary_btn" onClick={resetShapes}>
                Reset
              </button>
              <button ref={savebtnRef} className="secondary_btn" onClick={()=>saveZone()}>Save</button>
            </div>
          </div>
          <div className="Parking_S2">
          <div
              ref={mapRef}
              id="map"
              style={{ height: "100%", width: "100%" }}
            ></div>
          </div>

        </div>
      </div>

    ) : <NotAuthorized />)

  );
};

const Render = (status, projectId) => {

  switch (status) {
    case Status.LOADING:
      return <Loader/>;
    case Status.FAILURE:
      return <p>{status} Error...</p>;
    case Status.SUCCESS:
      return <DrivingZoneComponent projectId={projectId} />;
    default:
      return null;
  }
};
const AddDrivingZone = () => {
  const { projectId } = useParams();
  const { user } = useSelector((store) => store.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/signin");
    }
  }, []);

  return (
    <>
        <div>
      <Wrapper
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        render={(status) => Render(status, projectId)}
        libraries={["drawing", "places", "geometry"]}
      />
    </div>

    </>

  );
};

export default AddDrivingZone;
