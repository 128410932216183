import React, {useEffect} from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom'
import CheckRole from '../../Utils/CheckRole/CheckRole'
import NotAuthorized from '../NotAuthorized/NotAuthorized'
import { useDispatch, useSelector } from 'react-redux'
import {findGroup, resetRequiredGroup} from '../../Features/Group/GroupSlice'
import Loader from '../../Components/Loader/Loader'


const GroupDetails = () => {
    const { groupId } = useParams();
  const {user}=useSelector((store)=>store.auth)
  const {requiredGroup}=useSelector((store)=>store.group)
  const dispatch = useDispatch()
  const navigate = useNavigate()
    
    useEffect(()=>{
        if(user){
          dispatch(findGroup(groupId))
        }else{
          navigate("/signin")
        }

        return(()=>dispatch(resetRequiredGroup()))
      },[])


return (
( ('owner' === CheckRole() || 'manager' ===  CheckRole()) ? 
(<section>
    <div className='Group_CardContainer'>
       {requiredGroup!==null ? 
             <div className='groupCards flex_column' style={{alignItems:'flex-start', textAlign:'left'}}>
                <small><b>Group Name: </b>{requiredGroup.name}</small>
                <small><b>Owner Id: </b>{requiredGroup.ownerId}</small>
                <small><b>Group Users: </b></small>
                {requiredGroup.groupUsers.map((user, index)=>{
                    return(
                        <div key={index}>
                            <small>{user.userEmail}</small>
                        </div>
                    )
                })}
        
             </div>
             :

            <>
              <Loader/>
            </>



       }
    </div>
</section>) : <NotAuthorized/>
)
)}

export default GroupDetails
