import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {toast} from 'react-toastify'

const initialState = {
    cars:[],
    requiredCar: null,
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:'',
}


//////////////////////Add Car///////////////////////////
export const addCar = createAsyncThunk('Car/addCar',
async(allData, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/car/register`
        const response = await axios.post(API_URL, allData, {withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


//////////////////////Add Multiple Cars///////////////////////////
export const addMultipleCars = createAsyncThunk('Car/addMultipleCars',
async(allData, thunkAPI)=>{
    console.log(allData,'allData');
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/car/registerCarList`
        const response = await axios.post(API_URL, allData, {withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


//////////////////////Get Project Cars/////////////////////////
export const getProjectCars = createAsyncThunk('Car/getProjectCars',
async(projectId , thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/project/getProjectCars/${projectId}`
        const response = await axios.get(API_URL , {withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


//////////////////////Get Required Car/////////////////////////
export const getRequiredCar = createAsyncThunk('Car/getRequiredCar',
async(carId , thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/car/${carId}`
        const response = await axios.get(API_URL , {withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})



//////////////////////Delete Car/////////////////////////
export const deleteCar = createAsyncThunk('Car/deleteCar',
async(carId , thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/car/${carId}`
        const response = await axios.delete(API_URL , {withCredentials:true})
        console.log(response,'response');
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})





export const CarSlice = createSlice({
    name:'Car',
    initialState,
    reducers:{
        reset:(state)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        },
    },
    extraReducers:(builder) => {
        builder
        /////////////Add Car//////////////
        .addCase(addCar.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(addCar.fulfilled, (state, action)=>{
            console.log(action.payload,'fulfilled');
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.status ////replace status into message
            toast.info(action.payload.status)////replace status into message
        })
        .addCase(addCar.rejected, (state, action)=>{
            console.log(action.payload,'rejected');
            state.isLoading = false
            state.isError = true
            state.message = action.payload.status
            toast.info(action.payload.status)
        })
        /////////////Add Multiple Cars//////////////
        .addCase(addMultipleCars.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(addMultipleCars.fulfilled, (state, action)=>{
            console.log(action.payload,'fulfilled');
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.status ////replace status into message
            toast.info(action.payload.status)////replace status into message
        })
        .addCase(addMultipleCars.rejected, (state, action)=>{
            console.log(action.payload,'rejected');
            state.isLoading = false
            state.isError = true
            state.message = action.payload.status
            toast.info(action.payload.status)
        })
        /////////////Get Project Cars//////////////
        .addCase(getProjectCars.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(getProjectCars.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.cars = action.payload.result
        })
        .addCase(getProjectCars.rejected, (state, action)=>{
            console.log(action.payload,'action.payload rejected');
            state.isLoading = false
            state.isError = true
        })
        /////////////Get Required Cars//////////////
        .addCase(getRequiredCar.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(getRequiredCar.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.requiredCar = action.payload.result
        })
        .addCase(getRequiredCar.rejected, (state, action)=>{
            console.log(action.payload,'action.payload rejected');
            state.isLoading = false
            state.isError = true
        })
        /////////////delete Car//////////////
        .addCase(deleteCar.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(deleteCar.fulfilled, (state, action)=>{
            console.log(action.payload,'action.payload fulfilled');
            state.isLoading = false
            state.isSuccess = true
            // state.message = action.payload.status
            // toast.info(action.payload.status)

        })
        .addCase(deleteCar.rejected, (state, action)=>{
            console.log(action.payload,'action.payload rejected');
            state.isLoading = false
            state.isError = true
            // state.message = action.payload.status
            // toast.info(action.payload.status)

        })
    }
})

export const { reset } = CarSlice.actions
export default CarSlice.reducer