import React, { useEffect, useState } from "react";
import NotAuthorized from "../NotAuthorized/NotAuthorized";
import CheckRole from "../../Utils/CheckRole/CheckRole";
import { useSelector, useDispatch } from "react-redux";
import { getManagerInvitesProject, managerResponseToProjectInvites } from "../../Features/Notifications/NotificationSlice";
import { useNavigate, useParams, Link } from "react-router-dom";
import Loader from '../../Components/Loader/Loader'
import DataTable from '../../Components/DataTable/DataTable';


const NotificationsManager = () => {
  const { managerInvitesProject } = useSelector((store) => store.notification);
  const [loading, setLoading] = useState(true);
  const [activeList, setActiveList] = useState("activeJobs");
  const { user } = useSelector((store) => store.auth);
  const email = user?.email

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getManagerInvitesProject(email));
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);



  const invitedProjects =
  managerInvitesProject?.invite?.filter(
    (projects) => projects.invited === 1 && projects.joined === 0
  )?.map((project, index) => ({
    ...project,
    SrNo: index + 1,
  })) || [];

  const handleAccept = (project) => {
    dispatch(
      managerResponseToProjectInvites({
        managerEmail: project.managerEmail,
        projectId: project.project.id,
      })
    ).then((res) => {
      res.payload.success === true && navigate('/');
    });
  };




  const columns = [
    { field: 'SrNo', headerName: 'Sr.No', width: 150 },
    { field: 'projectId', headerName: 'Project Id', width: 150 },
    { field: 'project.name', headerName: 'Project Name', width: 200, valueGetter: (params) => params.row.project.name,  },
    { field: 'ownerName', headerName: 'Owner Name', width: 200 },
    {
      field: 'acceptButton',
      headerName: 'Action',
      width: 200,
      renderCell: (params) => (
        <button
          className="deleteButton marginRight"
          onClick={() => handleAccept(params.row)}
        >
          Accept
        </button>
      ),
    },
  ];


  return "manager" === CheckRole() ? (
    <div className="Group_CardContainer">
      {loading ? (
        <h5><Loader/></h5>
      ) : (
        <>
          {invitedProjects.length === 0 ? (
            <>
              <h5>No projects invites available yet</h5>
            </>
          ) : (
            <>
              <DataTable columns={columns} data={invitedProjects} />
            </>
          )}
        </>
      )}
    </div>
  ) : (
    <NotAuthorized />
  );
};
    
export default NotificationsManager;

