import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {toast} from 'react-toastify'

const initialState = {
    managers:[],
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:'',
}


//////////////////////Invite Manager///////////////////////////
export const inviteManager = createAsyncThunk('projectDetail/inviteManager',
async(managerData, thunkAPI)=>{
    console.log(managerData,'managerData');
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/project/inviteManager`
        const response = await axios.post(API_URL, managerData,{withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.response.data.status || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

//////////////////////get Managers///////////////////////////
export const getManagers = createAsyncThunk('projectDetail/getManagers',
async(projectId, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/project/getProjectManagerInvites/${projectId}`
        const response = await axios.get(API_URL, {withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.response.data.status || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

//////////////////////delete Manager///////////////////////////
export const deleteManager = createAsyncThunk('projectDetail/deleteManagers',
async(id, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/manager/${id}`
        const response = await axios.delete(API_URL, {withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.response.data.status || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})






export const ManagerSlice = createSlice({
    name:'Manager',
    initialState,
    reducers:{
        reset:(state)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        },
    },
    extraReducers:(builder) => {
        builder
        /////////////Invite Manager//////////////
        .addCase(inviteManager.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(inviteManager.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            toast.info(action.payload.message)
        })
        .addCase(inviteManager.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            toast.info(action.payload.message)
        })
        .addCase(getManagers.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(getManagers.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.managers=action.payload.result
            toast.info(action.payload.message)
        })
        .addCase(getManagers.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            // state.message = action.payload.message
            // toast.info(action.payload.message)
        })
        .addCase(deleteManager.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(deleteManager.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.status
            toast.info(action.payload.status)
        })
        .addCase(deleteManager.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload.status
            toast.info(action.payload.status)
        })

    }
})

export const { reset } = ManagerSlice.actions
export default ManagerSlice.reducer