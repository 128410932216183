import React from 'react';
import './LoaderForInputs.css';

function LoaderForInputs() {
  return (
    <div className="loaderContainerForInput">
      <div className="loaderForInput">
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
      </div>
    </div>
  );
}

export default LoaderForInputs;
