import React, { Suspense } from "react";
import './App.css';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from './Components/Header/Header'
import Home from './Pages/Home/Home';
import SignUp from './Pages/SignUp/SignUp';
import SignIn from './Pages/SignIn/SignIn';
import AddGroup from './Pages/Groups/AddGroup';
import Groups from './Pages/Groups/Groups';
import GroupDetails from './Pages/Groups/GroupDetails';
import Projects from './Pages/Projects/Projects';
import AddProject from './Pages/Projects/AddProject';
import ProjectDetails from './Pages/Projects/ProjectDetails';
import UnderConstruction from './Pages/UnderConstruction/UnderConstruction';
import NotFoundPage from './Pages/NotFoundPage/NotFoundPage';
import UpdateGroup from './Pages/Groups/UpdateGroup';
import UpdateProject from './Pages/Projects/UpdateProject';
import InviteManager from './Pages/ProjectDetails/Manager/InviteManager';
import InviteOperator from './Pages/ProjectDetails/Operator/InviteOperator';
import ViewOperator from './Pages/ProjectDetails/Operator/ViewOperator';
import ViewManager from './Pages/ProjectDetails/Manager/ViewManager';
import ViewCar from './Pages/ProjectDetails/Cars/ViewCar';
import AddCar from './Pages/ProjectDetails/Cars/AddCar';
import CarPreference from './Pages/ProjectDetails/Cars/CarPreferences';
import AddOperatorJob from './Pages/ProjectDetails/OperatorJobs/AddOperatorJob';
import ViewOperatorJobs from './Pages/ProjectDetails/OperatorJobs/ViewOperatorJobs'
import AddDrivingZone from "./Pages/ProjectDetails/DrivingZone/AddDrivingZone";
import ShowDrivingZones from "./Pages/ProjectDetails/DrivingZone/ShowDrivingZone";
import ShowAllZones from "./Pages/ProjectDetails/DrivingZone/ShowAllZones";
import AddParking from "./Pages/ProjectDetails/Parkings/AddParking";
import ShowAllParkings from "./Pages/ProjectDetails/Parkings/ShowAllParkings";
import ShowParking from "./Pages/ProjectDetails/Parkings/ShowParking";
import AddMultipleCars from './Pages/ProjectDetails/Cars/AddMultipleCars';
import PricingModel from './Pages/PricingModel/PricingModel';
import Loader from './Components/Loader/Loader'
import AddBroadcastJobs from "./Pages/ProjectDetails/BroadcastJobs/AddBroadcastJobs";
import ViewBroadcastJobs from "./Pages/ProjectDetails/BroadcastJobs/ViewBroadcastJobs";
import AttachDrivingZoneToCar from "./Pages/ProjectDetails/DrivingZone/AttachDrivingZoneToCar";
import OperatorAssignedJobs from "./Pages/OperatorHome/OperatorAssignedJobs"
import Subscription from "./Pages/Subscription/Subscription";
import NotificationsManager from "./Pages/Notifications/NotificationsManager";
import NotificationsOperator from "./Pages/Notifications/NotificationsOperator";
import CarDetails from "./Pages/ProjectDetails/Cars/CarDetails";
import ViewSubscription from "./Pages/Subscription/ViewSubscription";
import ImpactPage from "./Pages/ImpactPage/ImpactPage";

// import Tooltip from "./Components/ToolTip/Tooltip";


function App() {
  return (
    <div className="App">
      <Router>
      <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/signup' element={<Suspense fallback={<Loader/>}><SignUp /></Suspense>} />
          <Route path='/signin' element={<Suspense fallback={<Loader/>}><SignIn /></Suspense>} />

          <Route path='/pricing-model' element={<Suspense fallback={<Loader/>}><PricingModel /></Suspense>} />

          <Route path='/groups' element={<Suspense fallback={<Loader/>}><Groups /></Suspense>} />
          <Route path='/add-group' element={<Suspense fallback={<Loader/>}><AddGroup /></Suspense>} />
          <Route path='/update-group/:groupId' element={<Suspense fallback={<Loader/>}><UpdateGroup /></Suspense>} />

          <Route path='/projects' element={<Suspense fallback={<Loader/>}><Projects /></Suspense>} />
          <Route path='/add-project' element={<Suspense fallback={<Loader/>}><AddProject /></Suspense>} />
          <Route path='/update-project/:projectId' element={<Suspense fallback={<Loader/>}><UpdateProject /></Suspense>} />


          <Route path='/group-details/:groupId' element={<Suspense fallback={<Loader/>}><GroupDetails /></Suspense>} />


        <Route path='/project-details/:projectId' element={<Suspense fallback={<Loader/>}><ProjectDetails /></Suspense>} >
          <Route index element={<Suspense fallback={<Loader/>}>{<Navigate to={`cars/view-cars`} replace />}</Suspense>} />
          <Route path="cars/view-cars" element={<Suspense fallback={<Loader/>}><ViewCar /></Suspense>} />
          <Route path='cars/view-cars/:carId' element={<Suspense fallback={<Loader/>}><CarDetails /></Suspense>} />
          <Route path='cars/view-cars/:carId/setpreferences' element={<Suspense fallback={<Loader/>}><CarPreference /></Suspense>} />
          {/* <Route path='view-cars/edit-car/:carId' element={<Suspense fallback={<Loader/>}><UnderConstruction /></Suspense>} /> */}
          <Route path='cars/view-cars/:carId/attach-parking' element={<Suspense fallback={<Loader/>}><UnderConstruction /></Suspense>} />
          <Route path='cars/view-cars/:carId/attach-drivingzone' element={<Suspense fallback={<Loader/>}><AttachDrivingZoneToCar /></Suspense>} />
          <Route path="cars/add-car" element={<Suspense fallback={<Loader/>}><AddCar /></Suspense>} />
          <Route path="cars/add-multiple-cars" element={<Suspense fallback={<Loader/>}><AddMultipleCars /></Suspense>} />
          <Route path='jobs/view-broadcast-jobs' element={<Suspense fallback={<Loader/>}><ViewBroadcastJobs /></Suspense>} />
          <Route path='jobs/add-broadcast-jobs' element={<Suspense fallback={<Loader/>}><AddBroadcastJobs /></Suspense>} />
          <Route path="employees/view-managers" element={<Suspense fallback={<Loader/>}><ViewManager /></Suspense>} />
          <Route path="employees/add-manager" element={<Suspense fallback={<Loader/>}><InviteManager /></Suspense>} />
          <Route path="employees/view-operators" element={<Suspense fallback={<Loader/>}><ViewOperator /></Suspense>} />
          <Route path="jobs/view-operators-jobs" element={<Suspense fallback={<Loader/>}><ViewOperatorJobs /></Suspense>} />
          <Route path='view-operators/:operatorId/add-operator-job' element={<Suspense fallback={<Loader/>}><AddOperatorJob /></Suspense>} />
          <Route path="employees/add-operator" element={<Suspense fallback={<Loader/>}><InviteOperator /></Suspense>} />
          <Route path="parkings/view-all-parkings" element={<Suspense fallback={<Loader/>}><ShowAllParkings /></Suspense>} />
          <Route path="parkings/add-parking" element={<Suspense fallback={<Loader/>}><AddParking /></Suspense>} />
          <Route path="drivingzones/view-all-drivingzones" element={<Suspense fallback={<Loader/>}><ShowAllZones /></Suspense>} />
          <Route path="drivingzones/add-drivingzone" element={<Suspense fallback={<Loader/>}><AddDrivingZone /></Suspense>} />
          <Route path='parkings/view-parking/:parkingId/:parkingName' element={<ShowParking />} />
          <Route path='drivingzones/view-drivingzone/:zoneId/:zoneName' element={<ShowDrivingZones />} /> 
        </Route>



        <Route path='/assigned-jobs-for-operator' element={<OperatorAssignedJobs />} />

        <Route path='/subscription' element={<Subscription />} />
        <Route path='/view-subscription' element={<ViewSubscription />} />


        <Route path="/notifications-manager" element={<Suspense fallback={<Loader/>}><NotificationsManager /></Suspense>} />
        <Route path="/notifications-operator" element={<Suspense fallback={<Loader/>}><NotificationsOperator /></Suspense>} />


        <Route path="/insights" element={<Suspense fallback={<Loader/>}><ImpactPage /></Suspense>} />








          <Route path="*" element={<Suspense fallback={<Loader/>}><NotFoundPage /></Suspense>} />

        </Routes>
      </Router>
      <ToastContainer/>
    </div>
  );
}

export default App;
