import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import NotAuthorized from '../../NotAuthorized/NotAuthorized';
import CheckRole from '../../../Utils/CheckRole/CheckRole';
import { useDispatch, useSelector } from 'react-redux';
import { getParking } from '../../../Features/ProjectDetail/ParkingSlice';
import Loader from '../../../Components/Loader/Loader';
import DataTable from '../../../Components/DataTable/DataTable';

const ShowAllParkings = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { parkings } = useSelector((store) => store.parking);
  const [loading, setLoading] = useState(true);
  const { user } = useSelector((store) => store.auth)



  useEffect(() => {
      if (!user) {
          navigate('/signin');
      }
  }, [ user]);


  useEffect(() => {
    const fetchData = async () => {
      dispatch(getParking(projectId));
      setLoading(false);
    };
    fetchData();
  }, [dispatch, projectId]);

  const columns = [
    { field: 'SrNo', headerName: 'Sr.No',minWidth: 150, flex: 1 },
    { field: 'parkingName', headerName: 'Parking Name',minWidth: 150, flex: 1},
    {
      field: 'viewParking',
      headerName: 'View Parking',
     minWidth: 150, flex: 1,
      renderCell: (params) => (
        <Link className="btn" to={`/project-details/${projectId}/parkings/view-parking/${params.row.parkingId}/${params.row.parkingName}`}>
          View Parking
        </Link>
      ),
    },
  ];

  const rows = parkings.map((parking, index) => ({ ...parking, SrNo: index + 1, parkingName: parking.parking.name }));

  return 'owner' === CheckRole() || 'manager' === CheckRole() ? (
    <>
      <div className="Project_Container">
        {loading ? (
            <Loader />
        ) : (
          <>
            {parkings.length === 0 ? (<> <h4 className='Not_Data_heading'>No parking added yet</h4> </> ) : (<><h4>All Parkings</h4> <DataTable columns={columns} data={rows} pageSize={2} /></>)}
            <div className="nav_btn_group">
            <Link className="secondary_btn" to={`/project-details/${projectId}/parkings/add-parking`}> Add Parking +</Link>
            </div>
          </>
        )}
      </div>
    </>
  ) : (
    <NotAuthorized />
  );
};

export default ShowAllParkings;
