import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {toast} from 'react-toastify'

const initialState = {
    vendors:[],
    vendorThroughProject:{},
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:'',
}



//////////////////////get vendors///////////////////////////
export const getVendor = createAsyncThunk('projectDetail/getVendor',
async(thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/vendor`
        const response = await axios.get(API_URL, {withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.response.data.status || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


//////////////////////get vendors through project///////////////////////////
export const getVendorThroughProject = createAsyncThunk('projectDetail/getVendorThroughProject',
async(projectId, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/vendor/project/${projectId}`
        const response = await axios.get(API_URL, {withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.response.data.status || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})



export const VendorSlice = createSlice({
    name:'Vendor',
    initialState,
    reducers:{
        reset:(state)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        },
    },
    extraReducers:(builder) => {
        builder
        ///////////Get Vendors//////////////
        .addCase(getVendor.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(getVendor.fulfilled, (state, action)=>{
            console.log(action.payload,'///fulfilled///');
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.vendors=action.payload.result
            console.log(action.payload.result);
            toast.info(action.payload.message)
        })
        .addCase(getVendor.rejected, (state, action)=>{
            console.log(action.payload,'///rejected///');
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            toast.info(action.payload.message)
        })
        ///////////getVendorThroughProject//////////////
        .addCase(getVendorThroughProject.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(getVendorThroughProject.fulfilled, (state, action)=>{
            console.log(action.payload,'///fulfilled///');
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.vendorThroughProject=action.payload
            console.log(action.payload.result);
            toast.info(action.payload.message)
        })
        .addCase(getVendorThroughProject.rejected, (state, action)=>{
            console.log(action.payload,'///rejected///');
            state.isLoading = false
            state.isError = true
            // state.message = action.payload.message
            // toast.info(action.payload.message)
        })
    }
})

export const { reset } = VendorSlice.actions
export default VendorSlice.reducer