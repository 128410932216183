import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import NotAuthorized from '../../NotAuthorized/NotAuthorized';
import CheckRole from '../../../Utils/CheckRole/CheckRole';
import { useDispatch, useSelector } from 'react-redux';
import { getDrivingZone } from '../../../Features/ProjectDetail/DrivingZoneSlice';
import Loader from '../../../Components/Loader/Loader';
import DataTable from '../../../Components/DataTable/DataTable';

const ShowAllZones = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const { DrivingZones } = useSelector((store) => store.drivingZone);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getDrivingZone(projectId));
      setLoading(false);
    };
    fetchData();
  }, [dispatch, projectId]);

  const columns = [
    { field: 'SrNo', headerName: 'Sr.No', minWidth: 150, flex: 1  },
    { field: 'drivingZoneName', headerName: 'Driving Zone Name',minWidth: 150, flex: 1  },
    {
      field: 'viewDrivingZone',
      headerName: 'View Driving Zone',
      minWidth: 150, flex: 1 ,
      renderCell: (params) => (
        <Link
          className="btn"
          to={`/project-details/${projectId}/drivingzones/view-drivingzone/${params.row.drivingZoneId}/${params.row.drivingZoneName}`}
        >
          View Driving Zone
        </Link>
      ),
    },
  ];

  const rows = DrivingZones.map((zone, index) => ({ ...zone, SrNo: index + 1, drivingZoneName: zone.drivingZone.name }));

  return 'owner' === CheckRole() || 'manager' === CheckRole() ? (
    <>
      <div className="Project_Container">
        {loading ? <Loader /> : <> {DrivingZones.length === 0 ?<> <h4 className='Not_Data_heading'>No driving zone added yet</h4></> : <>
        <h4>All Driving Zone</h4>
         <DataTable columns={columns} data={rows} pageSize={4} />
         </>
         }
        <div className="nav_btn_group">
          <Link className="secondary_btn" to={`/project-details/${projectId}/drivingzones/add-drivingzone`}> Add driving zone +</Link>
        </div></>
        }
      </div>
    </>
  ) : (
    <NotAuthorized />
  );
};

export default ShowAllZones;
