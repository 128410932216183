// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .MuiDataGrid-columnHeader{
background-color: aqua !important;
width: 100% !important;
margin:1rem !important;

}
.MuiDataGrid-cell--withRenderer{
    background-color: rgb(34, 42, 42) !important;
width: 100% !important;
}
 .MuiDataGrid-cell{
    background-color: rgb(14, 61, 61) !important;
    width: 100% !important;
    margin:1rem !important;
 }  */
 /* .MuiDataGrid-cell--textLeft{
    background-color: rgb(161, 195, 23) !important;
    width: 100% !important;
 } */

/* .MuiDataGrid-columnHeader--sortable{
    background-color: green !important;

}  */
/* .MuiDataGrid-withBorderColor{
background-color: red !important;
} */`, "",{"version":3,"sources":["webpack://./src/Components/DataTable/DataTable.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;KAcK;CACJ;;;IAGG;;AAEJ;;;IAGI;AACJ;;GAEG","sourcesContent":["/* .MuiDataGrid-columnHeader{\nbackground-color: aqua !important;\nwidth: 100% !important;\nmargin:1rem !important;\n\n}\n.MuiDataGrid-cell--withRenderer{\n    background-color: rgb(34, 42, 42) !important;\nwidth: 100% !important;\n}\n .MuiDataGrid-cell{\n    background-color: rgb(14, 61, 61) !important;\n    width: 100% !important;\n    margin:1rem !important;\n }  */\n /* .MuiDataGrid-cell--textLeft{\n    background-color: rgb(161, 195, 23) !important;\n    width: 100% !important;\n } */\n\n/* .MuiDataGrid-columnHeader--sortable{\n    background-color: green !important;\n\n}  */\n/* .MuiDataGrid-withBorderColor{\nbackground-color: red !important;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
