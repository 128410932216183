import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import './DataTable.css';

export default function DataTable({ data, columns }) {
    const [filterText, setFilterText] = useState('');

    const handleFilterChange = (event) => {
        setFilterText(event.target.value);
    };

    const getField = (object, path) => {
        const pathArray = path.split('.');
        return pathArray.reduce((acc, current) => acc && acc[current], object);
    };

    const filteredData = data.filter((row) =>
        columns.some(
            (column) => {
                const field = column.field;
                const cellValue = getField(row, field);
                return cellValue && cellValue.toString().toLowerCase().includes(filterText.toLowerCase());
            }
        )
    );

    const columnsConfig = columns.map((column) => ({
        ...column,
        valueGetter: (params) => {
            const cellValue = getField(params.row, column.field);
            return cellValue !== undefined ? cellValue : '';
        },
    }));

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <input
                type="text"
                value={filterText}
                onChange={handleFilterChange}
                placeholder="Search..."
            />
            <DataGrid
                rows={filteredData}
                columns={columnsConfig}
                initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize:5 },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
            />
        </div>
    );
}
