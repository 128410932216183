import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {toast} from 'react-toastify'

const initialState = {
    groups: [],
    requiredGroup: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}


//////////////////////Create Group///////////////////////////
export const createGroup = createAsyncThunk('Group/createGroup',
async(userData, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/group/register`
        const response = await axios.post(API_URL, userData,{withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) ||  error.response.data.status||error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


////////////////////Get Group////////////////////////
export const getGroup = createAsyncThunk('Group/getGroup',
async(thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/group/getGroups`
        const response = await axios.get(API_URL, {withCredentials:true})
        console.log(response.data,'response');
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message)  ||  error.response.data.status|| error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})



////////////////////Find Group////////////////////////
export const findGroup = createAsyncThunk('Group/findGroup',
async(groupId,thunkAPI)=>{
    console.log(groupId,'groupId');
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/group/${groupId}`
        const response = await axios.get(API_URL, {withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) ||  error.response.data.status || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const deleteGroup = createAsyncThunk('Group/deleteGroup',
async(id,thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/group/${id}`
        const response = await axios.delete(API_URL, {withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message)  ||  error.response.data.status|| error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


//////////////////////Update Group///////////////////////////
export const updateGroup = createAsyncThunk('Group/updateGroup',
async({userData,groupId}, thunkAPI)=>{
    try{
        console.log({userData, groupId},'{userData, groupId}');
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/group/update/${groupId}`
        const response = await axios.put(API_URL, userData, {withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message)  ||  error.response.data.status|| error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


export const groupSlice = createSlice({
    name:'group',
    initialState,
    reducers:{
        reset:(state)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        },
        resetRequiredGroup:(state)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
            state.requiredGroup=null
        },
    },
    extraReducers:(builder) => {
        builder
        /////////////Create Group//////////////
        .addCase(createGroup.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(createGroup.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.status
            toast.info(action.payload.status)
        })
        .addCase(createGroup.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            toast.error(action.payload)
        })
        /////////////Get Group/////////////
        .addCase(getGroup.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(getGroup.fulfilled, (state, action)=>{
            if(action.payload.success===true){
                state.groups = action.payload.result
            }
            state.isLoading = false
            state.isSuccess = true
        })
        .addCase(getGroup.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            // state.message = action.payload.status
            // toast.info(action.payload.status)
        })
        /////////////Update Group//////////////
        .addCase(updateGroup.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(updateGroup.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.status
            toast.info(action.payload.status)
        })
        .addCase(updateGroup.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            toast.info(action.payload)
        })
        /////////////find Group//////////////
        .addCase(findGroup.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(findGroup.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.requiredGroup = action.payload.result
            state.message = action.payload.status
            toast.info(action.payload.status)
        })
        .addCase(findGroup.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload.status
            state.requiredGroup = null
            toast.info(action.payload.status)
        })
        /////////////delete Group//////////////
        .addCase(deleteGroup.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(deleteGroup.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.status
            toast.info(action.payload.status)
        })
        .addCase(deleteGroup.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            toast.info(action.payload)
        })
    }
})

export const { reset ,resetRequiredGroup} = groupSlice.actions
export default groupSlice.reducer