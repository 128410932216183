import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom'
import { addJob, getCategoryAndTask } from '../../../Features/ProjectDetail/AddJobSlice';
import { getProjectCars } from '../../../Features/ProjectDetail/CarSlice';
import NotAuthorized from '../../NotAuthorized/NotAuthorized';
import CheckRole from '../../../Utils/CheckRole/CheckRole';



const AddOperatorJob = () => {
  const { projectId, operatorId } = useParams();
  const [tasks, setTasks] = useState([]);


    const { cars } = useSelector((store) => store.car)
    const { categoryAndTask } = useSelector((store) => store.addJob)

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [data, setData] = useState({
        name: '',
        categoryId: 0,
        carId: 0,
        taskId: 0,
        operatorId: parseInt(operatorId, 10),
        startDate:'',
        endDate:'',
        projectId: parseInt(projectId, 10)
    });

    const { name, categoryId, carId, taskId, startDate, endDate } = data;



    const onChange = (e) => {
      let value;
      
      // Check if the field name is one of the fields that should be an integer
      if (['categoryId', 'taskId', 'operatorId', 'carId'].includes(e.target.name)) {
          value = parseInt(e.target.value, 10);
      } else {
          value = e.target.value;
      }
  
      setData((prevState) => ({ ...prevState, [e.target.name]: value }));
  };
        
    
    const onCategoryChange = (e) => {
      setData((prevState) => ({ ...prevState, categoryId: parseInt(e.target.value, 10) }));

      const selectedTasks = categoryAndTask?.result?.find((category) => category.id === parseInt(e.target.value, 10)).tasks;
      setTasks(selectedTasks);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (name === '') {
            toast.info('Please fill all fields');
        } else {
            dispatch(addJob(data)).then((res)=>{res.payload.success==true && navigate(`/project-details/${projectId}/view-operators-jobs`)})
          }
        }


    useEffect(() => {
      dispatch(getCategoryAndTask());
      dispatch(getProjectCars(projectId));
    }, [dispatch]);  

  return (
    ( ('owner' === CheckRole() || 'manager' ===  CheckRole()) ? 
    (<div className='flex_row_center_center Project_Container'>
        <form onSubmit={onSubmit} className='flex_column form form_project'>
        <div style={{textAlign:'left', width:'100%'}}>
            <h4>Add Job</h4>
        </div>
        <div className='flex_row_project'>
        <div className='flex_column form_group form_group_project'>
          <label>Job Name</label>
          <input type='text' id='name' name='name' value={name} placeholder='Enter job name' onChange={onChange} />
        </div>
        <div className='flex_column form_group form_group_project'>
          <label>Select Car Vin</label>
          <select type='number' id='carId' name='carId' value={carId} onChange={onChange} >
          <option>Select Car Vin</option>
          {cars?.map((car, index) => (
            <option key={index} value={car.carId}>
              {car.car.vin}
            </option>
          ))}
          </select>
        </div>
        </div>
        <div className='flex_row_project'>
        <div className='flex_column form_group form_group_project'>
          <label>Select Job Category</label>
          <select type='number' id='categoryId' name='categoryId' value={categoryId} onChange={onCategoryChange} >
          <option>Select Job Category</option>
          {categoryAndTask?.result?.map((category, index) => (
            <option key={index} value={category.id}>
              {category.name}
            </option>
          ))}
          </select>
        </div>        
        <div className='flex_column form_group form_group_project'>
          <label>Select Job</label>
          <select type='number' id='taskId' name='taskId' value={parseInt(taskId, 10)} onChange={onChange} >
          <option>Select Job Name</option>
          {tasks?.map((task, index) => (
            <option key={index} value={task.id}>
              {task.name}
            </option>
          ))}
          </select>
        </div>
        </div>        
        <div className='flex_row_project'>
          <div className='flex_column form_group form_group_project'>
            <label>Start Date</label>
            <input type='datetime-local' id='startDate' name='startDate' value={startDate} onChange={onChange} />
          </div>
          <div className='flex_column form_group form_group_project'>
            <label>End Date</label>
            <input type='datetime-local' id='endDate' name='endDate' value={endDate} onChange={onChange} />
          </div>
        </div>
        <div>
          <button className='btn' type='submit'>
            Add Job
          </button>
        </div>
        </form>
    </div>) : <NotAuthorized/>
    )
  )
}

export default AddOperatorJob
