import React, { useEffect } from 'react'
import './SideBar.css'
import {Link, useParams} from 'react-router-dom'
import { useState } from 'react'
import { useLocation ,useNavigate} from 'react-router-dom'
import {  useSelector } from "react-redux";
import { getProject } from "../../Features/Project/ProjectSlice";
import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";
import Loader from "../../Components/Loader/Loader"; 

const SideBar = () => {

  const { projectId } = useParams();
  const location = useLocation();
  const navigate=useNavigate()
  const pathParts=location.pathname.split('/');
  const indexOfProjectId = pathParts.indexOf(projectId);
  const [activeNav, setActiveNav] = useState(indexOfProjectId !== -1 && pathParts[indexOfProjectId + 1])
  const [dropDown, setDropDown] = useState({cars: false,employees: false,jobs: false, parkings: false, drivingzones: false,activeProject:"" });
  const { cars,employees,jobs,parkings,drivingzones,activeProject}=dropDown
  const { projects} = useSelector((store) => store.project);


  useEffect(() => {
    if(projects.length<1){
      navigate('/projects')
    }
  });


  
  useEffect(() => {
    setActiveNav(indexOfProjectId !== -1 && pathParts[indexOfProjectId + 1]);
  }, [pathParts]);


const getLinkUrl=(id)=>{
  const urlParts = location.pathname.split('/')
  const currentIndex = urlParts.findIndex(part => part === 'project-details');
  if (currentIndex !== -1) {
    urlParts[currentIndex + 1] = id; 
    navigate(urlParts.join('/'));
  }
}


  return ( 

    <section className="sidebar">
        <div  className={activeNav === 'projects' ? "sidebar_btn_active ": 'sidebar_btn_deactive'} onClick={()=>setDropDown({...dropDown,activeProject:!dropDown.activeProject})} style={{backgroundColor:"var(--lgray"}}>
          { projects.length>0&&projects?.filter((project) => project?.project?.id == projectId)[0].project.name}
          {activeProject==true?<RiArrowDropUpLine/>:<RiArrowDropDownLine/>} 
        </div> 

          {activeProject === true ?<div className='sidebar_btn_active_sub' onMouseLeave={()=>setDropDown({...dropDown,activeProject:!dropDown.activeProject})}>
          {activeProject===true? projects.length>0&& projects?.filter((project) => project?.project?.isActive === 1 &&  project?.project?.id != projectId).map((project, index) => {
            console.log(project,"project")
            return <button className={projects[index].id === project?.id ? "sidebar_btn_active_sub_a ": 'sidebar_btn_deactive_sub_a'} onClick={()=>getLinkUrl(project.project.id)}>{project.project.name.length>20?project.project.name.slice(0,18)+"...":project.project.name}</button>
          }):null}</div>:null}

        {/*================= Cars========================================= */}

            <div  className={activeNav === 'cars' ? "sidebar_btn_active ": 'sidebar_btn_deactive'}  onClick={()=>setDropDown({...dropDown,cars:!dropDown.cars})}>
              <Link to={`/project-details/${projectId}/cars/view-cars`}> Cars  </Link>
              {cars==true?<RiArrowDropUpLine/>:<RiArrowDropDownLine/>}
            </div>
            {cars === true ?<div className='sidebar_btn_active_sub'>
            <Link className={pathParts[indexOfProjectId + 2] === 'view-cars' ? "sidebar_btn_active_sub_a ": 'sidebar_btn_deactive_sub_a'} to={`/project-details/${projectId}/cars/view-cars`}> View cars</Link>
            <Link className={pathParts[indexOfProjectId + 2] === 'add-car' ? "sidebar_btn_active_sub_a ": 'sidebar_btn_deactive_sub_a'} to={`/project-details/${projectId}/cars/add-car`}> Add cars</Link>
            <Link className={pathParts[indexOfProjectId + 2] === 'add-multiple-cars' ? "sidebar_btn_active_sub_a ": 'sidebar_btn_deactive_sub_a'} to={`/project-details/${projectId}/cars/add-multiple-cars`}> Add multiple Cars</Link>
            </div>:null}
            
        {/* ==================Employees================================= */}

            <div className={activeNav === 'employees' ? "sidebar_btn_active ": 'sidebar_btn_deactive'}  onClick={()=>setDropDown({...dropDown,employees:!dropDown.employees})}>
              <Link  to={`/project-details/${projectId}/employees/view-managers`}>Employees</Link>
            {employees==true?<RiArrowDropUpLine />:<RiArrowDropDownLine/>}
            </div>
            {employees==true ?<div className='sidebar_btn_active_sub'>
              <Link className={pathParts[indexOfProjectId + 2] === 'view-managers' ? "sidebar_btn_active_sub_a ": 'sidebar_btn_deactive_sub_a'} to={`/project-details/${projectId}/employees/view-managers`}> View managers</Link>
              <Link className={pathParts[indexOfProjectId + 2] === 'add-manager' ? "sidebar_btn_active_sub_a ": 'sidebar_btn_deactive_sub_a'} to={`/project-details/${projectId}/employees/add-manager`}> Add manager</Link>
              <Link className={pathParts[indexOfProjectId + 2] === 'view-operators' ? "sidebar_btn_active_sub_a ": 'sidebar_btn_deactive_sub_a'} to={`/project-details/${projectId}/employees/view-operators`}> View operators</Link>
              <Link className={pathParts[indexOfProjectId + 2] === 'add-operator' ? "sidebar_btn_active_sub_a ": 'sidebar_btn_deactive_sub_a'} to={`/project-details/${projectId}/employees/add-operator`}> Add operator</Link>
            </div>:null}

        {/* ================Jobs====================================== */}

            <div className={activeNav === 'jobs' ? "sidebar_btn_active ": 'sidebar_btn_deactive'}  onClick={()=>setDropDown({...dropDown,jobs:!dropDown.jobs})}>
              <Link to={`/project-details/${projectId}/jobs/view-operators-jobs`}>Jobs</Link>
              {jobs==true?<RiArrowDropUpLine/>:<RiArrowDropDownLine />}
            </div>
            {jobs==true ?<div className='sidebar_btn_active_sub'>
              <Link className={pathParts[indexOfProjectId + 2] === 'view-operators-jobs' ? "sidebar_btn_active_sub_a ": 'sidebar_btn_deactive_sub_a'} to={`/project-details/${projectId}/jobs/view-operators-jobs`}> View operators jobs</Link>
              <Link className={pathParts[indexOfProjectId + 2] === 'view-broadcast-jobs' ? "sidebar_btn_active_sub_a ": 'sidebar_btn_deactive_sub_a'} to={`/project-details/${projectId}/jobs/view-broadcast-jobs`}> View broadcast jobs</Link>
              <Link className={pathParts[indexOfProjectId + 2] === 'add-broadcast-jobs' ? "sidebar_btn_active_sub_a ": 'sidebar_btn_deactive_sub_a'} to={`/project-details/${projectId}/jobs/add-broadcast-jobs`}> Add broadcast jobs</Link>
            </div>:null}

          {/* ==============Parkings=================================== */}

          <div className={activeNav === 'parkings' ? "sidebar_btn_active ": 'sidebar_btn_deactive'} onClick={()=>setDropDown({...dropDown,parkings:!dropDown.parkings})}>
            <Link  to={`/project-details/${projectId}/parkings/view-all-parkings`}>Parkings</Link>
            {parkings==true?<RiArrowDropUpLine/>:<RiArrowDropDownLine />}
          </div>
          {parkings==true ?<div className='sidebar_btn_active_sub'>
              <Link className={pathParts[indexOfProjectId + 2] === 'view-all-parkings' ? "sidebar_btn_active_sub_a ": 'sidebar_btn_deactive_sub_a'} to={`/project-details/${projectId}/parkings/view-all-parkings`}> view all parkings</Link>
              <Link className={pathParts[indexOfProjectId + 2] === 'add-parking' ? "sidebar_btn_active_sub_a ": 'sidebar_btn_deactive_sub_a'} to={`/project-details/${projectId}/parkings/add-parking`}> Add parking</Link>
            </div>:null}

          {/* =================Driving Zone============================ */}

          <div className={activeNav === 'drivingzones' ? "sidebar_btn_active ": 'sidebar_btn_deactive'} onClick={()=>setDropDown({...dropDown,drivingzones:!dropDown.drivingzones})} >
            <Link  to={`/project-details/${projectId}/drivingzones/view-all-drivingzones`}> Driving Zones</Link>
            {drivingzones==true?<RiArrowDropUpLine />:<RiArrowDropDownLine />}
          </div>
          {drivingzones==true ?<div className='sidebar_btn_active_sub'>
              <Link className={pathParts[indexOfProjectId + 2] === 'view-all-drivingzones' ? "sidebar_btn_active_sub_a ": 'sidebar_btn_deactive_sub_a'} to={`/project-details/${projectId}/drivingzones/view-all-drivingzones`}> view all driving zones</Link>
              <Link className={pathParts[indexOfProjectId + 2] === 'add-drivingzone' ? "sidebar_btn_active_sub_a ": 'sidebar_btn_deactive_sub_a'} to={`/project-details/${projectId}/drivingzones/add-drivingzone`}> Add driving zone</Link>
            </div>:null}
    </section>
  )
}

export default SideBar
