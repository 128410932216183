import React, { useEffect, useState } from "react";
import NotAuthorized from "../../NotAuthorized/NotAuthorized";
import CheckRole from "../../../Utils/CheckRole/CheckRole";
import { useSelector, useDispatch } from "react-redux";
import { getAllJobsProject } from "../../../Features/ProjectDetail/AddJobSlice";
import { useNavigate, useParams ,Link} from "react-router-dom";
import Loader from '../../../Components/Loader/Loader';
import DataTable from '../../../Components/DataTable/DataTable';
import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";


const ViewOperatorJobs = () => {
  const { allProjectJobs } = useSelector((store) => store.addJob);
  const [loading, setLoading] = useState(true);
  const [activeList, setActiveList] = useState("activeJobs");
  const { projectId } = useParams();
  const { user } = useSelector((store) => store.auth)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) {
        navigate('/signin');
    }
}, [ user]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAllJobsProject(projectId));
      setLoading(false);
    };
    fetchData();
  }, [dispatch, projectId]);

  const operatorJobs = allProjectJobs.filter(job => job.isBroadcast === 0);

  const columns = [
    { field: 'SrNo', headerName: 'Sr.No', minWidth: 150, flex: 1  },
    { field: 'projectId', headerName: 'Project Id', minWidth: 150, flex: 1  },
    { field: 'operatorId', headerName: 'Operator Id', minWidth: 150, flex: 1  },
    { field: 'name', headerName: 'Name', minWidth: 150, flex: 1  },
    {
      field: 'activeJobs',
      headerName: 'Active Jobs',
      minWidth: 150, flex: 1 ,
      renderCell: (params) => {
        if (params.row.isActive === 0 && params.row.isCompleted === 0 && params.row.isRejected === 0) {
          return <div className="tickIcon"><TiTick /></div>;
        } else {
          return <div className="crossIcon"><ImCross/></div>;
        }
      }
    },
    {
      field: 'rejectedJobs',
      headerName: 'Rejected Jobs',
      minWidth: 150, flex: 1 ,
      renderCell: (params) => {
        if (params.row.isActive === 0 && params.row.isCompleted === 0 && params.row.isRejected === 1) {
          return <div className="deleteButton"><ImCross/></div>;
        } else {
          return <div className="crossIcon"><ImCross/></div>;
        }
      }
    },
    {
      field: 'acceptedJobs',
      headerName: 'Accepted Jobs',
      minWidth: 150, flex: 1 ,
      renderCell: (params) => {
        if (params.row.isActive === 1 && params.row.isCompleted === 0 && params.row.isRejected === 0) {
          return <div className="tickIcon"><TiTick /></div>;
        } else {
          return <div className="crossIcon"><ImCross/></div>;
        }
      }
    },
    {
      field: 'completedJobs',
      headerName: 'Completed Jobs',
      minWidth: 150, flex: 1 ,
      renderCell: (params) => {
        if (params.row.isActive === 0 && params.row.isCompleted === 1 && params.row.isRejected === 0) {
          return <div className="tickIcon"><TiTick /></div>;
        } else {
          return <div className="crossIcon"><ImCross/></div>;
        }
      }
    }
  ];

  // Add serial numbers to each row
  const allOperatorJobs = operatorJobs.map((job, index) => ({
    ...job,
    SrNo: index + 1
  }));

  return "owner" === CheckRole() || "manager" === CheckRole() ? (
    <div className="Project_Container">
      {loading ? (
        <Loader />
      ) : (
        <>
          {operatorJobs.length === 0 ? <><h4 className="Not_Data_heading">No individual operator jobs added yet</h4></>:<><DataTable columns={columns} data={allOperatorJobs} /></>}

          <div className="nav_btn_group">
          <Link className="secondary_btn"to={`/project-details/${projectId}/employees/view-operators`}>Operater job +</Link>
          <Link className="secondary_btn" to={`/project-details/${projectId}/jobs/view-broadcast-jobs`}>View broadcast jobs</Link>
          <Link className="secondary_btn"to={`/project-details/${projectId}/jobs/add-broadcast-jobs`}>Add broadcast job +</Link>
          </div>
        </>
      )}
    </div>
  ) : (
    <NotAuthorized />
  );
};

export default ViewOperatorJobs;
