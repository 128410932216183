import React, { useEffect, useState } from 'react';
import './Header.css';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { logout } from '../../Features/Auth/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';
import CheckRole from "../../Utils/CheckRole/CheckRole";
import { IoNotifications } from "react-icons/io5";
import { MdOutlineCancel } from "react-icons/md";
import MobileNav from '../MobileNav/MobileNav';
import { getOperatorInvitesProject, getManagerInvitesProject } from "../../Features/Notifications/NotificationSlice";




const Header = () => {
  const [modal, setModal]=useState(false)
  const { operatorInvitesProject } = useSelector((store) => store.notification);
  const { managerInvitesProject } = useSelector((store) => store.notification);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { user } = useSelector((store) => store.auth);
  const email = user?.email
  const [activeLink, setActiveLink] = useState(null);

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

useEffect(() => {
    dispatch(getOperatorInvitesProject(email));
    dispatch(getManagerInvitesProject(email));
}, [dispatch, email]);



const invitedProjectsOperator = operatorInvitesProject?.invite?.filter((projects) => projects.invited === 1 && projects.joined === 0) || [];
const notificationCountOperator = invitedProjectsOperator.length;

const invitedProjectsManager = managerInvitesProject?.invite?.filter((projects) => projects.invited === 1 && projects.joined === 0) || [];
const notificationCountManager = invitedProjectsManager.length;



return (
  <div className="Header_container" >

    <div className="Header">
      <Link to="/">
      <div className="header_left">
        <h5>FORI MOBILITY</h5>
      </div>
      </Link>

        {user ? (
          <div className='header_right'>   
        <div className='flex_row_center_center header_right_c1'>
        {("owner" === CheckRole()) ? (
          <>
          <NavLink to="/">
            Home
          </NavLink>
          <NavLink to="/pricing-model">
            Pricing
          </NavLink>
          <NavLink to="/subscription">
            Subscription
          </NavLink>
          </>
        ) : ("manager" === CheckRole()) ? (
          <>
          <NavLink to="/">
            Home
          </NavLink>
          <NavLink to="/notifications-manager">
            <IoNotifications/>
            {notificationCountManager > 0 && <span className="notification-count">{notificationCountManager}</span>}
          </NavLink>

          </>
        ) :
        <>
          <NavLink to="/">
            Broadcast Jobs
          </NavLink>
          <NavLink to="/assigned-jobs-for-operator">
            Assigned Jobs
          </NavLink>
          <NavLink className='notification_icon' to="/notifications-operator">
            <IoNotifications />
            {notificationCountOperator > 0 && <span className="notification-count">{notificationCountOperator}</span>}
          </NavLink>
        </>
        }
        </div>
        {modal==true?        <div onMouseLeave={()=>setModal(false)} className='Modal_Avatar'>
        <button onClick={()=>setModal(false)} className='cancelBtn'><MdOutlineCancel/></button>
        <small><b>Name: </b>{user.name}</small>
          <small><b>Role: </b>{user.role}</small>
        <button className="btn logoutBtn" onClick={() => {dispatch(logout()).then(()=> navigate('/signin'));setModal(false)}}>
            Logout
          </button>
        </div>:null}

        <div className='name_avatar' onClick={()=>setModal(true)} onMouseEnter={()=>setModal(true)}><p> {user.name[0]}</p></div>

        </div>
        ) : (
        <div className='flex_row_center'>
          <NavLink to="/signup" className={activeLink === 'signup' ? 'active' : 'non_active'}onClick={() => handleLinkClick('signup')}>
            Sign Up
          </NavLink>
          <NavLink to="/signin" className={activeLink === 'signin' ? 'active' : 'non_active'} onClick={() => handleLinkClick('signin')}>
            Sign In
          </NavLink>
        </div>
        )}
    </div>
    {user!=null ?<MobileNav role={CheckRole()}/>:null}

  </div>
  );
};

export default Header;
