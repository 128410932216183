import React, { useState, useEffect } from 'react';
import './SignUp.css';
import { VscEye } from 'react-icons/vsc'
import { VscEyeClosed } from "react-icons/vsc"
import { useSelector, useDispatch } from 'react-redux'
import { Link , useNavigate} from 'react-router-dom'
import { toast } from "react-toastify"
import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
import AOS from 'aos';
import { SignUp_User, reset } from "../../Features/Auth/AuthSlice"
import CheckRole from '../../Utils/CheckRole/CheckRole';



const SignUp = () => {
    AOS.init({ offset: 400,delay: 10});
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [iconShow, setIconShow] = useState({ passShow: true, CpassShow:true})


    const [data, setData] = useState({ name:'', email:'', phone:'', password:'', confirmPassword: '' });
    const { name, email, phone, password, confirmPassword } = data

    const {  isLoading, isSuccess } = useSelector((store) => store.auth)


    const onChange = (e) => {
        setData((prevState)=>({ ...prevState, [e.target.name]:e.target.value}))
    }

    const onPhoneChange = (value) => {
        setData((prevState) => ({ ...prevState, phone: value }));
    };
    

    const onSubmit = (e) => {
        e.preventDefault()
        if( name === '' || email === '' || phone === '' || password === '' || confirmPassword === '' ){
            toast.info('Please fill all fields')
        }else if( password !== confirmPassword ){
            toast.info('Passwords do not match')
        }else if ( name !== '' && email !== '' && phone !== '' && password !== '' && confirmPassword !== '' && password === confirmPassword ){
            const userData = { name: name, email: email.toLowerCase(), password: password , phone:phone }
            dispatch(SignUp_User(userData))
        }
    }

    useEffect(() => {
        if(isSuccess){
            if ('owner' === CheckRole() || 'manager' === CheckRole() || 'operator' === CheckRole()) {
                navigate('/')
            }
        }
        dispatch(reset())
        }, [isSuccess, dispatch, navigate])



return (
<section className='flex_row_center_center Main_SignUp_Container'>
    <form onSubmit={onSubmit} className='flex_column form'>
        <div className="flex_column form_group">
            <label>Name</label>
            <input className='input-field' type="text" id='name' name='name' value={name} placeholder='Enter Your Name' onChange={onChange} />
        </div>
        <div className="flex_column form_group">
            <label>Email</label>
            <input className='input-field' type="email" id='email' name='email' value={email} placeholder='Enter Your Email' onChange={onChange} />
        </div>
        <div className="flex_column form_group">
            <PhoneInput country={'no'} containerStyle={{fontWeight:'bold'}} label='none' countryCodeEditable={true} placeholder="Enter phone number" value={phone} onChange={onPhoneChange}/>
        </div>
        <div className="flex_column form_group password_div">
            <label>Password</label>
            <div className='flex_row_center_center'>
                <input className='input-field' type={iconShow.passShow === true ? "text" : "password"} id='password' name='password' value={password} placeholder='Enter Your password' onChange={onChange} />
                {iconShow.passShow === true ? <VscEye className='site_icon'  onClick={() => setIconShow({...iconShow,"passShow":!iconShow.passShow})} /> : <VscEyeClosed className='site_icon'  onClick={() => setIconShow({...iconShow,"passShow":!iconShow.passShow})}/>}
            </div>
        </div>
        <div className="flex_column form_group password_div">
            <label>Confirm password</label>
            <div className='flex_row_center_center'>
                <input className='input-field' type={iconShow.CpassShow === true ? "text" : "password"} id='confirmPassword' name='confirmPassword' value={confirmPassword} placeholder='Confirm Your Password' onChange={onChange} />
                {iconShow.CpassShow === true ? <VscEye className='site_icon'  onClick={() => setIconShow({...iconShow,"CpassShow":!iconShow.CpassShow})} /> : <VscEyeClosed className='site_icon'  onClick={() => setIconShow({...iconShow,"CpassShow":!iconShow.CpassShow})} />}
            </div>
        </div>
        <div className="flex_column form_group">
            <button type='submit' className='btn'>Create Account</button>
        </div>
        <p>Already have an account? <Link className='special_a_tag' to='/signin'>Sign In</Link> here</p>
    </form>
    <div className='border'></div>
    <div className='flex_column_center_center form_right'>
        <h4>Sign Up to Fori Mobility</h4>
    </div>
</section>

)
}

export default SignUp