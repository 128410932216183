import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../Features/Auth/AuthSlice'
import groupReducer from '../Features/Group/GroupSlice'
import projectReducer from '../Features/Project/ProjectSlice'
import managerReducer from '../Features/ProjectDetail/ManagerSlice'
import operatorReducer from '../Features/ProjectDetail/OperatorSlice'
import addJobReducer from '../Features/ProjectDetail/AddJobSlice'
import carReducer from '../Features/ProjectDetail/CarSlice'
import parkingReducer from '../Features/ProjectDetail/ParkingSlice'
import drivingZoneReducer from '../Features/ProjectDetail/DrivingZoneSlice'
import vendorReducer from '../Features/ProjectDetail/VendorSlice'
import carPreferenceReducer from '../Features/ProjectDetail/CarPreferenceSlice'
import operatorHomeReducer from '../Features/OperatorHome/OperatorHomeSlice'
import notificationReducer from '../Features/Notifications/NotificationSlice'


export const store = configureStore({
  reducer: {
    auth: authReducer,
    group: groupReducer,
    project: projectReducer,
    manager: managerReducer,
    operator: operatorReducer,
    addJob: addJobReducer,
    car: carReducer,
    parking: parkingReducer,
    drivingZone: drivingZoneReducer,
    vendor: vendorReducer,
    carPreference: carPreferenceReducer,
    operatorHome: operatorHomeReducer,
    notification: notificationReducer
  },
});
