import React, { useEffect, useState } from 'react';
import CheckRole from '../../Utils/CheckRole/CheckRole';
import NotAuthorized from '../NotAuthorized/NotAuthorized';
import { getCategoryAndTask } from '../../Features/ProjectDetail/AddJobSlice';
import { useDispatch, useSelector } from 'react-redux';
import { textAlign } from '@mui/system';

const PricingModel = () => {
  const dispatch = useDispatch();

  const [prices, setPrices] = useState({
    small: '',
    medium: '',
    large: '',
  });

  const [activeSection, setActiveSection] = useState('cars');

  const { categoryAndTask } = useSelector((store) => store.addJob);

  const tasksNames = categoryAndTask.result?.flatMap((category) =>
    category.tasks.map((task) => task.name)
  );

  const onSubmit = (e) => {
    e.preventDefault();
    const userData = {};
  };

  useEffect(() => {
    dispatch(getCategoryAndTask());
  }, [dispatch]);

  return (
    (('owner' === CheckRole()) ? (
      <div className='flex_row_center_center Project_Container'>
        <form onSubmit={onSubmit} className='flex_column form form_project'>
          <div className='flex_row_gap flex_row_center_center'>
          <button
            className={`active_btn ${activeSection === 'cars' ? 'active' : ''}`}
            onClick={() => setActiveSection('cars')}
          >
            Car Pricing
          </button>
          <button
            className={`active_btn ${activeSection === 'operators' ? 'active' : ''}`}
            onClick={() => setActiveSection('operators')}
          >
            Operator Pricing
          </button>
          </div>
        {activeSection === 'cars' && (
        <>
            <div className='flex_column form_group form_group_project'>
              <label>Fori's Share: 10%</label>
            </div>
          <div className='flexWrap'>
            <div className='flex_row_project'>
              <div className='flex_column form_group form_group_project'>
                <label>Small</label>
                <input
                  type='number'
                  placeholder='Enter price'
                  value={prices.small}
                  onChange={(e) =>
                    setPrices((prevPrices) => ({
                      ...prevPrices,
                      small: parseInt(e.target.value, 10),
                    }))
                  }
                />
              </div>
              <div className='flex_column form_group form_group_project'>
                <label>Medium</label>
                <input
                  type='number'
                  placeholder='Enter price'
                  value={prices.medium}
                  onChange={(e) =>
                    setPrices((prevPrices) => ({
                      ...prevPrices,
                      medium: parseInt(e.target.value, 10),
                    }))
                  }
                />
              </div>
              <div className='flex_column form_group form_group_project'>
                <label>Large</label>
                <input
                  type='number'
                  placeholder='Enter price'
                  value={prices.large}
                  onChange={(e) =>
                    setPrices((prevPrices) => ({
                      ...prevPrices,
                      large: parseInt(e.target.value, 10),
                    }))
                  }
                />
              </div>
            </div>
          </div>
        </>
        )}
        {activeSection === 'operators' && (
          <>
            <div className='flex_row flexWrap'>
              {tasksNames?.map((taskName, index) => (
                <div key={index} className='flexWrapChild flex_column form_group form_group_project'>
                  <label>{taskName}</label>
                  <input
                    type='number'
                    placeholder={`Enter price for ${taskName}`}
                    value={prices[taskName] || ''}
                    onChange={(e) =>
                      setPrices((prevPrices) => ({
                        ...prevPrices,
                        [taskName]: parseInt(e.target.value, 10),
                      }))
                    }
                  />
                </div>
              ))}
            </div>
          </>
        )}
        <div>
          <button className='btn' type='submit' onClick={onSubmit}>
            Add Pricing
          </button>
        </div>
        </form>
      </div>
    ) : (
      <NotAuthorized />
    ))
  );
};

export default PricingModel;
