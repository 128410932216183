import React, { useEffect } from 'react'
import './Home.css'
import { DashboardCards } from '../../Data/Data'
import { Link } from 'react-router-dom'
import CheckRole from '../../Utils/CheckRole/CheckRole'
import NotAuthorized from '../NotAuthorized/NotAuthorized'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import OperatorHome from '../OperatorHome/OperatorHome'
import Tooltip from "../../Components/ToolTip/Tooltip";

const Home = () => {
    const navigate = useNavigate()
    const { user } = useSelector((store) => store.auth)


    useEffect(() => {
        if (!user) {
            navigate('/signin');
        }
    }, [ user]);


return (
    ( ('owner' === CheckRole() || 'manager' ===  CheckRole()) ?
    (<div className="Home_Maincard"  >

      <div className="flex_column Home_card">
      <div className="homeCard_title">
    <h6>Create your Projects and Groups</h6>
      </div>
    <div className=" Card_parent">
    {DashboardCards.map((card, index)=>{
        return(
          <div className="flex_column Card_parent_child"key={index}>
              <div  style={{display:"flex",justifyContent:"flex-end"}}><Tooltip text={card.toolTipText} color={"--primary_color"}/></div>
              <div className='flex_row_space_between'>
              <h4>{card.title}</h4>
              {card.icon}
              </div>
              <div className='flex_row_space_between' style={{flexWrap:"wrap",gap:".5rem"}}>
              {('manager' ===  CheckRole() && index==0)?null:card.linkadd}
              {card.linkView}
              </div>
              {card.description}
          </div>
          
        )
    })}
    </div>
    </div>


    </div>) :  ('operator' ===  CheckRole()) ? <OperatorHome/> :  <NotAuthorized/>
    )
  )
}


export default Home