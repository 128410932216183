import React from 'react';
import {MdDoNotDisturb } from 'react-icons/md'
const NotAuthorized = () => {
  return (
    <div className="under-construction-container site_padding">
      <div className="construction-icon">
        <MdDoNotDisturb />
      </div>
      <h1 className="construction-heading">Not Authorized</h1>
      <p className="construction-text">
        You are not authorized to view this page.
      </p>
    </div>
  );
};

export default NotAuthorized;
