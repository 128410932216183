import React, { useEffect, useState } from "react";
import { Link ,useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaEdit, FaTrash } from "react-icons/fa";
import CheckRole from "../../Utils/CheckRole/CheckRole";
import NotAuthorized from "../NotAuthorized/NotAuthorized";
import { getProject, deleteProject } from "../../Features/Project/ProjectSlice";
import Loader from "../../Components/Loader/Loader";
import DataTable from '../../Components/DataTable/DataTable';

const Projects = () => {
  const dispatch = useDispatch();
  const { projects ,isLoading} = useSelector((store) => store.project);
  const { user } = useSelector((store) => store.auth);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate()

  useEffect(() => {
    if (!user) {
        navigate('/signin');
    }
}, [ user]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getProject());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  const activeProjects = projects?.filter((project) => project.project.isActive === 1).map((project, index) => ({ ...project, SrNo: index + 1 }));


  const columns = [
    { field: 'SrNo', headerName: 'Sr.No', width: 150 ,flex:1},
    { field: 'projectId', headerName: 'Project Id', width: 150 ,flex:1},
    { field: 'project.name', headerName: 'Project Name', width: 150 ,flex:1, valueGetter: (params) => params.row.project.name,  },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 150 ,flex:1,
      renderCell: (params) => (
        <Link className="editLink" to={`/update-project/${params.row.projectId}`}>
          <FaEdit />
        </Link>
      ),
    },
    {
      field: 'delete',
      headerName: 'Delete',
      width: 150 ,flex:1,
      renderCell: (params) => (
        <button
          className="deleteButton"
          onClick={() => dispatch(deleteProject(params.row.projectId)).then(() => dispatch(getProject()))}
        >
          <FaTrash />
        </button>
      ),
    },
    {
      field: 'projectDetails',
      headerName: 'Project Details',
      width: 150 ,flex:1,
      renderCell: (params) => (
        <Link className="btn" to={`/project-details/${params.row.projectId}`}>
          Project Details
        </Link>
      ),
    },
  ];
  if(isLoading){return <Loader/>}
  return "owner" === CheckRole() || "manager" === CheckRole() ? (
    <div className="Group_CardContainer"> 
      {loading ? (
        <h5>
          <Loader />
        </h5>
      ) : (
        <>
          {activeProjects?.length == 0 ? (
            <h5>No project added yet</h5>
          ) : (
            <>
              <h4>All Projects detail</h4>
              <DataTable columns={columns} data={activeProjects} />
            </>
          )}

          {CheckRole() === "owner" ? (
            <div className="add_btn">
              <Link to="/add-project" className="btn">
                Add Project
              </Link>
            </div>
          ) : null}
        </>
      )}
    </div>
  ) : (
    <NotAuthorized />
  );
};

export default Projects;
