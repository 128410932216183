import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import "./ImpactPage.css";
import CardComponent from '../../Components/ImpactPageComponents/CardComponent/CardComponent'
import  BarChart  from '../../Components/ImpactPageComponents/Charts/BarChart'
import  LineChart  from '../../Components/ImpactPageComponents/Charts/LineChart'
import { useState } from 'react'
import {TbRefresh} from 'react-icons/tb'
import {toast} from 'react-toastify'
import FigureCard from '../../Components/ImpactPageComponents/FigureCard/FigureCard'
import ImpactCard from '../../Components/ImpactPageComponents/ImpactCard/ImpactCard'
import {DummyData} from "../../Data/ImpactDummyData"
import InfoBox from '../../Components/ImpactPageComponents/Infobox/InfoBox'
import { FiSettings } from 'react-icons/fi';
import ThemeSettings from "../../Components/ImpactPageComponents/Themesetter/ThemeSettings";
// import {  setSelectedTheme} from "../../../features/theme/themeSlice";
import HBar from "../../Components/ImpactPageComponents/Charts/HBar"
import getSuffixedNumber from '../../Utils/SuffixNumber/SuffixNumber';
import { HtmlToImage } from '../../Utils/HtmlToImage/HtmlToImage';
import {TbDownload} from 'react-icons/tb'
import NotAuthorized from "../NotAuthorized/NotAuthorized";
import CheckRole from "../../Utils/CheckRole/CheckRole";


const ImpactPage = () => {
    const [currentColor, setCurrentColor] = useState('#007bff')
  
  const componentRef = useRef(null);

  const handleCapture = (format) => {
    HtmlToImage(componentRef.current,format)

  }
  const dispatch=useDispatch()
//   const {currentColor}=useSelector((store)=>store.theme)
  const [ThemeSetting,setThemeSetting]=useState(false)

  const [activeFilter, setActiveFilter ] = useState('days30')
  const [filter,setFilter] = useState('days30')
  const [show, setShow] = useState({ graph1:false, graph2:false})

  const refreshData =()=>{
   toast.info("Please Connect Your Dashboard")
  }


  const ChoseFilter = (filter) =>{
    setActiveFilter(filter)
    setFilter(filter)
  }
const [Gdata, setGdata]=useState(DummyData.days30)
const cardData = Gdata.cardData
  
  useEffect(()=>{
    switch(filter){
      case "days30":
        setGdata(DummyData.days30)
        break;
      case "month12":
        setGdata(DummyData.month12)
        break;
      case "year":
        setGdata(DummyData.year)
        break;
      default:
        setGdata(DummyData.days30)
    }

  },[filter,Gdata])
 


//   useEffect(()=>{
//     dispatch(setSelectedTheme())
//   },[])


  return (
    (('owner' === CheckRole() || "manager" === CheckRole()) ? (
    <>
      <section className="DashboardHeader">
      <div className="company_logo_container">
              <img src={require('../../Assets/Images/fori_logo.png')} alt="" /> 
        </div>

        <div className="DashboardHeader_right">

      <button className="secondary_btn flex_row_center" onClick={()=>handleCapture('png')}>Download<TbDownload className='icon_react'/></button>
        <div className="flex_row_center">
        <div className="avatar flex_row">J</div>
        <h6>Jhon Doe</h6>
        </div>
        </div>

    
      </section>



      <div className="dashboard_container" ref={componentRef}>
        <div className="date_control">
          <h5 className="dash_heading">Fori Impact Dashboard</h5>

          <div className="control_buttons">
            <div className="RefreshData btn" onClick={refreshData}> Refresh Data<TbRefresh /></div>
            <button onClick={() => ChoseFilter("year")} className={   activeFilter === "year" ? "activeFilter" : "deactive_filter" }>  yearly</button>
            <button onClick={() => ChoseFilter("month12")} className={   activeFilter === "month12" ? "activeFilter" : "deactive_filter" }>Last 12 Months</button>
            <button onClick={() => ChoseFilter("days30")} className={activeFilter === "days30" ? "activeFilter" : "deactive_filter"}>Last 30 Days</button>
          </div>
        </div>

        <div>
          <h5>Your Fleet of <span style={{color:"var(--primary_color)"}}>{DummyData.year.cardData.fleetsize.total}</span> Vehicles completed a total of <span style={{color:"var(--primary_color)"}}>{DummyData.year.cardData.rides.total}</span> rides collectively contributed to a significant reduction of <span style={{color:"var(--primary_color)"}}>{Math.round(DummyData.year.cardData.co2Status.saved)}</span> kilograms in carbon emissions.</h5>
        </div>

        <div className='CombustibleCardsParent'>
                <CardComponent width="30%" title={`Tailpipe CO2 emissions Saved due to EVs ${filter==="days30"?"in last 30 days":filter==="month12"?"in last 12 Months":"collectively"}`} value={Math.round(cardData.co2Status.saved)} unit="kg" description="In some countries tailpipe emissions accounts for 70% of the sector's total carbon emissions."  graphNum={"graph7"} graphInfo="Tailpipe emissions are in case of combustion engine vehicles while EV produce upstream emissions while charging."/>
                <CardComponent width="30%" title={`Trees Relaxed due to Electric Vehicles ${filter==="days30"?"in last 30 days":filter==="month12"?"in last 12 Months":"collectively"}`} value={Math.round(cardData.trees_relaxed *-1)} unit="" description="You relaxed these much trees by adopting a safer and more eco-friendly means of transportation." graphNum={"graph8"} graphInfo="Using EV and E-scooters we can relax trees, This states the number of trees relaxed for that specific time."/>
                <CardComponent width="30%" title={`Estimated Fuel Saved due to Electric Vehicles ${filter==="days30"?"in last 30 days":filter==="month12"?"in last 12 Months":"collectively"}`}  value={Math.round(cardData.fuelStatus.saved)}  unit="litter" description="Burning one litter of fuel can emit upto 2.5 kilograms of Carbon dioxide, using EVs saves tons of CO2." graphNum={"graph9"} graphInfo="Running cost of an electric vehicles in terms of fuel is way less than combustion engine vehicles."/>
                <CardComponent width="30%" title={`Estimated Private Rides Reduced ${filter==="days30"?"in last 30 days":filter==="month12"?"in last 12 Months":"collectively"}`}  value={Math.round(cardData.privateRidesReduced)}  unit="rides" description="If a vehicle is used by multiple users it reduces congestion and private rides on the road." graphNum={"graph10"} graphInfo="Ride sharing, pooling and using dedicated services reduce a specific amount of private vehicle rides leading to less congestion."/>

            </div>

        <div className="dashboard_box card_Box">
          <FigureCard heading="Total distance" cardData={cardData.distance}  color={currentColor}  unit="km"/>
          <FigureCard heading="Total Rides" cardData={cardData.rides} color={currentColor}  />
          <FigureCard heading="Fleet size"  cardData={cardData.fleetsize} color={currentColor}  />
          <div className="avg_co2_card"> <h6>Average CO2 per Ride</h6> <h5> {getSuffixedNumber(cardData.avgCo2PerRide)} Kg </h5> </div>

        </div>


        <div className="chart_row">
          <div className="chart_box">
            <InfoBox show={show} setShow={setShow} graph="graph1" info={`This represents the kilograms of carbon emissions emitted due to using either combustion engine vehicles for rides or upstream emissions of electric vehicles ${filter === "days30"? "in last 30 days of your data.": filter === "month12"? "in last 12 months of your data.": "over years"} Upstream emissions are the emissions released when an electric car or scooter is charged, it is normally way less impact as compared to combustion engine. `}/>
            <LineChart data={Gdata.co2_emitted} title="CO2 emitted by combustion engine vehicle or Upstream Emissions of Electric rides" showTitle={true} color={currentColor}/>
          </div>
          <div className="chart_box">
            <InfoBox show={show} setShow={setShow} graph="graph2" info={`This represents the kilograms of carbon emissions saved due to utilizing electric vehicles for rides ${filter === "days30"? "in last 30 days of your data.": filter === "month12"? "in last 12 months of your data.": "over years"}`}/>
            <LineChart data={Gdata.co2_saved} title="CO2 Saved due to electric vehicle or e-scooter rides" showTitle={true} color={currentColor}/>
            
          </div>
       
        </div>
        <div className="chart_row">
          <div className="chart_row_with_cards" >
            <InfoBox show={show} setShow={setShow} graph="graph3" info={`This graph represents the comparative analysis of what you have saved vs what you have emitted ${filter === "days30"? "in last 30 days of your data.": filter === "month12"? "in last 12 months of your data.": "over years"} Ideally you should be saving more than you are emitting as a result of upstream emissions or tailpipe emissions.`}/>
            <div className="chart_box_with_cards">
            <HBar data={Gdata.co2Status}  title="CO2 saved and released" showTitle={true} color={currentColor} Xaxis="Carbon Emissions in Kgs"/>
            </div>
            <ImpactCard heading="Net CO2 status" data={cardData.co2Status}  color={currentColor}  unit="kg" good={false}/>

          </div>
          <div className="chart_row_with_cards" >
            <InfoBox show={show} setShow={setShow} graph="graph4" info={`This graph represents the comparative analysis of the amount of fuel you have used or saved by using electric vehicles ${filter === "days30"? "in last 30 days of your data.": filter === "month12"? "in last 12 months of your data.": "over years"} Charging an EV to cover same distance as of a combustion engine vehicle will cost much less in terms of carbon impact as upstream emissions are much less than tailpipe emissions.`}/>
            <div className="chart_box_with_cards">
            <HBar data={Gdata.fuelStatus}  title="Fuel saved and consumed" showTitle={true} color={currentColor} Xaxis="Fuel Status in Litters"/>
            </div>
            <ImpactCard heading="Net Fuel status" data={cardData.fuelStatus}  color={currentColor}  unit="litter" good={false}/>

          </div>
        </div>
        
        <div className="chart_row" >
          <div className="chart_box" >
            <InfoBox show={show} setShow={setShow} graph="graph5" info={`This graph represents the amount of trees relaxed and burdened each day to absorb the amount of emitted carbon emissions ${filter === "days30"? "in last 30 days of your data.": filter === "month12"? "in last 12 months of your data.": "over years"} `}/>
            <BarChart data={Gdata.trees} title="Trees Relaxed and Trees Burdened for a day" showTitle={true} color={currentColor} />
          </div>
          <div className="chart_box" >
            <InfoBox show={show} setShow={setShow} graph="graph6" info={`This graph represents the amount of fuel used and saved each day due to specific ride behaviors each day ${filter === "days30"? "in last 30 days of your data.": filter === "month12"? "in last 12 months of your data.": "over years"} You should be saving more than consuming to save the planet.`}/>
            <BarChart data={Gdata.fuel} title="Fuel Saved and consumed" showTitle={true} color={currentColor} />
          </div>

        </div>
        <button
                type="button"
                onClick={() =>{ setThemeSetting(!ThemeSetting)}}
                // style={{ background: currentColor, }}
                className="SettingBtn"
              >
                <FiSettings />
              </button>
        {ThemeSetting && (<ThemeSettings Obj={{ThemeSetting, setThemeSetting}} />)}

      </div>
    </>) : (
      <NotAuthorized />
    ))
  );
};

export default ImpactPage;
