import React, { useEffect, useState } from "react";
import "./Subscription.css";
import { useNavigate,Link } from "react-router-dom";
import { useSelector } from 'react-redux'


const Subscription = () => {
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [cvcFocused,setCvcFoucsed]=useState(false)
  const navigate = useNavigate();
  const {user} = useSelector((store) => store.auth)

  useEffect(()=>{
    if(!user){
      navigate("/signin")
    }
  },[user,navigate])

  const handleCardNumberChange = (e) => {
    let value = e.target.value.replace(/\s+/g, '');
    if (value.length > 16) {
      value = value.slice(0, 16);
    }
    setCardNumber(value.replace(/(\d{4})(?=\d)/g, '$1 '));
  };

  const handleExpiryChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    let formattedValue = "";

    if (value.length <= 2) {
      formattedValue = value;
    } else if (value.length === 3) {
      formattedValue = value.slice(0, 2) + "/" + value.slice(2);
    } else if (value.length > 3) {
      formattedValue = value.slice(0, 2) + "/" + value.slice(2, 4);
    }

    setExpiry(formattedValue);
  };

  const handleCvcChange = (e) => {
    const value = e.target.value;
    if (value.length <= 3) {
      setCvc(value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (cardNumber.length < 19 || expiry.length < 5 || cvc.length < 3) {
      alert("Completely fill out the card information");
    } else {
      const newCard = { cardNumber, expiry, cvc };

      const cards = JSON.parse(localStorage.getItem("cards")) || [];

      cards.push(newCard);

      localStorage.setItem("cards", JSON.stringify(cards));
    }
  };

  return (
  <div className=" flex_row_center_center Project_Container">
    <div className="flex_column form form_project">
      <h4>Get Subscription</h4>
      <div className="flex_row_center_center flex_row_gap subscriptionCard_parent">
        <div className="flex_column_center_center flip-box">
          <div className="flip-box-inner" id="flip-box-inner">
          <div className="flex_row_center_center card-image flip-box-front">
          <div className="card-number-display">
            {cardNumber.length === 0 ? "**** **** **** ****" : cardNumber}
          </div>
          <div className="flex_column expiry_data_card">
            <div>Month/Year</div>
            <div>{expiry}</div>
          </div>
        </div>
        <div className="flex_column_center_center card-image flip-box-back">
          <div className="flex_column_center_center card-CVC">
            <p>Enter CVC</p>
            <span>{cvc}</span>
          </div>
        </div>
          </div>

        </div>

        <form onSubmit={handleSubmit} className="flex_column form_project card-input-form">
          <div className="flex_column form_group form_group_project">
            <label className="card_labels" htmlFor="cardNumber">
              CARD NUMBER
            </label>
            <input  type="text"  id="cardNumber" value={cardNumber} onChange={handleCardNumberChange} placeholder="1234 5678 1234 5678"  maxLength="19"  />
          </div>
          <div className="flex_column form_group form_group_project">
            <label className="card_labels" htmlFor="expiry">
              EXPIRY
            </label>
            <input type="text" id="expiry" value={expiry} onChange={handleExpiryChange} placeholder="MM/YY" maxLength="5" // 4 digits + 1 slash
            />
          </div>
          <div className="flex_column form_group form_group_project">
            <label className="card_labels" htmlFor="cvc">
              CVC
            </label>
            <input type="number" id="cvc" value={cvc} onChange={handleCvcChange} placeholder="CVC" maxLength="3" onFocus={()=>{document.getElementById("flip-box-inner").style.transform="rotateY(180deg)"}} onBlur={()=>{document.getElementById("flip-box-inner").style.transform="inherit"}}
            />
          </div>
          <button type="submit" className="btn">  Add </button>
      <Link to="/view-subscription" className=" tertiary_btn" > View Subscription</Link>

        </form>
      </div>
    </div>
  </div>
  );
};

export default Subscription;
