// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Group_CardContainer {
    padding: 8rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.groupCards {
    border: 1px solid var(--lgray);
    padding: 15px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}
.groupCards span {
    font-weight: bold;
    margin-right: 2rem;
}
.buttonsContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.marginRight {
    margin-right: 10px;
}
.groupDetailsButton {
    background-color: var(--primary_color);;
    color: var(--white);
    padding: 10px 15px;
    text-decoration: none;
    border-radius: 5px;
}
.groupDetailsButton:hover {
    background-color: var(--hover_primary_color);
}
.checkbox{
    display: flex;
    width: 4%;
    justify-items: left;
}


@media screen and (max-width: 800px) {
    .Group_CardContainer {
        padding: 2rem 2rem;

    }
    
}
@media screen and (max-width: 800px) {
    .Group_CardContainer {
        padding: 1rem;

    }
    
}`, "",{"version":3,"sources":["webpack://./src/Pages/Groups/Group.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;AACb;AACA;IACI,8BAA8B;IAC9B,aAAa;IACb,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,8BAA8B;AAClC;AACA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;AACvB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,sCAAsC;IACtC,mBAAmB;IACnB,kBAAkB;IAClB,qBAAqB;IACrB,kBAAkB;AACtB;AACA;IACI,4CAA4C;AAChD;AACA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB;;;AAGA;IACI;QACI,kBAAkB;;IAEtB;;AAEJ;AACA;IACI;QACI,aAAa;;IAEjB;;AAEJ","sourcesContent":[".Group_CardContainer {\n    padding: 8rem 2rem;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 1rem;\n}\n.groupCards {\n    border: 1px solid var(--lgray);\n    padding: 15px;\n    display: flex;\n    width: 100%;\n    align-items: center;\n    justify-content: space-between;\n}\n.groupCards span {\n    font-weight: bold;\n    margin-right: 2rem;\n}\n.buttonsContainer {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n}\n.marginRight {\n    margin-right: 10px;\n}\n.groupDetailsButton {\n    background-color: var(--primary_color);;\n    color: var(--white);\n    padding: 10px 15px;\n    text-decoration: none;\n    border-radius: 5px;\n}\n.groupDetailsButton:hover {\n    background-color: var(--hover_primary_color);\n}\n.checkbox{\n    display: flex;\n    width: 4%;\n    justify-items: left;\n}\n\n\n@media screen and (max-width: 800px) {\n    .Group_CardContainer {\n        padding: 2rem 2rem;\n\n    }\n    \n}\n@media screen and (max-width: 800px) {\n    .Group_CardContainer {\n        padding: 1rem;\n\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
