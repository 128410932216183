import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {toast} from 'react-toastify'

const initialState = {
    DrivingZones:[],
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:'',
}



//////////////////////Register DrivingZone///////////////////////////
export const registerDrivingZone = createAsyncThunk('projectDetail/registerDrivingZone',
async(DrivingZoneData, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/drivingZone/register`
        const response = await axios.post(API_URL, DrivingZoneData,{withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message)|| error.response.data.status || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

//////////////////////get DrivingZone///////////////////////////
export const getDrivingZone = createAsyncThunk('projectDetail/getDrivingZone',
async(projectId, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/project/getProjectDrivingZones/${projectId}`
        const response = await axios.get(API_URL, {withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.response.data.status || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

//////////////////////Atach DrivingZone to Car///////////////////////////
export const attachDrivingZoneToCar = createAsyncThunk('projectDetail/attachDrivingZoneToCar',
async(data, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/drivingZone/attachCar`
        const response = await axios.post(API_URL, data,{withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message)|| error.response.data.status || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})



export const DrivingZoneSlice = createSlice({
    name:'DrivingZones',
    initialState,
    reducers:{
        reset:(state)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        },
    },
    extraReducers:(builder) => {
        builder
        /////////////Register DrivingZone//////////////
        .addCase(registerDrivingZone.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(registerDrivingZone.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            toast.info(action.payload.message)
        })
        .addCase(registerDrivingZone.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            toast.info(action.payload)
        })
        /////////////Attach DrivingZone to car//////////////
        .addCase(attachDrivingZoneToCar.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(attachDrivingZoneToCar.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            toast.info(action.payload.message)
        })
        .addCase(attachDrivingZoneToCar.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            toast.info(action.payload)
        })
        /////////////get DrivingZone//////////////
        .addCase(getDrivingZone.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(getDrivingZone.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.DrivingZones= action.payload.result
            state.message = action.payload.message
            toast.info(action.payload.message)
        })
        .addCase(getDrivingZone.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.DrivingZones = []
            // state.message = action.payload.message
            // toast.info(action.payload.message)
        })
      
    }
})

export const { reset } = DrivingZoneSlice.actions
export default DrivingZoneSlice.reducer