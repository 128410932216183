import React, { useEffect, useState } from "react";
import NotAuthorized from "../NotAuthorized/NotAuthorized";
import CheckRole from "../../Utils/CheckRole/CheckRole";
import { useSelector, useDispatch } from "react-redux";
import { getAllTasks, operaterResponseToAssignedTask, operaterResponseMarkAsComplete } from "../../Features/OperatorHome/OperatorHomeSlice";
import Loader from '../../Components/Loader/Loader';
import DataTable from '../../Components/DataTable/DataTable';
import { ImCross } from "react-icons/im";
import { TiTick } from "react-icons/ti";



const OperatorAssignedJobs = () => {
  const { allTasks } = useSelector((store) => store.operatorHome);
  const [loading, setLoading] = useState(true);
  const [activeList, setActiveList] = useState("activeJobs");
  const { user } = useSelector((store) => store.auth);
  const operatorId = user?.id;

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAllTasks());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  const allJobs = allTasks?.flat() || [];

  // Filter projects with isBroadcast key set to 0
  const assignedProjects = allJobs.filter(project => project.isBroadcast === 0);

  // Sort projects based on status (active, accepted, rejected, completed)
  assignedProjects.sort((a, b) => {
    if (a.isActive !== b.isActive) {
      return b.isActive - a.isActive; // Active jobs first
    }
    if (a.isActive && b.isActive) {
      return a.isCompleted - b.isCompleted; // Completed jobs next
    }
    if (a.isCompleted !== b.isCompleted) {
      return a.isCompleted - b.isCompleted; // Accepted jobs next
    }
    if (a.isRejected !== b.isRejected) {
      return a.isRejected - b.isRejected; // Rejected jobs next
    }
    return 0;
  });

  const columns = [
    { field: 'SrNo', headerName: 'Sr.No', width: 100 },
    { field: 'projectId', headerName: 'Project Id', width: 150 },
    { field: 'name', headerName: 'Project Name', width: 200 },
    {
      field: 'activeJobs',
      headerName: 'Active Jobs',
      width: 150,
      renderCell: (params) => {
        if (params.row.isBroadcast === 0 && params.row.isActive === 0 && params.row.isCompleted === 0 && params.row.isRejected === 0) {
          return <button className="deleteButton marginRight" onClick={() => dispatch(operaterResponseToAssignedTask({ taskId: params.row.id, projectId: params.row.projectId, response: "accept" })).then(() => {dispatch(getAllTasks()); setActiveList("acceptedJobs")})}>Accept</button>;
        } else {
          return <div className="crossIcon"><ImCross/></div>;
        }
      }
    },
    {
      field: 'rejectedJobs',
      headerName: 'Rejected Jobs',
      width: 150,
      renderCell: (params) => {
        if (params.row.isBroadcast === 0 && params.row.isActive === 0 && params.row.isCompleted === 0 && params.row.isRejected === 1) {
          return <div className="deleteButton"><ImCross/></div>;
        } else {
          return <div className="crossIcon"><ImCross/></div>;
        }
      }
    },
    {
      field: 'acceptedJobs',
      headerName: 'Accepted Jobs',
      width: 150,
      renderCell: (params) => {
        if (params.row.isBroadcast === 0 && params.row.isActive === 1 && params.row.isCompleted === 0 && params.row.isRejected === 0) {
          return <button className="deleteButton marginRight" onClick={() => dispatch(operaterResponseMarkAsComplete({ id: params.row.id })).then(() => {dispatch(getAllTasks()); setActiveList("completedJobs")})}>Mark as Complete</button>;
        } else {
          return <div className="crossIcon"><ImCross/></div>;
        }
      }
    },
    {
      field: 'completedJobs',
      headerName: 'Completed Jobs',
      width: 150,
      renderCell: (params) => {
        if (params.row.isBroadcast === 0 && params.row.isActive === 0 && params.row.isCompleted === 1 && params.row.isRejected === 0) {
          return <div className="tickIcon"><TiTick /></div>;
        } else {
          return <div className="crossIcon"><ImCross/></div>;
        }
      }
    }
  ];

  // Add serial numbers to each row
  const allAssignedProjects = assignedProjects.map((project, index) => ({
    ...project,
    SrNo: index + 1
  }));

  return "operator" === CheckRole() ? (
    <div className="Group_CardContainer">
      {loading ? (
        <h5><Loader /></h5>
      ) : (
        <>
          {assignedProjects.length === 0 ? (
            <h5>No jobs available yet</h5>
          ) : (
            <>
              <DataTable columns={columns} data={allAssignedProjects} />
            </>
          )}
        </>
      )}
    </div>
  ) : (
    <NotAuthorized />
  );
};

export default OperatorAssignedJobs;
