import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { FaTrash } from 'react-icons/fa';
import { MdInfoOutline } from 'react-icons/md';
import CheckRole from '../../Utils/CheckRole/CheckRole';
import NotAuthorized from '../NotAuthorized/NotAuthorized';
import { createGroup } from '../../Features/Group/GroupSlice';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';

const AddGroup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState({
    name: '',
    phoneVerification: 0,
    licenseVerification: 0,
    emailVerification: 0,
    groupType: '',
    userEmail: [],
  });
  const { name, phoneVerification, licenseVerification, emailVerification, groupType, userEmail } = data;

  const [showDescription, setShowDescription] = useState(false);

  const onChange = (e) => {
    const { name, value, type } = e.target;

    // Convert the value to a number if the input type is 'number'
    const parsedValue = type === 'number' ? parseInt(value, 10) : value;

    setData((prevState) => ({ ...prevState, [name]: parsedValue }));
  };

  const onCheckboxChange = (e) => {
    setData((prevState) => ({ ...prevState, [e.target.name]: e.target.checked ? 1 : 0 }));
  };

  const onEmailChange = (index, value) => {
    const updatedEmails = [...userEmail];
    updatedEmails[index] = value;
    setData((prevState) => ({ ...prevState, userEmail: updatedEmails }));
  };

  const removeEmailInput = (index) => {
    if (index !== 0) {
      const updatedEmails = [...userEmail];
      updatedEmails.splice(index, 1);
      setData((prevState) => ({ ...prevState, userEmail: updatedEmails }));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const emails = XLSX.utils.sheet_to_json(sheet, { header: undefined })
          .filter((row) => row !== null && typeof row === 'object')
          .map((row) => Object.values(row).filter((value) => typeof value === 'string'))
          .flat();
        setData((prevState) => ({ ...prevState, userEmail: emails }));
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (name === '' || userEmail.some((email) => email === '')) {
      toast.info('Please fill all fields');
    } else {
      const userData = {
        name,
        phoneVerification,
        licenseVerification,
        emailVerification,
        groupType,
        userEmail,
      };
      dispatch(createGroup(userData)).then((res) => res.payload.success === true && navigate(-1));
    }
  };

  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  return (
    (('owner' === CheckRole() || 'manager' === CheckRole()) ? (
      <div className='Project_Container'style={{padding:"2rem 0rem"}}>
            <div style={{ textAlign: 'center', width: '100%' }}>
              <h4>Add Group</h4>
          </div>
        <form onSubmit={onSubmit} className='flex_column form form_project'>
          <div className='flex_column form_group form_group_project'>
            <label>Group Name</label>
            <input type='text' id='name' name='name' value={name} placeholder='Enter group name' onChange={onChange} />
          </div>
          <div className='flex_column form_group form_group_project'>
            <label style={{ marginBottom: '1rem' }}>Select rules for the group!</label>
            <div className='flex_row form_group form_group_project' style={{ gap: '1rem' }}>
              <input
                className='checkbox'
                type='checkbox'
                name='phoneVerification'
                checked={phoneVerification}
                onChange={onCheckboxChange}
              />
              <div style={{ width: '100%' }}>
                <small>
                  <b>Phone Verification</b>
                </small>
              </div>
            </div>
            <div className='flex_row form_group form_group_project' style={{ gap: '1rem' }}>
              <input
                className='checkbox'
                type='checkbox'
                name='licenseVerification'
                checked={licenseVerification}
                onChange={onCheckboxChange}
              />
              <div style={{ width: '100%' }}>
                <small>
                  <b>License Verification</b>
                </small>
              </div>
            </div>
            <div className='flex_row form_group' style={{ gap: '1rem' }}>
              <input
                className='checkbox'
                type='checkbox'
                name='emailVerification'
                checked={emailVerification}
                onChange={onCheckboxChange}
              />
              <div style={{ width: '100%' }}>
                <small>
                  <b>Email Verification</b>
                </small>
              </div>
            </div>
          </div>
          <div className='flex_column form_group form_group_project'>
          <label>Group Type</label>
          <select type='text' id='groupType' name='groupType' value={groupType} onChange={onChange} >
            <option>Select Group Type</option>
            <option value='Public'>Public</option>
            <option value='Private'>Private</option>
          </select>
          </div>
          <div className='flex_column form_group form_group_project'>
            <label>
              Invite individuals to join this group
              <button className='iBtn' type='button' onClick={toggleDescription}>
                <MdInfoOutline />
              </button>
            </label>
            {showDescription && (
              <div>
                <small>
                  This can only accept excel sheets in the format like <a className='link_btn' href='https://docs.google.com/spreadsheets/d/1OZDTORDxv6WWaC3YpZsg7KiwS9TKNoSQDHDQcGepnus/edit?pli=1#gid=0'>Check format</a>
                </small>
              </div>
            )}
            <input type='file' accept='.xlsx, .xls' onChange={handleFileChange} />
            {userEmail.map((email, index) => (
              <div key={index} style={{ display: 'flex' }}>
                <input
                  type='email'
                  value={email}
                  onChange={(e) => onEmailChange(index, e.target.value)}
                />
                {index !== 0 && (
                  <button className='deleteButton' type='button' onClick={() => removeEmailInput(index)}>
                    <FaTrash />
                  </button>
                )}
              </div>
            ))}
          </div>
          <div>
            <button className='btn' type='submit'>
              Add Group
            </button>
          </div>
        </form>
      </div>
    ) : (
      <NotAuthorized />
    ))
  );
};

export default AddGroup;
