import React, { useRef, useEffect, useState }  from 'react'
import { MdInfoOutline } from "react-icons/md";
import "./Tooltip.css"
const Tooltip = ({text,color}) => {
  const tipColor=color?color:"lightgrey"
  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);
  const iconRef = useRef(null);
  const textref=useRef(null)

  useEffect(() => {
  
    if (iconRef.current) {
      const rect = iconRef.current.getBoundingClientRect();
      const rectTextref=textref.current.getBoundingClientRect()
  
      if(parseFloat(getComputedStyle(textref.current).width)>windowInnerWidth/2){
        textref.current.style.width=`calc(${windowInnerWidth /2}px)`
      }
      if(rect.x>=windowInnerWidth/2){
        textref.current.classList.add('right')
        textref.current.classList.remove('left');

      }else {
        textref.current.classList.add('left')
        textref.current.classList.remove('right');

      }
      if(rectTextref.y>=(windowInnerWidth-(rectTextref.height))){
        textref.current.classList.add('top')
        textref.current.classList.remove('bottom');

      }else {
        textref.current.classList.add('bottom')
        textref.current.classList.remove('top');
      }
    }
  },[windowInnerWidth]); 
useEffect(()=>{
  function handleresize(){
    setWindowInnerWidth(window.innerWidth)
  }
  window.addEventListener("resize",handleresize)
  return () => {
    window.removeEventListener('resize', handleresize);
  };
})
  return (
    <div  ref={iconRef} className={`tooltip`}>
         <MdInfoOutline style={{fontSize:".9rem",color:`${tipColor}`}}/>
         <div ref={textref}className={`tooltiptext`}>
          <ol>
            {text.map((li,index)=>{
              return <li  key={`li${index}`}>{li}</li>
            })}
          </ol>
         </div>
    </div>
  )
}

export default Tooltip

