import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const width =  window.innerWidth
let height = 400
if(width<=900){
   height = 250
}



export default function LineChart({data ,title, showTitle,color}) {

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      title: {
        display: showTitle,
        text: title,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Years", // X-axis title
        },
        grid: {
          display: false, // Turn off x-grid lines
        },
      },
      y: {
        title: {
          display: true,
          text: "Carbon Dioxide in Kilograms", // Y-axis title
        },
        grid: {
          display: true, // Turn off y-grid lines
        },
      },
    },
    maintainAspectRatio: false,
  };

  if(color){
    data = {
     ...data,
     datasets: [
       {
         ...data.datasets[0],
         borderColor: color.primary || '#0e0e0e',
         backgroundColor:color.primary || '#0e0e0e' // Update borderColor here
       }
     ]
   };

 }
  return <Line   options={options} data={data} height={height} style={{backgroundColor:"transparent"}}/>;
}
