export default function getSuffixedNumber(num) {
  if (num === 0) {
    return "0";
  }
  
  if (num < 1 && num > -1) {
    return num.toFixed(3); // Handle numbers between 0 and 1 with one decimal place
  }

  
  const SUFFIXES = ["", "K", "M", "B", "T"];
  const power = Math.floor(Math.log10(Math.abs(num)));
  const index = Math.floor(power / 3);
  const scaledNum = num / Math.pow(10, index * 3);
  
  return (scaledNum.toFixed(1) * (num < 0 ? -1 : 1)) + SUFFIXES[index];
}
