// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader_container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
  }
.Project_Container {
    width: 75vw;
    margin: auto;
    /* margin-top: 1rem; */
    /* padding: 20px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:2rem;
    border-radius: 8px;
    
}
.form_project {
    width: 100%;
    border:1px solid rgba(224, 224, 224, 1);
}
.form_group_project {
    margin-bottom: 20px;
}
.form_group_project label {
    margin-bottom: 10px;
}
.flex_row_project {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
}
.flex_row_project .form_group_project {
    flex: 1 1;
    margin-right: 10px;
}
.groups-dropdown {
    height: 50px;
    overflow-y: scroll;
}
@media screen and (max-width: 1025px){
    .Project_Container{
        margin-top: 3rem;
    }
}

@media screen and (max-width: 768px){
    .flex_row_project {
        flex-direction: column;
    }
    .flex_row_project .form_group_project {
        margin-right: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Projects/AddProject.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;EAClB;AACF;IACI,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,QAAQ;IACR,kBAAkB;;AAEtB;AACA;IACI,WAAW;IACX,uCAAuC;AAC3C;AACA;IACI,mBAAmB;AACvB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,eAAe;IACf,WAAW;AACf;AACA;IACI,SAAO;IACP,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI,sBAAsB;IAC1B;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".loader_container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-height: 100%;\n  }\n.Project_Container {\n    width: 75vw;\n    margin: auto;\n    /* margin-top: 1rem; */\n    /* padding: 20px; */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    gap:2rem;\n    border-radius: 8px;\n    \n}\n.form_project {\n    width: 100%;\n    border:1px solid rgba(224, 224, 224, 1);\n}\n.form_group_project {\n    margin-bottom: 20px;\n}\n.form_group_project label {\n    margin-bottom: 10px;\n}\n.flex_row_project {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 1.5rem;\n}\n.flex_row_project .form_group_project {\n    flex: 1;\n    margin-right: 10px;\n}\n.groups-dropdown {\n    height: 50px;\n    overflow-y: scroll;\n}\n@media screen and (max-width: 1025px){\n    .Project_Container{\n        margin-top: 3rem;\n    }\n}\n\n@media screen and (max-width: 768px){\n    .flex_row_project {\n        flex-direction: column;\n    }\n    .flex_row_project .form_group_project {\n        margin-right: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
