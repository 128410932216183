import React from 'react';
// import './Loader.css';

function Spinner() {


  return (
    <div className="loader_container">
    <span className="loader"></span>
  </div>
  );
}

export default Spinner;
