import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom';
import { VscEye } from 'react-icons/vsc'
import { VscEyeClosed } from "react-icons/vsc"
import { toast } from "react-toastify"
import { SignIn_User, reset } from "../../Features/Auth/AuthSlice"
import CheckRole from '../../Utils/CheckRole/CheckRole';


const SignIn = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [iconShow, setIconShow] = useState({ passShow: false, CpassShow: false})
    const [data, setData] = useState({ email:'', password:'' });
    const { email, password } = data

    const { isSuccess } = useSelector((store) => store.auth)

    const onChange = (e) => {
        setData((prevState)=>({ ...prevState, [e.target.name]:e.target.value}))
    }    

    const onSubmit = (e) => {
        e.preventDefault()
        if( email === '' || password === '' ){
            toast.info('Please fill all fields')
        }else if ( email !== '' && password !== '' ){
            const userData = { email: email.toLowerCase(), password: password }
            dispatch(SignIn_User(userData))
        }
    }

    useEffect(() => {
    if(isSuccess){
        if ('owner' === CheckRole() || 'manager' === CheckRole() || 'operator' === CheckRole()) {
            navigate('/')
        }
    }
    dispatch(reset())
    }, [isSuccess, dispatch, navigate])


return (
<section className='flex_row_center_center Main_SignUp_Container'>
    <form onSubmit={onSubmit} className='flex_column form'>
        <div className="flex_column form_group">
            <label>Email</label>
            <input className='input-field' type="email" id='email' name='email' value={email} placeholder='Enter Your Email' onChange={onChange} />
        </div>
        <div className="flex_column form_group password_div">
            <label>Password</label>
            <div className='flex_row_center_center'>
            <input className='input-field' type={iconShow.passShow === true ? "text" : "password"} id='password' name='password' value={password} placeholder='Enter Your password' onChange={onChange} />
            {iconShow.passShow === true ? <VscEye className='site_icon'  onClick={() => setIconShow({...iconShow,"passShow":!iconShow.passShow})} /> : <VscEyeClosed className='site_icon'  onClick={() => setIconShow({...iconShow,"passShow":!iconShow.passShow})}/>}
            </div>
        </div>
        <div className="flex_column form_group">
            <button type='submit' className='btn'>Sign In</button>
        </div>
        <p>Don’t have an account? <Link className='special_a_tag' to='/signup'>Sign Up</Link> here</p>
    </form>
    <div className='border'></div>
    <div className='flex_column_center_center form_right'>
        <h4>Sign In to Fori Mobility</h4>
    </div>
</section>
);
};

export default SignIn;