import React from "react";
import "./ImpactCard.css"
import getSuffixedNumber from '../../../Utils/SuffixNumber/SuffixNumber'

const ImpactCard = (props) => {
  const { heading, data, color, unit,good ,statusText} = props;
  const {total}  = data;
  const SuffixedNumber = getSuffixedNumber(total) 


  return (
    <div className="ImpactCardP">
        <h6>{heading}</h6>
    <div className="CircularPathContainer">
      <svg className="CircularPath"viewBox="0 0 100 100" >
        <circle style={{fill:good===true?"#BCFA90":"#007bff", stroke:color.primary || '#0e0e0e'}} className="OuterCircle" cx="50%" cy="50%" r="36%"></circle>
        <circle className="InnerCircle" style={{stroke:color.secondary || '#f6f6f6'}} cx="50%" cy="50%" r="30%"></circle>
      </svg>
      <div className="HeadingandFigImpact">
        <h5>
          {SuffixedNumber}<span style={{fontSize:".8rem"}}> {unit}</span>
        </h5>
        <p className={good===true?"HeadingandFigImpactTick":"HeadingandFigImpactFalse"}>{statusText ? statusText:(good===true?"Saved":heading==="Net Fuel status"?"consumed":"released")}</p>
      </div>
    </div>
  </div>
  );
};

export default ImpactCard;
