import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams,Link, useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';
import CheckRole from '../../../Utils/CheckRole/CheckRole';
import NotAuthorized from '../../NotAuthorized/NotAuthorized';
import { addCar, addCarToProject } from '../../../Features/ProjectDetail/CarSlice';
import { getVendorThroughProject } from '../../../Features/ProjectDetail/VendorSlice'
import Loader from '../../../Components/LoaderForInputs/LoaderForInputs'

const AddCar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);


    const { projectId } = useParams();
    const [carData, setCarData] = useState([]);
    const [carSize, setCarSize] = useState(''); // New state for selected car size
    const [selectedCarObject,setSelectedCarObject] = useState({})

    const [data, setData] = useState({ selectedCarLicense: '', selectedCarMake: {}, selectedCarModel: {}, selectedCarVin: '', class:''});
    const { selectedCarLicense, selectedCarMake, selectedCarModel, selectedCarVin } = data;

    const { vendorThroughProject } = useSelector((store) => store.vendor)

    const onSizeChange = (e) => {
      const { value } = e.target;
      setData((prevState) => ({ ...prevState, class: value }));
    };
    

    /////car make////
    const onMakeChange = (e) => {
        const carObject = carData.find((make) => make.carMake.name === e.target.value)
        setSelectedCarObject(carObject)

        setData((prevState) => ({
          ...prevState,
          selectedCarMake: carObject.carMake || {}
        }));
    };

    /////car model////
    const onModelChange = (e) => {
      const selectedCarModelObject = selectedCarObject.models.find((make) => make.carModel.label === e.target.value);

      setData((prevState) => ({
          ...prevState,
          selectedCarModel: selectedCarModelObject.carModel || {},
      }));
  };



    ////other inputs////
    const onChange = (e) => {
        const { name, value } = e.target;
        setData((prevState) => ({ ...prevState, [name]: value }));
    };
    



    const onSubmit = (e) => {
      e.preventDefault();
      if (!selectedCarMake || !data.class) {
          toast.info('Please fill all fields');
          return;
      }
  const allData = {
    selectedCarLicense, selectedCarMake, selectedCarModel, selectedCarVin, class:data.class, projectId
  }
      dispatch(addCar(allData))
          .then((response) => {
            if (response.payload.success === true) {
              navigate(`/project-details/${projectId}/view-cars`);
            } else {
              console.error('Error adding car to project. Response:', response);
            }
          })
          .catch((error) => {
              console.error('Error adding car to project:', error);
          });
  };
  

useEffect(() => {
    setIsLoading(true)
    dispatch(getVendorThroughProject(projectId))
      .then(()=>{
        setIsLoading(false);
      })
      .catch((error)=>{
        setIsLoading(false);
        console.error('Error fetching car data:', error);
      })
    // Extract car data and set it in the state
    if (vendorThroughProject?.success && vendorThroughProject?.result?.makes?.length > 0) {
        const tempCarArray = vendorThroughProject.result.makes.map((make) => ({
        carMake: {
            id: make.uuid,
            manafactureId: make.uuid,
            name: make.car_manufacturer,
            value: make.uuid,
        },
        models: make.models.map((model) => ({
            carModel: {
                id: model.uuid,
                label: model.car_model,
                name: model.car_model,
                value: model.uuid,
            },
        })),
      }));
      setCarData(tempCarArray);
    } else {
      console.error('Failed to fetch car data:', vendorThroughProject?.status);
    }
  }, [dispatch, vendorThroughProject.success]);

  return (
    (('owner' === CheckRole() || 'manager' === CheckRole()) ? (
    <div className='flex_row_center_center Project_Container'>
            <h4>Add Car</h4>
      <form onSubmit={onSubmit} className='flex_column form form_project'>
        <div style={{textAlign:'left', width:'100%'}}>
        </div>
        <div className='flex_row_project'>
          <div className='flex_column form_group form_group_project'>
            <label>Select Car Make</label>
            {isLoading ? <div className='loaderForInput'> <Loader/> </div> : <>
            <select
              name='selectedCarMake'
              value={selectedCarMake?.name}
              onChange={onMakeChange}
            >
              <option>Select Car Make</option>
              {carData.map((make, index) => (
                <option key={index} value={make.carMake.name}>
                  {make.carMake.name}
                </option>
              ))}
            </select>
            </>
            }
          </div>
          <div className='flex_column form_group form_group_project'>
            <label>Select Car Model</label>
            <select
              name='selectedCarModel'
              value={selectedCarModel?.name}
              onChange={onModelChange}
            >
              <option>Select Car Model</option>
              {carData.find((make) => make.carMake.name === selectedCarObject?.carMake?.name)?.models.map((model, index) => (
                <option key={index} value={model.carModel.name}>
                    {model.carModel.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='flex_column form_group form_group_project'>
            <label>Select Car Size</label>
            <select
              name='class'
              value={data.class}
              onChange={onSizeChange}
            >
              <option value=''>Select Car Size</option>
              <option value='small'>Small</option>
              <option value='medium'>Medium</option>
              <option value='large'>Large</option>
            </select>
        </div>
        <div className='flex_row_project'>
          <div className='flex_column form_group form_group_project'>
            <label>Select License</label>
            <input
              type='text'
              name='selectedCarLicense'
              value={selectedCarLicense}
              placeholder='Enter license plate number'
              onChange={onChange}
            />
          </div>
          <div className='flex_column form_group form_group_project'>
            <label>Select Vin</label>
            <input
              type='text'
              name='selectedCarVin'
              value={selectedCarVin}
              placeholder='Enter VIN'
              onChange={onChange}
            />
          </div>
        </div>
        <div>
          <button className='btn' type='submit'>
            Add Car
          </button>
        </div>
      </form>
      <div className="nav_btn_group">
        <Link className="secondary_btn" to={`/project-details/${projectId}/cars/view-cars`}> View Cars</Link>
        <Link className="secondary_btn" to={`/project-details/${projectId}/cars/add-multiple-cars`}> Add Multiple Cars +</Link>
      </div>
    </div>
    
    ) : <NotAuthorized />)
  );
};

export default AddCar;
