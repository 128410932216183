import React from 'react';
import './NotFoundPage.css'; // You can create a CSS file for styling
import {BiError} from 'react-icons/bi'
import {Link} from 'react-router-dom'
const NotFoundPage = () => {
  return (
    <div className="under-construction-container site_padding">
      <div className="construction-icon">
        <BiError/>
      </div>
      <h1 className="construction-heading">404 - Page Not Found</h1>
      <p className="construction-text">
        Requested Page is not found on the server. Click <Link style={{borderBottom:"2px solid var(--primary_color)"}} to='/'>here</Link> to go to Home Page.
      </p>
    </div>
  );
};

export default NotFoundPage;
