import React, {useEffect} from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom'
import CheckRole from '../../../Utils/CheckRole/CheckRole'
import NotAuthorized from '../../NotAuthorized/NotAuthorized'
import { useDispatch, useSelector } from 'react-redux'
import {findGroup, resetRequiredGroup} from '../../../Features/Group/GroupSlice'
import Loader from '../../../Components/Loader/Loader'
import { getRequiredCar } from '../../../Features/ProjectDetail/CarSlice'
import DataTable from '../../../Components/DataTable/DataTable'

const CarDetails = () => {
    const { carId } = useParams();
    const {requiredCar}=useSelector((store)=>store.car)
    const dispatch = useDispatch()
    const navigate = useNavigate()


    const columns = [
      { field: 'serialNumber', headerName: 'Sr.No', width: 100 },
      { field: 'day', headerName: 'Day', width: 100 },
      { field: 'startTime', headerName: 'Start Time', width: 120 },
      { field: 'endTime', headerName: 'End Time', width: 150 },
    ]


    useEffect(()=>{
      dispatch(getRequiredCar(carId))
    },[])


    const addSerialNumberToData = (data) => {
      return data.map((item, index) => ({
          ...item,
          serialNumber: index + 1
      }))
  }

  

  return (
    ( ('owner' === CheckRole() || 'manager' ===  CheckRole()) ? 
(<section>
    <div className='Group_CardContainer'>
      {requiredCar!==null ? 
        <div className='flex_column' style={{alignItems:'flex-start', textAlign:'left'}}>
          <small><b>Car Id: </b>{requiredCar.projectCarModels[0].carId}</small>
          <small><b>Car Make: </b>{requiredCar.make}</small>
          <small><b>Car Model: </b>{requiredCar.projectCarModels[0].name}</small>
          <small><b>Car Vin: </b>{requiredCar.vin}</small>
          <small><b>Car License: </b>{requiredCar.license}</small>
          <small>
            <b>Car Preferences: </b>
            {
            requiredCar.carPreferences.length > 0 ?
            <DataTable columns={columns} data={addSerialNumberToData(requiredCar.carPreferences)} pageSize={4} /> 
            : 
            <small>No preferences have been provided for this vehicle.</small>
            }
          </small>
        </div>
        :
        <>
          <Loader/>
        </>
      }
    </div>
</section>) : <NotAuthorized/>
)

  )
}

export default CarDetails
