import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams,Link,useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CheckRole from '../../../Utils/CheckRole/CheckRole';
import { MdInfoOutline } from 'react-icons/md';
import NotAuthorized from '../../NotAuthorized/NotAuthorized';
import { addCar, addMultipleCars, addCarToProject } from '../../../Features/ProjectDetail/CarSlice';
import * as XLSX from 'xlsx';

const AddMultipleCars = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const[addedCars, setAddedCars] = useState([])

    const { projectId } = useParams();

    const [showDescription, setShowDescription] = useState(false);

    const onFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet);
      
            const newCars = jsonData.map((car) => {
              return {
                selectedCarMake: { id: car['Make uuid'], manafactureId: car['Make uuid'], name: car['Car Manufacturer'], value: car['Make uuid'] },
                selectedCarModel: { id: car['Model uuid'], label: car['Car Model'], name: car['Car Model'], value: car['Model uuid'] },
                selectedCarLicense: car['License Number'],
                selectedCarVin: car['Vin'],
                class: car['Class']
              };
            });
      
            // Append the new cars to the existing array
            setAddedCars((prevAddedCars) => [...prevAddedCars, ...newCars]);
          };
          reader.readAsArrayBuffer(selectedFile);
        }
      };
      

    const onSubmit = (e) => {
        e.preventDefault()
        if(addedCars.length === 0){
            toast.info('Please provide file')
            return
        }
        const allData = {
            cars: addedCars,
            projectId
        }
            dispatch(addMultipleCars(allData))
                .then(() => {
                    navigate(`/project-details/${projectId}/view-cars`);
                })
                .catch((error) => {
                    console.error('Error adding car to project:', error);
                });
    }

            const toggleDescription = () => {
                setShowDescription(!showDescription);
            };
            

    return (
        (('owner' === CheckRole() || 'manager' === CheckRole()) ? (
            <div className='flex_row_center_center Project_Container'>
            <h4>Add Multiple Cars At Once</h4>
                <form onSubmit={onSubmit} className='flex_column form form_project'>
                    <div className='flex_column form_group form_group_project'>
                        <label>
                            Add cars in bulk
                            <button className='iBtn' type='button' onClick={toggleDescription}>
                                <MdInfoOutline />
                            </button>
                        </label>
                        {showDescription && (
                        <div>
                            <small>
                                This can only accept excel sheets in the format like <a className='link_btn' href='https://docs.google.com/spreadsheets/d/1uphs9FxZndbAMKHXwi9HEACIU0axnrj_raTmvioEsdc/edit?usp=sharing'>Check format</a>
                            </small>
                        </div>
                        )}
                        <input
                            type='file'
                            accept='.xlsx, .xls'
                            onChange={onFileChange}
                        />
                        {addedCars.length > 0 && (
                        <table className=' form form_project'>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>License</th>
                                <th>Vin</th>
                                <th>Class</th>
                            </tr>
                        </thead>
                        <tbody>
                        {addedCars.map((cars, index) => (
                            <tr key={index}>
                                <td>{cars.selectedCarMake.name}</td>
                                <td>{cars.selectedCarLicense}</td>
                                <td>{cars.selectedCarVin}</td>
                                <td>{cars.class}</td>
                            </tr>
                        ))}
                        </tbody>
                        </table>
                        )}
                    </div>
                    <div>
                        <button className='btn' type='submit'>
                            Add Multiple Cars
                        </button>
                    </div>
                </form>
        <div className="nav_btn_group">
            <Link className="secondary_btn" to={`/project-details/${projectId}/cars/view-cars`}> View Cars</Link>
            <Link className="secondary_btn" to={`/project-details/${projectId}/cars/add-car`}> Add single car +</Link>
       </div>
            </div>
        ) : <NotAuthorized />)
    );
};

export default AddMultipleCars;
