import React, { useEffect, useState } from 'react';
import NotAuthorized from '../../NotAuthorized/NotAuthorized';
import CheckRole from '../../../Utils/CheckRole/CheckRole';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaTrash } from 'react-icons/fa';
import { getOperators, deleteOperator, inviteOperator } from '../../../Features/ProjectDetail/OperatorSlice';
import Loader from '../../../Components/Loader/Loader';
import DataTable from '../../../Components/DataTable/DataTable';
import { TbForbid2 } from "react-icons/tb";
import { TiTick } from "react-icons/ti";
import { MdOutlinePending } from "react-icons/md";

const ViewOperator = () => {
  const { operators } = useSelector((store) => store.operator);
  const [loading, setLoading] = useState(true);

  const { projectId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData =  () => {
       dispatch(getOperators(projectId));
      setLoading(false);
    };
    fetchData();
  }, [dispatch, projectId]);


  const columns = [
    { field:'SrNo', headerName: '#',minWidth: 150, flex: 1  },
    { field: 'operatorEmail', headerName: 'Operator Email', minWidth: 150, flex: 1 },
    {field:"invited",headerName:"Invited",minWidth: 150, flex: 1 ,
    renderCell: (params) => (<>{params.row.invited==1? <TiTick className='tickIcon'/>:<MdOutlinePending style={{fontSize:"1.3rem",color:"lightgrey"}}/>}</>)},
    {field:"joined",headerName:"Joined",minWidth: 150, flex: 1 ,
    renderCell: (params) => ( <>{params.row.joined==1? <TiTick className='tickIcon'/>:<MdOutlinePending style={{fontSize:"1.3rem",color:"lightgrey"}}/>}</>)},
    {
      field: 'delete',
      headerName: 'Delete Operator',
      minWidth: 150, flex: 1 ,
      renderCell: (params) => (
        <button
          className="deleteButton marginRight"
          onClick={() => {
            dispatch(deleteOperator(params.row.operatorId)).then((res) => res.payload.success === true && dispatch(getOperators(projectId)));
          }}
        >
          <FaTrash />
        </button>
      ),
    },
          {
            field: 'addJob',
            headerName: 'Add Job',
            minWidth: 150, flex: 1 ,
            renderCell: (params) => (
              <>
              {params.row.joined==1?<Link  className="btn" to={`/project-details/${projectId}/view-operators/${params.row.operatorId}/add-operator-job`} >  Add Job </Link>:
              <TbForbid2  style={{fontSize:"1.3rem",color:"lightgrey"}}/>}
              </>
            ),
          },
  ];
  


  return 'owner' === CheckRole() || 'manager' === CheckRole() ? (
    <div className="Project_Container">
      {loading ? (
        <h5>
          <Loader />
        </h5>
      ) : (
        <>
          {operators.length === 0 ? 
            <>
              <h4 className='Not_Data_heading'>No operators added yet</h4>
            </>:
            <>
            <h4>All Operators</h4>
          <DataTable columns={columns} data={operators.map((operator, index) => ({ ...operator, SrNo: index + 1 }))} pageSize={3} hideHeader />
          </>
          }
      <div className="nav_btn_group">
            <Link className="secondary_btn"to={`/project-details/${projectId}/employees/add-operator`}>Add Operator +</Link>
            <Link className="secondary_btn"to={`/project-details/${projectId}/jobs/view-operators-jobs`}>View Operators jobs</Link>
            <Link className='secondary_btn'to={`/project-details/${projectId}/employees/view-managers`}>View Managers</Link>
            <Link className='secondary_btn'to={`/project-details/${projectId}/employees/add-manager`}>Add Manager +</Link>

          </div>
        </>
      )}
    </div>
  ) : (
    <NotAuthorized />
  );
};

export default ViewOperator;