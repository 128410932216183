import html2canvas from 'html2canvas';
  
 export const HtmlToImage = (CurrentComponentRef, formate) => {
    if (CurrentComponentRef) {
      html2canvas(CurrentComponentRef).then((canvas) => {
        const imgData = canvas.toDataURL(`image/${formate}`);
        
        // Create a temporary anchor element to trigger the download
        const downloadLink = document.createElement('a');
        downloadLink.href = imgData;
        downloadLink.download = `captured-image.${formate}`;
        
        // Trigger a click event on the anchor element
        downloadLink.click();
      
      });
    }
  };


  // export const shareOnFacebook = (CurrentComponentRef,formate) => {
  //   // Create a Facebook sharing URL
   

  //   if (CurrentComponentRef) {
  //     html2canvas(CurrentComponentRef).then((canvas) => {
  //       var imgData = canvas.toDataURL(`image/${formate}`);
        
  //     });
  //   }else{
  //     return
  //   }
  //   const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
  //     window.location.href
  //   )}&quote=Check out this captured image!`;

  //   // Open a new window with the Facebook sharing URL
  //   window.open(facebookShareUrl, '_blank', 'width=600,height=400');
  // };

  // const shareOnTwitter = () => {
  //   // Create a Twitter sharing URL
  //   const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
  //     window.location.href
  //   )}&text=Check out this captured image!`;

  //   // Open a new window with the Twitter sharing URL
  //   window.open(twitterShareUrl, '_blank', 'width=600,height=400');
  // };

//   shareOnFacebook(imgData);
//         shareOnTwitter(imgData);