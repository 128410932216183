import React, { useState,useEffect } from 'react';
import './AddProject.css'
import { useDispatch,useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CheckRole from '../../Utils/CheckRole/CheckRole'
import NotAuthorized from '../NotAuthorized/NotAuthorized'
import { createProject } from "../../Features/Project/ProjectSlice"
import { getGroup } from "../../Features/Group/GroupSlice"
import { getVendor } from "../../Features/ProjectDetail/VendorSlice"
import { useNavigate } from 'react-router-dom'



const AddProject = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const {user}=useSelector((store)=>store.auth)
  const {groups}=useSelector((store)=>store.group)
  const {vendors}=useSelector((store)=>store.vendor)

  const [data, setData] = useState({
    projectName: '',
    numberOfVehicle: 0,
    numberOfParkings: 0,
    numberOfManagers: 0,
    selectedVendor:'',
    vendorKey:'',
    selectedGroups: [],
    city:'',
    vehicleType:'',
    selectedProjectValue: 'Public',
    projectPrimaryColor:'#0074AF',
    projectSecondaryColor:'#FFFFF'
  });


  const { projectName, numberOfVehicle, numberOfParkings, numberOfManagers, selectedVendor, vendorKey,selectedGroups, city, vehicleType, selectedProjectValue, projectPrimaryColor, projectSecondaryColor } = data;

  const onChange = (e) => {
    const { name, value } = e.target;
  
    // Check if the input is a color input
    if (e.target.type === 'color') {
      // If it is a color input, update the state with the hex format
      setData((prevState) => ({ ...prevState, [name]: value }));
    }else if (name === 'projectName' && /test/i.test(value)) {
      // Display an error or prevent further action when "Test" is found
      toast.error('Project name cannot include the word "Test"');
    } else if (name === 'selectedGroups') {
      // Handle multi-select for groups
      const selectedOptions = Array.from(e.target.selectedOptions, (option) => Number(option.value));
      setData((prevState) => ({ ...prevState, [name]: selectedOptions }));
    } else {
      // Otherwise, update the state normally
      setData((prevState) => ({ ...prevState, [name]: value }));
    }
  };
  

  const onSubmit = (e) => {
    e.preventDefault();
    if (projectName === '' || vendorKey==="") {
      toast.info('Please fill all fields');
    } else {
      dispatch(createProject(data)).then((res)=>res.payload.success ===true&& navigate(-1))
    }
  };


  useEffect(()=>{
    if(!user){
      navigate('/signin');
    }else{
      dispatch(getVendor())
      dispatch(getGroup())
    }
  },[dispatch])


  return (
    ( ('owner' === CheckRole()) ? 
    (<div className='Project_Container'style={{padding:"2rem 0rem"}}>
        <div style={{textAlign:'center', width:'100%'}}>
          <h4>Add Project</h4>
        </div>
      <form onSubmit={onSubmit} className='flex_column form form_project' style={{width:'100%'}}>
        <div className='flex_row_project'>
        <div className='flex_column form_group form_group_project'>
          <label>Project Name</label>
          <input type='text' id='projectName' name='projectName' value={projectName} placeholder='Enter your project name' onChange={onChange} />
        </div>
        <div className='flex_column form_group form_group_project'>
          <label>City</label>
          <input type='text' id='city' name='city' value={city} placeholder='Enter your city name' onChange={onChange} />
        </div>
        <div className='flex_column form_group form_group_project'>
          <label>Vehicle Type</label>
          <select type='text' id='vehicleType' name='vehicleType' value={vehicleType} onChange={onChange} >
            <option>Select type</option>
            <option value='cars'>Cars</option>
            <option value='escooters'>E-scooters</option>
            <option value='bicycles'>Bicycles</option>
            <option value='mixed'>Mixed</option>
          </select>
        </div>
        </div>
        <div className='flex_row_project'>
        <div className='flex_column form_group form_group_project'>
          <label>No of expected Vehicles</label>
          <input type='number' min='0' id='numberOfVehicle' name='numberOfVehicle' value={numberOfVehicle} onChange={onChange} onKeyDown={(e) => e.key === '-' && e.preventDefault()}/>
        </div>
        <div className='flex_column form_group form_group_project'>
          <label>No of expected Parkings</label>
          <input type='number' min='0' id='numberOfParkings' name='numberOfParkings' value={numberOfParkings} onChange={onChange} onKeyDown={(e) => e.key === '-' && e.preventDefault()}/>
        </div>
        <div className='flex_column form_group form_group_project'>
          <label>No of expected Managers</label>
          <input type='number' min='0' id='numberOfManagers' name='numberOfManagers' value={numberOfManagers} onChange={onChange} onKeyDown={(e) => e.key === '-' && e.preventDefault()}/>
        </div>

        </div>
        <div className='flex_row_project'>
        <div className='flex_column form_group form_group_project'>
          <label>Select Vendor</label>
          <select id="selectedVendor" name="selectedVendor" value={selectedVendor} onChange={onChange} placeholder="Choose Vendor">
          <option>Select Vendor</option>
          {vendors.map((vendor, index) => (
            <option key={index} value={vendor.name}>{vendor.name}</option>
          ))}
          </select>
        </div>
        <div className='flex_column form_group form_group_project'>
          <label>Add Vendor Api Key</label>
          <input type='text' id='vendorKey' name='vendorKey' value={vendorKey} placeholder='Add vendor api key' onChange={onChange} />
        </div>
        </div>
        <div className='flex_row_project'>
        <div className='flex_column form_group form_group_project'>
          <label>Project Type</label>
          <select type='text' id='selectedProjectValue' name='selectedProjectValue' value={selectedProjectValue} onChange={onChange} >
            <option value='Public'>Public</option>
            <option value='Private'>Private</option>
          </select>
        </div>
        <div className='flex_column form_group form_group_project'>
          <label>Select Group</label>
          <select style={{overflowY:'scroll'}} className="groups-dropdown" id="selectedGroups" name="selectedGroups" value={selectedGroups} onChange={onChange} multiple placeholder="Choose Group">
          {groups.map((group, index) => (
            <option key={index} value={group.id}>{group.name}</option>
          ))}
          </select>
        </div>
        </div>

        <div className='flex_row_project'>
        <div className='flex_column form_group form_group_project' style={{display:'flex',flexDirection:'column', gap:'1rem'}}>
          <label>Select app primary color</label>
          <input style={{height:'3rem', width:'25%'}} type='color' id='projectPrimaryColor' name='projectPrimaryColor' value={projectPrimaryColor} onChange={onChange} />
        </div>
        <div className='flex_column form_group form_group_project' style={{display:'flex',flexDirection:'column', gap:'1rem'}}>
          <label>Select app secondary color</label>
          <input style={{height:'3rem', width:'25%'}} type='color' id='projectSecondaryColor' name='projectSecondaryColor' value={projectSecondaryColor} onChange={onChange} />
        </div>
        </div>
        <div>
          <button className='btn' type='submit'>
            Add Project
          </button>
        </div>
      </form>
    </div>) : <NotAuthorized/>
    )
  );
};

export default AddProject;
