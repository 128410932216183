// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info{
    position: absolute;
    right: 10px;
    top: 10px;
    color: var(--gray);
    z-index: 1;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
}
.info_para{
    background-color: rgba(0,0,0,0.7);
    position: absolute;
    right: 30px;
    top: 10px;
    width: 70%;
    padding: 2rem;
    border-radius: 1rem;
    color:white;
    opacity: 1;
    -webkit-user-select: none;
            user-select: none;
    z-index: 1;

}

@media screen and (max-width: 650px){
    .info_para{
     font-size: 0.8rem;
    }
}

@media screen and (max-width: 300px){
    .info_para{
    padding: 1rem;

    }
}`, "",{"version":3,"sources":["webpack://./src/Components/ImpactPageComponents/Infobox/InfoBox.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,kBAAkB;IAClB,UAAU;IACV,eAAe;IACf,yBAAiB;YAAjB,iBAAiB;AACrB;AACA;IACI,iCAAiC;IACjC,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,UAAU;IACV,yBAAiB;YAAjB,iBAAiB;IACjB,UAAU;;AAEd;;AAEA;IACI;KACC,iBAAiB;IAClB;AACJ;;AAEA;IACI;IACA,aAAa;;IAEb;AACJ","sourcesContent":[".info{\n    position: absolute;\n    right: 10px;\n    top: 10px;\n    color: var(--gray);\n    z-index: 1;\n    cursor: pointer;\n    user-select: none;\n}\n.info_para{\n    background-color: rgba(0,0,0,0.7);\n    position: absolute;\n    right: 30px;\n    top: 10px;\n    width: 70%;\n    padding: 2rem;\n    border-radius: 1rem;\n    color:white;\n    opacity: 1;\n    user-select: none;\n    z-index: 1;\n\n}\n\n@media screen and (max-width: 650px){\n    .info_para{\n     font-size: 0.8rem;\n    }\n}\n\n@media screen and (max-width: 300px){\n    .info_para{\n    padding: 1rem;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
