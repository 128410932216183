import React, { useEffect, useState } from "react";
import NotAuthorized from "../../NotAuthorized/NotAuthorized";
import CheckRole from "../../../Utils/CheckRole/CheckRole";
import { useSelector, useDispatch } from "react-redux";
import { FaTrash } from 'react-icons/fa';
import { getManagers, deleteManager, inviteManager } from "../../../Features/ProjectDetail/ManagerSlice";
import { useParams, Link } from "react-router-dom";
import Loader from '../../../Components/Loader/Loader';
import DataTable from '../../../Components/DataTable/DataTable';
import { TiTick } from "react-icons/ti";
import { MdOutlinePending } from "react-icons/md";
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';




const ViewManager = () => {
  const { managers } = useSelector((store) => store.manager);
  const [loading, setLoading] = useState(true);
  const { projectId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = () => {
      dispatch(getManagers(projectId));
      setLoading(false);
    };
    fetchData();
  }, [dispatch, projectId]);


  const columns = [
    { field: 'SrNo', headerName: '#', minWidth: 150, flex: 1 , },
    { field: 'managerEmail', headerName: 'Manager Email', minWidth: 150, flex: 1 , },

    {field:"invited",headerName:"Invited",minWidth: 150, flex: 1 ,
    renderCell: (params) => (<>{params.row.invited==1?<Tooltip TransitionComponent={Zoom} title="invitation to join this project was sent"> <TiTick className="tickIcon"/></Tooltip>:<Tooltip TransitionComponent={Zoom} title="could not send invitation"><MdOutlinePending style={{fontSize:"1.3rem",color:"lightgrey"}}/></Tooltip>}</>)},
    {field:"joined",headerName:"Joined",minWidth: 150, flex: 1 ,
    renderCell: (params) => (<>{params.row.joined==1?<Tooltip TransitionComponent={Zoom} title="Have joined this project"><TiTick className="tickIcon"/></Tooltip>:<Tooltip TransitionComponent={Zoom} title="Manager have not accepted the invitation yet"><MdOutlinePending style={{fontSize:"1.3rem",color:"lightgrey"}}/></Tooltip>}</>)},
    {
      field: 'delete',
      headerName: 'Delete Manager',
      minWidth: 150, flex: 1 ,
      renderCell: (params) => (
        <Tooltip TransitionComponent={Zoom} title="Remove manager from this projectr">
        <button
          className="deleteButton marginRight"
          onClick={() => {
            dispatch(deleteManager(params.row.id)).then((res) => res.payload.success === true && dispatch(getManagers(projectId)));
          }}
        >
          <FaTrash />
        </button>
        </Tooltip>
      ),
    },
  ];


  return 'owner' === CheckRole() || 'manager' === CheckRole() ? (
    <div className="Project_Container">
      {loading ? (
          <Loader />
      ) : (
        <>
          {managers.length === 0 ? <> <h4 className="Not_Data_heading">No managers added yet</h4></>:
          <>
          <h4>All Managers</h4>
          <DataTable columns={columns} data={managers.map((manager, index) => {return{ ...manager, SrNo: index + 1 }})} pageSize={3} />
          </>
          }
          <div className="nav_btn_group">
            <Link className='secondary_btn'to={`/project-details/${projectId}/employees/add-manager`}>Add Manager +</Link>
            <Link className="secondary_btn"to={`/project-details/${projectId}/employees/view-operators`}>View Operators</Link>
            <Link className="secondary_btn"to={`/project-details/${projectId}/employees/add-operator`}>Add Operator +</Link>
          </div>
        </>
      )}
    </div>
  ) : (
    <NotAuthorized />
  );
};

export default ViewManager;
