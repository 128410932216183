import React from "react";
import "./FigureCard.css";
import DoughnutChart from "../Charts/Doughnut";
import getSuffixedNumber from '../../../Utils/SuffixNumber/SuffixNumber'
const FigureCard = (props) => {
 
  const { heading, cardData, color, unit } = props;
  const { total, electric, combustible, e_scooter } = cardData;

  const Sdata = {
    labels: ["Electric", "Combustible","e-scooter"],
    datasets: [
      {
        label: `${heading}`,
        data: [electric, combustible,e_scooter],
      },
    ],
  };


  const SuffixedNumber = getSuffixedNumber(total) 
  return (
    <div className="ImpactCard ">

      <div className="HeadingandFig">
        <h6>{heading}</h6>
        <h5>
          {SuffixedNumber} {unit}
        </h5>
      </div>
      <div className="chartDoughnut">
        <DoughnutChart data={Sdata} color={color} />
      </div>
    </div>
  );
};

export default FigureCard;
