import React, { useState, useRef, useEffect } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams ,Link} from "react-router-dom";
import { registerParking } from "../../../Features/ProjectDetail/ParkingSlice";
import NotAuthorized from "../../NotAuthorized/NotAuthorized";
import CheckRole from "../../../Utils/CheckRole/CheckRole";
import { MdInfoOutline } from "react-icons/md";
import Loader from "../../../Components/Loader/Loader";
import Tooltip from "../../../Components/ToolTip/Tooltip"
import "./Parking.css";

const ParkingComponent = ({ projectId }) => {
  const [parkingInfo,setParkingInfo]=useState({parkingName:"",parkingPriceDay:"",parkingPriceNight:"",parkingPriceFullDay:"",category:"outdoor",floor:0,vehicleType:"All",type:"float"})
  const [placeName, setPlaceName] = useState("");
  const [shapes, setShapes] = useState([]);
  const mapRef = useRef();
  const inputRef = useRef();
  const savebtnRef=useRef()
  const drawingManagerRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const map = new window.google.maps.Map(mapRef.current, {
      center: { lat: 44.9, lng: 0.0 },
      zoom: 3,
    });
    const autocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current
    );
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();

      if (!place.geometry) {
        toast.info("No details available for input: '" + place.name + "'");
      } else {
        mapRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
        map.setCenter(place.geometry.location);
        map.setZoom(14);

      }
    });
    const drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
      },
      polygonOptions: {
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        draggable: false,
        editable: false, // Set the polygon to be editable
        zIndex: 1,
      },
    });
    window.google.maps.event.addListener(
      drawingManager,
      "overlaycomplete",
      (event) => {
        setShapes(event.overlay);
        // // Extract and log coordinates for each shape
        // if (event.type === window.google.maps.drawing.OverlayType.POLYGON) {
        //   const coordinates = event.overlay.getPath().getArray().map((latLng) => ({lat: latLng.lat(), lng: latLng.lng()}));

        //   // console.log("Polygon Coordinates:", coordinates);
        // }
        // restrict user from further drawing
        drawingManager.setOptions({ drawingControl: false, drawingMode: null });
        savebtnRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }
    );

    drawingManager.setMap(map);
    drawingManagerRef.current = drawingManager;
  }, []);

  const resetShapes = () => {
    Object.keys(shapes).length !== 0 && shapes.setMap(null);
    setShapes([]);

    if (drawingManagerRef.current) {
      drawingManagerRef.current.setOptions({
        drawingControl: true,
        drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
      });
    }
  };

  

  const saveZone = () => {
    if (Object.keys(shapes).length !== 0 &&  Object.values(parkingInfo).every(value => value !== "")){
      const parkingData = {
        name:parkingInfo.parkingName,
        projectId: projectId,
        type: parkingInfo.type,
        vehicleType:parkingInfo.vehicleType,
        day: parkingInfo.parkingPriceDay,
        fullday:parkingInfo.parkingPriceFullDay,
        category:parkingInfo.category,
        floor:parkingInfo.category=="outdoor"?0:parkingInfo.floor,
        night: parkingInfo.parkingPriceNight,
        center: shapes.getPath().getArray().map((latLng) => ({ lat: latLng.lat(), lon: latLng.lng() }))[0],
        region: shapes.getPath().getArray().map((latLng) => ({ lat: latLng.lat(), lon: latLng.lng() })),
      };
      dispatch(registerParking(parkingData)).then((res)=>res.payload.success==true && navigate(`/project-details/${projectId}/view-all-parkings`))
    } else {
      toast.info("please fill all fields and select area of parking");
    }
  };
const handleChange=(e)=>{
  e.preventDefault();
  setParkingInfo({...parkingInfo,[e.target.name]:e.target.value})
}

const floorData=[
  {name:"Ground Floor",value:0},
  {name:"1st Floor",value:1},
  {name:"2nd Floor",value:2},
  {name:"3rd Floor",value:3},
  {name:"4th Floor",value:4},
  {name:"5th Floor",value:5},
  {name:"1st Basement",value:-1},
  {name:"2nd Basement",value:-2},
  {name:"3rd Basement",value:-3},
  {name:"4th Basement",value:-4},
  {name:"5th Basement",value:-5},
]
  return (
    <div className='Project_Container'>
    
      <h4 style={{ textAlign: "center", width: "100%" }}>Add Parking Details</h4>
      <div className="flex_column form form_project">
      <div className="Parking_S1">
          <div className="Parking_S1_B1">
            <div>
                <label htmlFor="parkingName" className="tooltipClass">Name <Tooltip place="left" text={["Add searchable name of parking"]}/></label>
                <input name="parkingName" placeholder="Enter parking name" value={parkingInfo.parkingName} onChange={handleChange}/>
              </div>
              <div>
                <label htmlFor="parkingPriceDay" className="tooltipClass">Day ($/hr) <Tooltip place="left"text={["Add chargeable rent from 7am to 7pm" ]}/></label>
                <input type="number"name="parkingPriceDay"placeholder="Enter parking price"value={parkingInfo.parkingPriceDay}min="0"onKeyDown={(e) => e.key === "-" && e.preventDefault()}onChange={handleChange}/>
              </div>
              <div>
                <label htmlFor="parkingPriceNight"  className="tooltipClass">Night ($/hr) <Tooltip place="right" text={["Add chargeable rent from 7pm to 7am"]}/></label>
                <input type="number" name="parkingPriceNight" placeholder="Enter parking price" value={parkingInfo.parkingPriceNight} min="0" onKeyDown={(e) => e.key === "-" && e.preventDefault()} onChange={handleChange}/>
              </div>
              <div>
                <label htmlFor="parkingPriceFullDay"  className="tooltipClass">Full Day ($/hr) <Tooltip place="right" text={["Add chargeable rent for full day"]}/></label>
                <input type="number" name="parkingPriceFullDay" placeholder="Enter parking price" value={parkingInfo.parkingPriceFullDay} min="0" onKeyDown={(e) => e.key === "-" && e.preventDefault()} onChange={handleChange}/>
              </div>
              <div>
                <label htmlFor="vehicleType" className="tooltipClass">For Vehicle <Tooltip place="right"text={["select for which type  of vehicle this parking is allowed"]}/></label>
                <select id="vehicleType" name="vehicleType" value={parkingInfo.vehicleType} onChange={handleChange}>
                <option value="All">All type of vehicles</option>
                  <option value="Car">Car</option>
                  <option value="E-scooter">scooter</option>
                  <option value="Truck">Truck</option>
                </select>
              </div>
              <div>
                <label htmlFor="type" className="tooltipClass">type <Tooltip place="right" text={["select which type of parking is this","Float type: in which any vehicle can be parked","Stationed type: in which specific vehicles can be parked","Reserved type: which is reserved for specific users"]}/></label>
                <select id="type" name="type" value={parkingInfo.type} onChange={handleChange}>
                  <option value="float">Float </option>
                  <option value="stationed">Stationed </option>
                  <option value="reserved">Reserved</option>
                </select>
              </div>
              <div>
                <label htmlFor="category" className="tooltipClass">category <Tooltip place="right" text={["In terms of your rentable parking, is it indoors or outdoors?"]}/></label>
                <select id="category" name="category" value={parkingInfo.category} onChange={handleChange}>
                  <option value="outdoor">Outdoor </option>
                  <option value="indoor">Indoor </option>
                </select>
              </div>
              {parkingInfo.category=="indoor"?(  
                <div>
                <label htmlFor="floor" className="tooltipClass">Floor <Tooltip place="right" text={["In terms of your indoor parking, select the floor number"]}/></label>
                <select id="floorSelect" name="floor" onChange={handleChange} value={parkingInfo.floor}>
                  {floorData.map((floor)=>{ return <option key={floor.value} value={floor.value}>{floor.name}</option> })}
                </select>
              </div>):null}
          </div>
        <div className="Parking_S1_C2">
        <div>
            <label htmlFor="placeName">Search:</label>
            <input
              name="placeName"
              placeholder="Search place on map"
              value={placeName}
              onChange={(e) => setPlaceName(e.target.value)}
              ref={inputRef}
            />
          </div>
          <div>
            <Link className="secondary_btn" to={`/project-details/${projectId}/parkings/view-all-parkings`}> View all Parking </Link>
            <button className="secondary_btn" onClick={resetShapes}>
              Redraw
            </button>
            <button ref={savebtnRef} className="secondary_btn" onClick={() => saveZone()}>
              Save
            </button>
          </div>
        </div>
      </div>

      <div className="Parking_S2">
       <div
          ref={mapRef}
          id="map"
          style={{ height: "100%", width: "100%" }}
        ></div>
      </div>
    </div>
        </div>

  );
};

const Render = (status, projectId) => {
  console.log(projectId,"projectid in rendre component")
  switch (status) {
    case Status.LOADING:
      return <Loader />;
    case Status.FAILURE:
      return <p>{status} Error...</p>;
    case Status.SUCCESS:
      return <ParkingComponent projectId={projectId} />;
    default:
      return null;
  }
};
const AddParking = () => {
  const { projectId } = useParams();
  const { user } = useSelector((store) => store.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/signin");
    }
  }, []);

  return "owner" === CheckRole() || "manager" === CheckRole() ? (
    <div >
      <Wrapper
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        render={(status) => Render(status, projectId)}
        libraries={["drawing", "places", "geometry"]}
      />
    </div>
  ) : (
    <NotAuthorized />
  );
};

export default AddParking;
