import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {toast} from 'react-toastify'

const initialState = {
    categoryAndTask:[],
    allProjectJobs:[],
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:'',
}


//////////////////////Add Job///////////////////////////
export const addJob = createAsyncThunk('Task/addJob',
async(data, thunkAPI)=>{
    console.log(data);
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/task/create`
        const response = await axios.post(API_URL, data, { withCredentials: true })
        console.log(response.data,'response.data');
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})



//////////////////////add Broadcast Job///////////////////////////
export const addBroadcastJobs = createAsyncThunk('Task/addBroadcastJobs',
async(data, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/task/broadcastTask`
        const response = await axios.post(API_URL, data, { withCredentials: true })
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


//////////////////////Get all Jobs in the project///////////////////////////
export const getAllJobsProject = createAsyncThunk('Task/getAllJobsProject',
async(projectId,thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/task/getProjectTasks/${projectId}`
        const response = await axios.get(API_URL, { withCredentials: true })
        console.log(response.data,'response.data');
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})



//////////////////////Get Category and tasks///////////////////////////
export const getCategoryAndTask = createAsyncThunk('Task/getCategoryAndTask',
async(thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/category/`
        const response = await axios.get(API_URL, { withCredentials: true })
        console.log(response.data,'response.data');
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})




export const AddJobSlice = createSlice({
    name:'AddJob',
    initialState,
    reducers:{
        reset:(state)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        },
    },
    extraReducers:(builder) => {
        builder
        /////////////Add Job//////////////
        .addCase(addJob.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(addJob.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.status
            toast.info(action.payload.status)
        })
        .addCase(addJob.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload.status
            toast.info(action.payload.status)
        })
        /////////////add Broadcast Job//////////////
        .addCase(addBroadcastJobs.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(addBroadcastJobs.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.status
            toast.info(action.payload.status)
        })
        .addCase(addBroadcastJobs.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload.status
            toast.info(action.payload.status)
        })
        /////////////Get all Jobs in the project//////////////
        .addCase(getAllJobsProject.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(getAllJobsProject.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            // state.message = action.payload.message
            state.allProjectJobs = action.payload.result
        })
        .addCase(getAllJobsProject.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
        })
        /////////////Get Category And Task//////////////
        .addCase(getCategoryAndTask.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(getCategoryAndTask.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            // state.message = action.payload.message
            state.categoryAndTask = action.payload
        })
        .addCase(getCategoryAndTask.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            // state.message = action.payload.message
            // toast.info(action.payload.message)
        })
    }
})

export const { reset } = AddJobSlice.actions
export default AddJobSlice.reducer