// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip>.tooltiptext {
  visibility: hidden;
  width:max-content;
  background-color: #333;
  color: #fff;
  text-align: left;
  border-radius: 4px;
  padding: 0.5rem .5rem 0.5rem 1.5rem;
  position: absolute;
  z-index: 8;
  font-family: 'Arial', sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 2;
  letter-spacing: 1.5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  max-height: 30vh;
  transition: all .3s linear;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
/* Customize the appearance of the scrollbar */
.tooltiptext::-webkit-scrollbar {
  width: 5px;
  display:inline;
}

.tooltiptext::-webkit-scrollbar-thumb {
  background-color: grey; /* Change the color of the thumb */
}

.tooltiptext::-webkit-scrollbar-track {
  border-radius: 1rem;
  background-color: #333;
}
.right {
  right: 100%;
}
.left {
  left: 50%;
}
.top {
  bottom: 100%;
}
.bottom {
  top: 100%
}
.tooltiptext>ol>li {
  list-style-type: square;

}`, "",{"version":3,"sources":["webpack://./src/Components/ToolTip/Tooltip.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,sBAAsB;EACtB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,mCAAmC;EACnC,kBAAkB;EAClB,UAAU;EACV,gCAAgC;EAChC,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,qBAAqB;EACrB,+FAA+F;EAC/F,kBAAkB;EAClB,uBAAuB;EACvB,gBAAgB;EAChB,0BAA0B;AAC5B;AACA;EACE,mBAAmB;AACrB;AACA,8CAA8C;AAC9C;EACE,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,sBAAsB,EAAE,kCAAkC;AAC5D;;AAEA;EACE,mBAAmB;EACnB,sBAAsB;AACxB;AACA;EACE,WAAW;AACb;AACA;EACE,SAAS;AACX;AACA;EACE,YAAY;AACd;AACA;EACE;AACF;AACA;EACE,uBAAuB;;AAEzB","sourcesContent":[".tooltip {\n  position: relative;\n  display: inline-block;\n}\n\n.tooltip>.tooltiptext {\n  visibility: hidden;\n  width:max-content;\n  background-color: #333;\n  color: #fff;\n  text-align: left;\n  border-radius: 4px;\n  padding: 0.5rem .5rem 0.5rem 1.5rem;\n  position: absolute;\n  z-index: 8;\n  font-family: 'Arial', sans-serif;\n  font-size: 1rem;\n  font-weight: 300;\n  line-height: 2;\n  letter-spacing: 1.5px;\n  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;\n  overflow-y: scroll;\n  scroll-behavior: smooth;\n  max-height: 30vh;\n  transition: all .3s linear;\n}\n.tooltip:hover .tooltiptext {\n  visibility: visible;\n}\n/* Customize the appearance of the scrollbar */\n.tooltiptext::-webkit-scrollbar {\n  width: 5px;\n  display:inline;\n}\n\n.tooltiptext::-webkit-scrollbar-thumb {\n  background-color: grey; /* Change the color of the thumb */\n}\n\n.tooltiptext::-webkit-scrollbar-track {\n  border-radius: 1rem;\n  background-color: #333;\n}\n.right {\n  right: 100%;\n}\n.left {\n  left: 50%;\n}\n.top {\n  bottom: 100%;\n}\n.bottom {\n  top: 100%\n}\n.tooltiptext>ol>li {\n  list-style-type: square;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
