import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {toast} from 'react-toastify'

const initialState = {
    projects: [],
    singleProject: null,
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:'',
}


//////////////////////Create Group///////////////////////////
export const createProject = createAsyncThunk('Project/createProject',
async(projectData, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/project/register`
        const response = await axios.post(API_URL, projectData,{withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.response.data.status || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


////////////////////Get Project////////////////////////
export const getProject = createAsyncThunk('Project/getProject',
async(thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/project/getProjects`
        const response = await axios.get(API_URL, {withCredentials:true})
        console.log(response.data,'response.data');
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) ||  error.response.data.status || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


////////////////////Get Single Project////////////////////////
export const getSingleProject = createAsyncThunk('Project/getSingleProject',
async(projectId, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/project/${projectId}`
        const response = await axios.get(API_URL, {withCredentials:true})
        console.log(response.data,'response.data');
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) ||  error.response.data.status || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


//////////////////////Update Project///////////////////////////
export const updateProject = createAsyncThunk('Project/updateProject',
async({data, projectId}, thunkAPI)=>{
    try{
        console.log({data, projectId},'{data, projectId}');
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/project/updateProject/${projectId}`
        const response = await axios.put(API_URL, data, {withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) ||  error.response.data.status || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


//////////////////////Delete Project///////////////////////////
export const deleteProject = createAsyncThunk('Project/deleteProject',
async(id, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/project/${id}`
        const response = await axios.delete(API_URL,{withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message)  ||  error.response.data.status || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const projectSlice = createSlice({
    name:'project',
    initialState,
    reducers:{
        reset:(state)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        },
    },
    extraReducers:(builder) => {
        builder
        /////////////Create Project//////////////
        .addCase(createProject.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(createProject.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.status
            toast.info(action.payload.status)
        })
        .addCase(createProject.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            toast.info(action.payload)
        })
        /////////////Get Project/////////////
        .addCase(getProject.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(getProject.fulfilled, (state, action)=>{
            if(action.payload.success===true){
                state.projects = action.payload.result
                console.log(action.payload.result,'action.payload.result');
            }
            state.isLoading = false
            state.isSuccess = true
        })
        .addCase(getProject.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            // state.message = action.payload.status
            // toast.info(action.payload.status)
        })
        /////////////Get Single Project/////////////
        .addCase(getSingleProject.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(getSingleProject.fulfilled, (state, action)=>{
            if(action.payload.success===true){
                state.singleProject = action.payload.result
                console.log(action.payload.result,'action.payload.result');
            }
            state.isLoading = false
            state.isSuccess = true
        })
        .addCase(getSingleProject.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            // state.message = action.payload.status
            // toast.info(action.payload.status)
        })
        /////////////Update Project//////////////
        .addCase(updateProject.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(updateProject.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.status
            toast.info(action.payload.status)
        })
        .addCase(updateProject.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            toast.info(action.payload)
        })
        /////////////Delete Project//////////////
        .addCase(deleteProject.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(deleteProject.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.status
            toast.info(action.payload.status)
        })
        .addCase(deleteProject.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            toast.info(action.payload)
        })
    }
})

export const { reset } = projectSlice.actions
export default projectSlice.reducer