import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import NotAuthorized from '../../NotAuthorized/NotAuthorized';
import CheckRole from '../../../Utils/CheckRole/CheckRole';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getDrivingZone, attachDrivingZoneToCar } from '../../../Features/ProjectDetail/DrivingZoneSlice';
import Loader from '../../../Components/Loader/Loader';

const AttachDrivingZoneToCar = () => {
  const { projectId, carId } = useParams();

  const dispatch = useDispatch();
  const { DrivingZones } = useSelector((store) => store.drivingZone);
  const [loading, setLoading] = useState(true);
  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getDrivingZone(projectId));
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (!selectedZoneId) {
      toast.info('please select driving zone')
      return;
    }
    const data = {projectId, carId, drivingZoneId: selectedZoneId}
    dispatch(attachDrivingZoneToCar(data))
      .then(() => {
        navigate(-1);
      })
      .catch((error) => {
        console.error('Error attaching driving zone to car:', error);
      });
  };

  return (
    (('owner' === CheckRole() || 'manager' === CheckRole()) ? (
      <div className='flex_row_center_center Project_Container'>
        {loading ? (
          <Loader />
        ) : (
          <>
            {DrivingZones.length === 0 ? (
              <>
                <h4>No driving zone added yet</h4>
                <Link className="secondary-btn" to={`/project-details/${projectId}/add-drivingzone`}>Create Driving Zone</Link>
              </>
            ) : (
              <>
              <h4>Select Driving Zone:</h4>
              <form onSubmit={onSubmit} className='flex_column form form_project'>
                <div className="flex_row_project">
                 <select id="selectDrivingZone"onChange={(e) => setSelectedZoneId(e.target.value)} >
                  <option value="">Select a Driving Zone</option>
                  {DrivingZones.map((zone, index) => (
                    <option key={index} value={zone.drivingZoneId}>
                      {zone.drivingZone.name}
                    </option>
                  ))}
                </select>
              </div>
              <button type="submit" className="btn">Attach Driving Zone to Car</button>
              </form>
              <div className="nav_btn_group">
                <Link className="secondary_btn" to={`/project-details/${projectId}/drivingzones/add-drivingzone`}>Add driving zone +</Link>
                <Link className="secondary_btn" to={`/project-details/${projectId}/cars/view-cars`}>View cars</Link>
                </div>
              </>
 
            )}


          </>
        )}
      </div>

    ) : <NotAuthorized />)
  );
};

export default AttachDrivingZoneToCar;
