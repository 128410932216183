import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {toast} from 'react-toastify'

const initialState = {
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:'',
}


//////////////////////Add Car Preference///////////////////////////
// Update addCarPreferences thunk
export const addCarPreferences = createAsyncThunk(
    'Car/addCarPreferences',
    async (preferenceData, thunkAPI) => {
      try {
        console.log(preferenceData);
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/car/setPreference`;
        const response = await axios.put(API_URL, preferenceData, { withCredentials: true });
        console.log(response.data, 'response.data');
        return response.data;
      } catch (error) {
        console.error('addCarPreferences error:', error);
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  




export const CarPreferenceSlice = createSlice({
    name:'CarPreference',
    initialState,
    reducers:{
        reset:(state)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        },
    },
    extraReducers:(builder) => {
        builder
        /////////////Add Car Preferences//////////////
        .addCase(addCarPreferences.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(addCarPreferences.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            toast.info(action.payload.message)
        })
        .addCase(addCarPreferences.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            toast.info(action.payload.message)
        })
    }
})

export const { reset } = CarPreferenceSlice.actions
export default CarPreferenceSlice.reducer