import React, { useState, useEffect } from 'react';
import './Car.css';
import { Link, useParams,useNavigate } from 'react-router-dom';
import NotAuthorized from '../../NotAuthorized/NotAuthorized';
import CheckRole from '../../../Utils/CheckRole/CheckRole';
import { useDispatch, useSelector } from 'react-redux';
import { PiDotsThreeOutlineVerticalFill } from 'react-icons/pi';
import { FaTrash } from 'react-icons/fa';
import { getProjectCars, deleteCar } from '../../../Features/ProjectDetail/CarSlice';
import Loader from '../../../Components/Loader/Loader';
import DataTable from '../../../Components/DataTable/DataTable';

const ViewCar = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const [selectedCar, setSelectedCar] = useState(null);

  const { cars } = useSelector((store) => store.car);

  const navigate = useNavigate();
  const [selectedAction, setSelectedAction] = useState('');

  const handleOptionChange = (e,carId) => {
    setSelectedAction(e.target.value);
    setSelectedCar(carId);
    console.log(carId,"car",e.target.value,"e.target.value")
  };

  useEffect(()=>{
    const handleNavigate = (carId) => {
      console.log(selectedAction,"selected action")
      switch (selectedAction) {
        case "attachParking":
          navigate(`/project-details/${projectId}/cars/view-cars/${carId}/attach-parking`);
          break;
        case "selectDrivingZone":
         navigate(`/project-details/${projectId}/cars/view-cars/${carId}/attach-drivingzone`);
          break;
        case "setPreferences":
         navigate(`/project-details/${projectId}/cars/view-cars/${carId}/setpreferences`);
          break;
        default:
          // Handle default case
          break;
      }
    };
    handleNavigate(selectedCar)
  },[selectedAction])

  

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getProjectCars(projectId));
      setLoading(false);
    };

    fetchData();
  }, [dispatch, projectId]);

  // Add serial numbers to each car object
  const activeCars = cars.map((car, index) => ({ ...car, SrNo: index + 1 }));

  const columns = [
    { field: 'SrNo', headerName: '#',minWidth: 30, flex: 1  },
    // { field: 'carId', headerName: 'Id', width: 80 },
    { field: 'car.make', headerName: 'Make', minWidth: 150, flex: 1 , valueGetter: (params) => params.row.car.make },
    // { field: 'car.projectCarModels[0].name', headerName: 'Car Model', width: 150, valueGetter: (params) => params.row.car.projectCarModels[0]?.name },
    // { field: 'car.license', headerName: 'Car License', width: 150, valueGetter: (params) => params.row.car.license },
    { field: 'car.vin', headerName: 'Car Vin', minWidth: 120, flex: 1 , valueGetter: (params) => params.row.car.vin },
    {
      field: 'delete',
      headerName: 'Delete',
      minWidth: 150 , flex: 1 ,
      renderCell: (params) => (
        <button className='deleteButton' onClick={() => dispatch(deleteCar(params.row.carId))}>
          <FaTrash />
        </button>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 200, flex: 1 ,
      renderCell: (params) => (
          <select className='car_action_Select' value={selectedAction}  onChange={(e)=>handleOptionChange(e,params.row.carId)}>
            <option disabled selected value="">&#8942;</option>
            <option value="attachParking">Attach Parking</option>
            <option value="selectDrivingZone">Select Driving Zone</option>
            <option value="setPreferences">Set Preference</option>
          </select>
      ),
    },
    {
      field: 'carDetails',
      headerName: 'Car Details',
      minWidth: 150, flex: 1 ,
      renderCell: (params) => (
        <Link className="btn" to={`/project-details/${params.row.projectId}/view-cars/${params.row.carId}`}>
          Details
        </Link>
      ),
    },
  ];

  return (
    (('owner' === CheckRole() || 'manager' === CheckRole()) ?
      (<div className='Project_Container'>
        {loading ? (
          <Loader />
        ) : (
          <>
            {
              cars.length === 0 ? (
                <>
                  <h4 className='Not_Data_heading' >No car added yet</h4>
                  <div className="nav_btn_group">
                  <Link className="secondary_btn" to={`/project-details/${projectId}/cars/add-car`}> Add Car +</Link>
                  <Link className="secondary_btn" to={`/project-details/${projectId}/cars/add-multiple-cars`}> Add Multiple Cars +</Link>
                  </div>
                </>
              ) :<>
                  <h4>All Added Cars</h4>
                  <DataTable columns={columns} data={activeCars} pageSize={6} />
                  <div className="nav_btn_group">
                  <Link className="secondary_btn" to={`/project-details/${projectId}/cars/add-car`}> Add Car +</Link>
                  <Link className="secondary_btn" to={`/project-details/${projectId}/cars/add-multiple-cars`}> Add Multiple Cars +</Link>
                  </div>
                  </>
            }
          </>
        )}
      </div>) : <NotAuthorized />
    )
  );
};

export default ViewCar;
