import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {toast} from 'react-toastify'

const initialState = {
    operators:[],
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:'',
}



//////////////////////Invite Operator///////////////////////////
export const inviteOperator = createAsyncThunk('projectDetail/inviteOperator',
async(operatorData, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/project/inviteOperator`
        const response = await axios.post(API_URL, operatorData,{withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message)||error.response.data.status  || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

//////////////////////get operators///////////////////////////
export const getOperators = createAsyncThunk('projectDetail/getOPerators',
async(projectId, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/project/getProjectOperatorInvites/${projectId}`
        const response = await axios.get(API_URL, {withCredentials:true})
        console.log(response);
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.response.data.status || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

//////////////////////delete Operator///////////////////////////
export const deleteOperator= createAsyncThunk('projectDetail/deleteOperator',
async(id, thunkAPI)=>{
    try{
        
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/operator/${id}`
        const response = await axios.delete(API_URL, {withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.response.data.status || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})




export const OperatorSlice = createSlice({
    name:'Operator',
    initialState,
    reducers:{
        reset:(state)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        },
    },
    extraReducers:(builder) => {
        builder
        /////////////Invite Operator//////////////
        .addCase(inviteOperator.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(inviteOperator.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            toast.info(action.payload.message)
        })
        .addCase(inviteOperator.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            toast.info(action.payload.message)
        })
        .addCase(getOperators.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(getOperators.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.operators=action.payload.result
            toast.info(action.payload.message)
        })
        .addCase(getOperators.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            // state.message = action.payload.message
            // toast.info(action.payload.message)
        })
        .addCase(deleteOperator.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(deleteOperator.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.status
            toast.info(action.payload.status)
        })
        .addCase(deleteOperator.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            toast.info(action.payload)
        })
    }
})

export const { reset } = OperatorSlice.actions
export default OperatorSlice.reducer