import React ,{useState}from 'react'
import './MobileNav.css' ;
import {Link} from 'react-router-dom'
import CheckRole from "../../Utils/CheckRole/CheckRole";
import NotAuthorized from '../../Pages/NotAuthorized/NotAuthorized';
import {dashBoardLinkData,mainLinkData} from '../../Data/Data'

const MobileNav = ({projectId,role}) => {
    const [show, setShow] = useState(false)
    const [firstShow, setFirstShow] = useState(true)
    // const {projectId} =  useParams()


    const showFunction =()=>{
      setFirstShow(false)
      setShow(!show)
    }

  return (
    <div className="Mob_Navbar">
            <div className={show?"Mob_Navbar_right":(firstShow===false && show===false)?'hidden_animation':"hidee"}>
              {CheckRole()? (
                <>
                {projectId!=undefined? dashBoardLinkData(projectId).map((obj,index)=>{
                  return <Link key={index} onClick={showFunction}  to={obj.link}>{obj.text}</Link>
                }):mainLinkData(role).map((obj,index)=>{
                  return <Link key={index} onClick={showFunction} to={obj.link}>{obj.text}</Link>
                })}         
                </>)  : null
              }
        </div>
        <div className={show?(projectId!=undefined?'cross burger burger-dashboard':'cross burger'):projectId!=undefined?`burger burger-dashboard`:"burger"} onClick={showFunction}>
            <div></div>
            <div></div>
        </div>
       
    </div>
  )
}

export default MobileNav

