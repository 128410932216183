import React from 'react';
import { decryptAndRetrieve }  from "../Encryption/Encryption"


const CheckRole = () => {
    const user = decryptAndRetrieve('user')
    const userRole = user?.role
  return userRole;
};

export default CheckRole;
