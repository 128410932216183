import React, { useState ,useEffect} from "react";
// import './SignUp.css';
import { useDispatch,useSelector } from "react-redux";
import { useParams,useNavigate,Link } from "react-router-dom";
import { toast } from "react-toastify";
import CheckRole from "../../../Utils/CheckRole/CheckRole";
import NotAuthorized from "../../NotAuthorized/NotAuthorized";
import { inviteOperator } from "../../../Features/ProjectDetail/OperatorSlice";
import { MdDeleteForever } from "react-icons/md";
import { MdPersonAddAlt1 } from "react-icons/md";
import Tooltip from "../../../Components/ToolTip/Tooltip";

const InviteOperator = () => {
  const [data, setData] = useState({ operatorEmail: [''] });
  const { operatorEmail } = data;

  const { user } = useSelector((store) => store.auth);

  const { projectId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onEmailChange = (index, value) => {
    const updatedEmails = [...operatorEmail];
    updatedEmails[index] = value;
    setData((prevState) => ({ ...prevState, operatorEmail: updatedEmails }));
  };

  const addEmailInput = () => {
    setData((prevState) => ({
      ...prevState,
      operatorEmail: [...operatorEmail, ""],
    }));
  };

  const removeEmailInput = (index) => {
    const updatedEmails = [...operatorEmail];
    updatedEmails.splice(index, 1);
    setData((prevState) => ({ ...prevState, operatorEmail: updatedEmails }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (operatorEmail[0] === "") {
      toast.info("Please provide operator email");
    } else {
      const operatorData = {
        operatorEmail,
        projectId,
      };

      dispatch(inviteOperator(operatorData)).then((res)=>{res.payload.success==true && navigate(`/project-details/${projectId}/view-operators`)})};
    }



  useEffect(() => {
    if (!user) {
      navigate("/signin");
    }
  },[]);


  return "owner" === CheckRole() || "manager" === CheckRole() ? (
    <div className="flex_row_center_center Project_Container">
          <h4>Invite Operators to join</h4>
      <form onSubmit={onSubmit} className="flex_column form form_project">
        <div style={{textAlign:'left', width:'100%'}}>
        </div>
        <div className='flex_column form_group form_group_project'>
          <label>Emails</label>
          {operatorEmail.map((email, index) => (
            <div key={index} style={{ display: "flex" }}>
              <input
                type="email"
                placeholder={`Enter email ${index + 1}`}
                value={email}
                onChange={(e) => onEmailChange(index, e.target.value)}
              />
              {index !== 0 && (
                <button style={{ fontSize: 'larger' }} type='button' onClick={() => removeEmailInput(index)}>
                  <MdDeleteForever/>
                </button>
              )}
            </div>
          ))}
          <div className="flex_column_center_center">
            <button
              type="button"
              onClick={addEmailInput}
              style={{fontSize:"1.5rem"}}
            >
              <MdPersonAddAlt1/>
              <Tooltip text={["click to add more members"]}/>
            </button>
          </div>
        </div>
        <div>
          <button className="btn" type="submit">
            Invite Operator
          </button>
        </div>
      </form>
      <div className="nav_btn_group">
            <Link className="secondary_btn"to={`/project-details/${projectId}/employees/view-operators`}>View Operators</Link>
            <Link className='secondary_btn'to={`/project-details/${projectId}/employees/view-managers`}>View Managers</Link>
            <Link className='secondary_btn'to={`/project-details/${projectId}/employees/add-manager`}>Add Manager +</Link>
          </div>
    </div>
  ) : (
    <NotAuthorized />
  );
};

export default InviteOperator;
