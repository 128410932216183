// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav_btn_group{
  display: flex;
  justify-content: center;
  align-self: center;
  gap:2rem;
  flex-wrap: wrap;
}

.Not_Data_heading{
  margin:0 0 2rem 0;
}

.car_action_Select{
  appearance: none; 
  -webkit-appearance: none;
  background-color: transparent; 
  border: none; 
  outline: none; 
  padding: 0; 
  cursor: pointer;
  padding: 1rem;
}
.car_action_Select>option:hover{
    box-shadow: 0 0 10px 100px #000 inset;
    background-color: #000;
}

.customSelect::-ms-expand {
  display: none; 
}`, "",{"version":3,"sources":["webpack://./src/Pages/ProjectDetails/Cars/Car.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,QAAQ;EACR,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,wBAAwB;EACxB,6BAA6B;EAC7B,YAAY;EACZ,aAAa;EACb,UAAU;EACV,eAAe;EACf,aAAa;AACf;AACA;IACI,qCAAqC;IACrC,sBAAsB;AAC1B;;AAEA;EACE,aAAa;AACf","sourcesContent":[".nav_btn_group{\n  display: flex;\n  justify-content: center;\n  align-self: center;\n  gap:2rem;\n  flex-wrap: wrap;\n}\n\n.Not_Data_heading{\n  margin:0 0 2rem 0;\n}\n\n.car_action_Select{\n  appearance: none; \n  -webkit-appearance: none;\n  background-color: transparent; \n  border: none; \n  outline: none; \n  padding: 0; \n  cursor: pointer;\n  padding: 1rem;\n}\n.car_action_Select>option:hover{\n    box-shadow: 0 0 10px 100px #000 inset;\n    background-color: #000;\n}\n\n.customSelect::-ms-expand {\n  display: none; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
