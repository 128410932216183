import { FaProjectDiagram, FaMapMarked, FaCarAlt } from "react-icons/fa";
import { MdGroups, MdPerson } from "react-icons/md";
import { LuParkingCircle } from "react-icons/lu";
import { GrProjects } from "react-icons/gr";
import { PiTreeEvergreen } from "react-icons/pi";
import { IoMdNotifications } from "react-icons/io";
import { Link } from "react-router-dom";
import { GrGroup } from "react-icons/gr";

export const DashboardCards = [
  {
    title:"Projects",
    icon: <GrProjects style={{fontSize:"1rem"}} />,
    linkadd: <Link to="/add-project"className="btn">Add project +</Link>,
    linkView: <Link to="/projects" className="secondary_btn" >View Projects</Link>,
    description:null,
    status: true,
    toolTipText:["Project represent a group of vehicles in your fleet.","You can create multiple project"]
  },
  {
    title:"Groups",
    icon: <GrGroup style={{fontSize:"1rem"}}/>,
    linkView: <Link to="/groups" className="secondary_btn">View Groups</Link>,
    linkadd: <Link to="/add-group" className="btn">Add group +</Link>,
    description: null,
    status: true,
    toolTipText:["Group represent a group of individuals.","You can add multiple individuals"]
  },
  {
    title:"Insights",
    icon:<PiTreeEvergreen style={{fontSize:"1rem"}}/>,
    linkView: <Link to="/insights" className="btn">View impact</Link>,
    linkadd:null,
    status: true,
    toolTipText:["Climate impact of your fleet."]
  },
];

export const projectDetailCards = [
  {
    name: "Add Car",
    icon: <FaCarAlt />,
    link: "/project-details/add-car",
  },
  {
    name: "View Cars",
    icon: <FaCarAlt />,
    link: "/project-details/view-cars",
  },
  {
    name: "Add Manager",
    icon: <MdPerson />,
    link: "/project-details/add-manager",
  },
  {
    name: "View Managers",
    icon: <MdPerson />,
    link: "/project-details/view-managers",
  },
  {
    name: "Add Operator",
    icon: <MdPerson />,
    link: "/project-details/add-operator",
  },
  {
    name: "View Operators",
    icon: <MdPerson />,
    link: "/project-details/view-operators",
  },
  {
    name: "Add Parking",
    icon: <LuParkingCircle />,
    link: "/project-details/add-parking",
  },
  {
    name: "View Parkings",
    icon: <LuParkingCircle />,
    link: "/project-details/view-all-parkings",
  },
  {
    name: "Add Driving Zone",
    icon: <FaMapMarked />,
    link: "/project-details/add-drivingzone",
  },
  {
    name: "View Driving Zones",
    icon: <FaMapMarked />,
    link: "/project-details/view-all-drivingzones",
  },
];
export function dashBoardLinkData(projectId) {
  return [
    { link: `/projects`, text: "Projects" },
    { link: `/project-details/${projectId}/cars/view-cars`, text: " Cars" },
    {
      link: `/project-details/${projectId}/employees/view-managers`,
      text: "employees",
    },
    {
      link: `/project-details/${projectId}/jobs/view-operators-jobs`,
      text: "Jobs",
    },
    {
      link: `/project-details/${projectId}/parkings/view-all-parkings`,
      text: "Parkings",
    },
    {
      link: `/project-details/${projectId}/drivingzones/view-all-drivingzones`,
      text: "Driving Zones",
    },
  ];
}


export function mainLinkData(role){
 const links= role=="owner"?[{link:"/",text:"Home"},{link:"/pricing-model",text:"Pricing"},{link:"/subscription",text:"Subscription"}]:
    role=="manager"?[{link:"/",text:"Home"},{link:"/notifications-manager",text: "Notifications"}]:
    role=="operator"?[{link:"/",text:" Broadcast Jobs"},{link:"/assigned-jobs-for-operator",text:" Assigned Jobs"},{link:"/notifications-operator",text:"Notifications"}]:
    [{link:"/",text:"Home"}]
    return links
}
