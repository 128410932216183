import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {toast} from 'react-toastify'

const initialState = {
    parkings:[],
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:'',
}



//////////////////////Register Parking///////////////////////////
export const registerParking = createAsyncThunk('projectDetail/registerParking',
async(parkingData, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/parking/register`
        const response = await axios.post(API_URL, parkingData,{withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) ||error.response.data.status || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

//////////////////////get Parking///////////////////////////
export const getParking = createAsyncThunk('projectDetail/getParking',
async(parkingId, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/project/getProjectParkings/${parkingId}`
        const response = await axios.get(API_URL, {withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.response.data.status || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})




export const ParkingSlice = createSlice({
    name:'Parking',
    initialState,
    reducers:{
        reset:(state)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        },
    },
    extraReducers:(builder) => {
        builder
        /////////////Register Parking//////////////
        .addCase(registerParking.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(registerParking.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            toast.info(action.payload.message)
        })
        .addCase(registerParking.rejected, (state, action)=>{
            console.log(action.payload);
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            toast.info(action.payload)
        })

        /////////////get Parking//////////////
        .addCase(getParking.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(getParking.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.parkings= action.payload.result
            state.message = action.payload.message
            toast.info(action.payload.message)
        })
        .addCase(getParking.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.parkings = []
            state.message = action.payload
            toast.error(action.payload)
        })
    }
})

export const { reset } = ParkingSlice.actions
export default ParkingSlice.reducer