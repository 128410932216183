// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImpactCard{
    padding:.5rem 1rem;
    flex: 1 1;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-right: 1px solid var(--light_gray);
}
.chartDoughnut{
flex-shrink: 0;
width: 50%;
height: 6rem;
}

.HeadingandFig{
width: 50%;

display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
flex-shrink: 0;

}
.HeadingandFig>h6{
    font-size: 1rem;
    color: var(--gray);
}

.HeadingandFig>h5{
    font-size: 1rem;
    font-weight: 300;
    
}

@media screen and (max-width: 900px){
    .ImpactCard{
        min-width: 50%;
    }

    
}


@media screen and (max-width: 450px){

  
    
}`, "",{"version":3,"sources":["webpack://./src/Components/ImpactPageComponents/FigureCard/FigureCard.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAO;IACP,cAAc;IACd,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,yCAAyC;AAC7C;AACA;AACA,cAAc;AACd,UAAU;AACV,YAAY;AACZ;;AAEA;AACA,UAAU;;AAEV,aAAa;AACb,sBAAsB;AACtB,uBAAuB;AACvB,2BAA2B;AAC3B,cAAc;;AAEd;AACA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;;AAEpB;;AAEA;IACI;QACI,cAAc;IAClB;;;AAGJ;;;AAGA;;;;AAIA","sourcesContent":[".ImpactCard{\n    padding:.5rem 1rem;\n    flex: 1;\n    flex-shrink: 0;\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    border-right: 1px solid var(--light_gray);\n}\n.chartDoughnut{\nflex-shrink: 0;\nwidth: 50%;\nheight: 6rem;\n}\n\n.HeadingandFig{\nwidth: 50%;\n\ndisplay: flex;\nflex-direction: column;\nalign-items: flex-start;\njustify-content: flex-start;\nflex-shrink: 0;\n\n}\n.HeadingandFig>h6{\n    font-size: 1rem;\n    color: var(--gray);\n}\n\n.HeadingandFig>h5{\n    font-size: 1rem;\n    font-weight: 300;\n    \n}\n\n@media screen and (max-width: 900px){\n    .ImpactCard{\n        min-width: 50%;\n    }\n\n    \n}\n\n\n@media screen and (max-width: 450px){\n\n  \n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
