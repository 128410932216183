import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function DoughnutChart({ data, title, showTitle, color }) {
  const width =  window.innerWidth
  let height = 100
  if(width<=900){
     height = 50
  }
  
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        
        labels: {
            boxWidth: 7,
            font: {
              size: 8, // Change this value to adjust the legend font size
            },
          },
      },
      title: {
        display: showTitle,
        text: title,
      },
      
    },
    
    maintainAspectRatio: false,
    
  };

if(color){
  data = {
    ...data,
    datasets: [
      {
        ...data.datasets[0],
        backgroundColor: [color.primary || '#0e0e0e', color.secondary || '#007bff' ],
        hoverOffset: 4,
      },
    ],
  };
}

  return <Doughnut options={options} data={data} height={height}  />;
}
