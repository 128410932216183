import React, { useState } from 'react';
import { MdDeleteForever } from "react-icons/md";
import "./Subscription.css";
import { useNavigate,Link } from 'react-router-dom';

const ViewSubscription = () => {
  const [cards, setCards] = useState(JSON.parse(localStorage.getItem('cards')) || []);
  const navigate = useNavigate();

  const deleteCard = (indexToDelete) => {
    const updatedCards = cards.filter((card, index) => index !== indexToDelete);
    localStorage.setItem('cards', JSON.stringify(updatedCards));
    setCards(updatedCards);
  };

  const maskCardNumber = (number) => {
    const groups = number.split(" ");
    const maskedGroups = groups.map((group, index) => (index < 3 ? "****" : group));
    return maskedGroups.join(" ");
  };

  return (
    <div className='flex_row_center_center Project_Container'>
      <div className="flex_column form form_project">
      <h4>Subscription Details</h4>
      <div className="flex_column_center_center">
        {cards.length>0?cards.map((card, index) => (
          <div key={index} className="flex_row_space_between card-container">
            <div className="credit-card-image"> </div>
            <div className="card-details">
              <p>Card Number: {maskCardNumber(card.cardNumber)}</p>
              <p>Expiry: {card.expiry ? card.expiry : 'Not available'}</p>
            </div>
            <div className="deleteButton" onClick={() => deleteCard(index)}>
              <MdDeleteForever />
            </div>
          </div>
      )):<p style={{marginBottom:"2rem"}}>You have added not any card for payment yet</p>}
      </div>
      </div>
    </div>
  )
}
export default ViewSubscription;