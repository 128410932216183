import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {toast} from 'react-toastify'

const initialState = {
    managerInvitesProject:[],
    operatorInvitesProject:[],
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:'',
}




//////////////////////get manager Invites for Project///////////////////////////
export const getManagerInvitesProject = createAsyncThunk('notification/getManagerInvitesProject',
async(email, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/project/getManagerInvites/${email}`
        const response = await axios.get(API_URL, {withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.response.data.status || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


//////////////////////manager Response To project invites///////////////////////////
export const managerResponseToProjectInvites = createAsyncThunk('notification/managerResponseToProjectInvites',
async({managerEmail,projectId},thunkAPI)=>{
    const data = {managerEmail,projectId}
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/project/managerJoinProject`
        const response = await axios.post(API_URL, data, {withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.response.data.status || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


//////////////////////get Operator Invites for Project///////////////////////////
export const getOperatorInvitesProject = createAsyncThunk('notification/getOperatorInvitesProject',
async(email, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/project/getOperatorInvites/${email}`
        const response = await axios.get(API_URL, {withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.response.data.status || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


//////////////////////Operator Response To project invites///////////////////////////
export const operatorResponseToProjectInvites = createAsyncThunk('notification/operatorResponseToProjectInvites',
async({operatorEmail,projectId},thunkAPI)=>{
    const data = {operatorEmail,projectId}
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/project/operatorJoinProject`
        const response = await axios.post(API_URL, data, {withCredentials:true})
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.response.data.status || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})





export const NotificationSlice = createSlice({
    name:'Notification',
    initialState,
    reducers:{
        reset:(state)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        },
    },
    extraReducers:(builder) => {
        builder
        ////////get manager invites for projects//////
        .addCase(getManagerInvitesProject.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(getManagerInvitesProject.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.managerInvitesProject=action.payload.result
            toast.info(action.payload.message)
        })
        .addCase(getManagerInvitesProject.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            toast.info(action.payload.message)
        })
        ////////manager Response To project invites//////
        .addCase(managerResponseToProjectInvites.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(managerResponseToProjectInvites.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            toast.info(action.payload.message)
        })
        .addCase(managerResponseToProjectInvites.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            toast.info(action.payload.message)
        })
        ////////get Operator invites for projects//////
        .addCase(getOperatorInvitesProject.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(getOperatorInvitesProject.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.operatorInvitesProject=action.payload.result
            toast.info(action.payload.message)
        })
        .addCase(getOperatorInvitesProject.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            toast.info(action.payload.message)
        })
        ////////operator Response To project invites//////
        .addCase(operatorResponseToProjectInvites.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(operatorResponseToProjectInvites.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            toast.info(action.payload.message)
        })
        .addCase(operatorResponseToProjectInvites.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            toast.info(action.payload.message)
        })
    }
})

export const { reset } = NotificationSlice.actions
export default NotificationSlice.reducer