// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styledCardMYDASHBOARD{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:1rem;
    height: auto;
    padding: 2rem;
    box-shadow: 1px 1px 10px rgba(0,0,0,0.1);
    cursor: pointer;
    width: calc((100% - (4 - 1) * 2rem) / 4);
    position: relative;

}

.styledCardMYDASHBOARD>h6{
    color: var(--primary_color);
    display: flex;
    gap: 5px;
    font-weight: 600;
}

@media screen and (max-width: 900px){
    .styledCardMYDASHBOARD{
        width: 100%;
    }
    
    
}`, "",{"version":3,"sources":["webpack://./src/Components/ImpactPageComponents/CardComponent/CardComponent.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,QAAQ;IACR,YAAY;IACZ,aAAa;IACb,wCAAwC;IACxC,eAAe;IACf,wCAAwC;IACxC,kBAAkB;;AAEtB;;AAEA;IACI,2BAA2B;IAC3B,aAAa;IACb,QAAQ;IACR,gBAAgB;AACpB;;AAEA;IACI;QACI,WAAW;IACf;;;AAGJ","sourcesContent":[".styledCardMYDASHBOARD{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    gap:1rem;\n    height: auto;\n    padding: 2rem;\n    box-shadow: 1px 1px 10px rgba(0,0,0,0.1);\n    cursor: pointer;\n    width: calc((100% - (4 - 1) * 2rem) / 4);\n    position: relative;\n\n}\n\n.styledCardMYDASHBOARD>h6{\n    color: var(--primary_color);\n    display: flex;\n    gap: 5px;\n    font-weight: 600;\n}\n\n@media screen and (max-width: 900px){\n    .styledCardMYDASHBOARD{\n        width: 100%;\n    }\n    \n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
