import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams ,Link} from 'react-router-dom';
import { addCarPreferences } from '../../../Features/ProjectDetail/CarPreferenceSlice';
import NotAuthorized from '../../NotAuthorized/NotAuthorized';
import CheckRole from '../../../Utils/CheckRole/CheckRole';
import { useNavigate } from 'react-router-dom';

// ... (your existing imports)

const CarPreference = () => {
    const navigate = useNavigate();
    const { projectId, carId } = useParams()

    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const dispatch = useDispatch();

    const [preferences, setPreferences] = useState(
        daysOfWeek.reduce((acc, day) => ({
            ...acc,
            [day]: { day: day, selected: 0, startTime: 1, endTime: 1 } // Default value set to 1
        }), {})
    );

    const handleCheckboxChange = (day) => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            [day]: {
                ...prevPreferences[day],
                selected: prevPreferences[day].selected === 1 ? 0 : 1,
            },
        }));
    };

    const handleTimeChange = (day, field, e) => {
        const newValue = e.target.value;
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            [day]: {
                ...prevPreferences[day],
                [field]: newValue,
            },
        }));
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        // Transform preferences state into the desired array format
        const preferenceArray = Object.values(preferences).map(({ day, selected, startTime, endTime }) => ({
            day,
            selected,
            startTime,
            endTime,
        }));

        const preferenceData = { carId, preference: preferenceArray }

        // Dispatch the preferences to the backend
        dispatch(addCarPreferences(preferenceData)).then((res)=>res.payload.success ===true&& navigate(`/project-details/${projectId}/view-cars`));
    };

    return (
    (('owner' === CheckRole() || 'manager' === CheckRole()) ? 
        (<div className='flex_row_center_center Project_Container'>
                <div style={{textAlign:'center', width:'100%'}}>
                    <h4>Car Preference</h4>
                </div>
            <form onSubmit={handleSubmit} className='flex_column form form_project'>

                <div className='flex_column form_group form_group_project'style={{gap:'1rem'}}>
                    {daysOfWeek.map((day) => (
                        <div key={day} className='flex_column'>
                            <div className='flex_row_center'>
                            <input
                                className='checkbox'
                                type='checkbox'
                                onChange={() => handleCheckboxChange(day)}
                                checked={preferences[day].selected === 1}
                            />
                            <h6>{day}</h6>
                            </div>
                            {preferences[day].selected === 1 && (
                                <div className='flex_column' style={{width:'50%'}}>
                                    <label>Start Time:</label>
                                    <input
                                        type='time'
                                        value={preferences[day].startTime}
                                        onChange={(e) => handleTimeChange(day, 'startTime', e)}
                                    />
                                    <label>End Time:</label>
                                    <input
                                        type='time'
                                        value={preferences[day].endTime}
                                        onChange={(e) => handleTimeChange(day, 'endTime', e)}
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <button className='btn' type='submit'>Submit</button>
            </form>
            <div className="nav_btn_group">
                <Link className="secondary_btn" to={`/project-details/${projectId}/cars/view-cars`}>View cars</Link>
                </div>
        </div>) : <NotAuthorized/>
    )
    );
};

export default CarPreference;

