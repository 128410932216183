
export const DummyData ={
    "year": {
        "cardData": {
            "distance": {
                "total": 49376,
                "electric": 9019,
                "combustible": 29888,
                "e_scooter": 10469
            },
            "fleetsize": {
                "total": 667,
                "electric": 153,
                "combustible": 348,
                "e_scooter": 166
            },
            "fuelStatus": {
                "total": 2666.905652343861,
                "electric": 450.30670470756104,
                "combustible": 2189.3795476363002,
                "saved": 362.58638773181207,
                "e_scooter": 27.219400000000018
            },
            "co2Status": {
                "total": 6390.056322007415,
                "electric": 1035.7054208273896,
                "combustible": 5291.536901180025,
                "saved": 778.8817517831671,
                "e_scooter": 62.814000000000036
            },
            "avgCo2PerRide": 6.415719198802625,
            "rides": {
                "total": 996,
                "electric": 178,
                "combustible": 608,
                "e_scooter": 210
            },
            "privateRidesReduced": 543,
            "trees_burduned": 12061471.054893592,
            "trees_relaxed": -1449718.106364341
        },
        "co2_saved": {
            "labels": [
                "2019",
                "2020"
            ],
            "datasets": [
                {
                    "label": "Co2 Saved",
                    "data": [
                        3.5222310984308134,
                        775.3595206847364
                    ]
                }
            ]
        },
        "co2_emitted": {
            "labels": [
                "2019",
                "2020"
            ],
            "datasets": [
                {
                    "label": "Co2 emitted",
                    "data": [
                        15.717787051275524,
                        6374.338534956146
                    ]
                }
            ]
        },
        "trees": {
            "labels": [
                "2019",
                "2020"
            ],
            "datasets": [
                {
                    "label": "Trees Relaxed",
                    "data": [
                        -61.793528042645846,
                        -1449656.3128362983
                    ]
                },
                {
                    "label": "Trees Burduned",
                    "data": [
                        275.7506500223776,
                        12061195.30424357
                    ]
                }
            ]
        },
        "fuel": {
            "labels": [
                "2019",
                "2020"
            ],
            "datasets": [
                {
                    "label": "Fuel saved",
                    "data": [
                        -1.7303700427960056,
                        -360.8560176890161
                    ]
                },
                {
                    "label": "Fuel used",
                    "data": [
                        6.1598976511356955,
                        2660.7457546927267
                    ]
                }
            ]
        },
        "fuelStatus": {
            "labels": [
                "fuel saved",
                "fuel consumed"
            ],
            "datasets": [
                {
                    "label": "Fuel",
                    "data": [
                        362.58638773181207,
                        2666.905652343861
                    ]
                }
            ]
        },
        "co2Status": {
            "labels": [
                "Co2 saved",
                "Co2 emitted"
            ],
            "datasets": [
                {
                    "label": "Co2",
                    "data": [
                        778.8817517831671,
                        6390.056322007415
                    ]
                }
            ]
        }
    },
    "month12": {
        "cardData": {
            "distance": {
                "total": 49376,
                "electric": 9019,
                "combustible": 29888,
                "e_scooter": 10469
            },
            "fleetsize": {
                "total": 667,
                "electric": 153,
                "combustible": 348,
                "e_scooter": 166
            },
            "fuelStatus": {
                "total": 2666.905652343861,
                "electric": 450.30670470756104,
                "combustible": 2189.3795476363002,
                "saved": 362.58638773181207,
                "e_scooter": 27.219400000000018
            },
            "co2Status": {
                "total": 6390.056322007415,
                "electric": 1035.7054208273896,
                "combustible": 5291.536901180025,
                "saved": 778.8817517831671,
                "e_scooter": 62.814000000000036
            },
            "avgCo2PerRide": 6.415719198802625,
            "rides": {
                "total": 996,
                "electric": 178,
                "combustible": 608,
                "e_scooter": 210
            },
            "trees_burduned": 112106.25126328802,
            "trees_relaxed": -13664.592136546811,
            "privateRidesReduced": 543
        },
        "co2_saved": {
            "labels": [
                "2019-12",
                "2020-01",
                "2020-02"
            ],
            "datasets": [
                {
                    "label": "Co2 Saved",
                    "data": [
                        3.5222310984308134,
                        580.644536376605,
                        194.71498430813122
                    ]
                }
            ]
        },
        "co2Status": {
            "labels": [
                "Co2 saved",
                "Co2 emitted"
            ],
            "datasets": [
                {
                    "label": "Co2",
                    "data": [
                        778.8817517831671,
                        6390.056322007415
                    ]
                }
            ]
        },
        "co2_emitted": {
            "labels": [
                "2019-12",
                "2020-01",
                "2020-02"
            ],
            "datasets": [
                {
                    "label": "Co2 emitted",
                    "data": [
                        15.717787051275524,
                        4821.7052879764515,
                        1552.6332469796926
                    ]
                }
            ]
        },
        "trees": {
            "labels": [
                "2019-12",
                "2020-01",
                "2020-02"
            ],
            "datasets": [
                {
                    "label": "Trees Relaxed",
                    "data": [
                        -61.793528042645846,
                        -10186.746252221157,
                        -3416.0523562830076
                    ]
                },
                {
                    "label": "Trees Burduned",
                    "data": [
                        275.7506500223776,
                        84591.32084169208,
                        27239.179771573556
                    ]
                }
            ]
        },
        "fuel": {
            "labels": [
                "2019-12",
                "2020-01",
                "2020-02"
            ],
            "datasets": [
                {
                    "label": "Fuel saved",
                    "data": [
                        -1.7303700427960056,
                        -269.6291897289587,
                        -91.22682796005707
                    ]
                },
                {
                    "label": "Fuel used",
                    "data": [
                        6.1598976511356955,
                        2011.8847965573336,
                        648.8609581353941
                    ]
                }
            ]
        },
        "fuelStatus": {
            "labels": [
                "fuel saved",
                "fuel consumed"
            ],
            "datasets": [
                {
                    "label": "Fuel",
                    "data": [
                        362.58638773181207,
                        2666.905652343861
                    ]
                }
            ]
        }
    },
    "days30": {
        "cardData": {
            "distance": {
                "total": 35744,
                "electric": 6616,
                "combustible": 21803,
                "e_scooter": 7325
            },
            "fleetsize": {
                "total": 543,
                "electric": 117,
                "combustible": 299,
                "e_scooter": 127
            },
            "fuelStatus": {
                "total": 1948.2091306844472,
                "electric": 330.3281027104139,
                "combustible": 1598.8360279740332,
                "saved": 260.23418687589174,
                "e_scooter": 19.04500000000001
            },
            "co2Status": {
                "total": 4660.6787071795125,
                "electric": 759.7546362339517,
                "combustible": 3856.9740709455605,
                "saved": 560.009129814551,
                "e_scooter": 43.95000000000004
            },
            "avgCo2PerRide": 6.402031191180649,
            "rides": {
                "total": 728,
                "electric": 131,
                "combustible": 446,
                "e_scooter": 151
            },
            "trees_burduned": 81766.2931084125,
            "trees_relaxed": -9824.721575693871,
            "privateRidesReduced": 397.53999999999996
        },
        "co2_saved": {
            "labels": [
                "2020-01-12",
                "2020-01-13",
                "2020-01-14",
                "2020-01-15",
                "2020-01-16",
                "2020-01-17",
                "2020-01-18",
                "2020-01-19",
                "2020-01-20",
                "2020-01-21",
                "2020-01-22",
                "2020-01-23",
                "2020-01-24",
                "2020-01-25",
                "2020-01-26",
                "2020-01-27",
                "2020-01-28",
                "2020-01-29",
                "2020-01-30",
                "2020-01-31",
                "2020-02-01",
                "2020-02-02",
                "2020-02-03",
                "2020-02-04",
                "2020-02-05",
                "2020-02-06",
                "2020-02-07",
                "2020-02-08",
                "2020-02-09",
                "2020-02-10",
                "2020-02-11"
            ],
            "datasets": [
                {
                    "label": "Co2 Saved",
                    "data": [
                        9.184000000000001,
                        20.11588017118402,
                        8.92154065620542,
                        26.931514978602,
                        12.975218259629102,
                        29.620747503566335,
                        5.724970042796006,
                        21.371606276747503,
                        24.500513552068476,
                        5.820171184022824,
                        23.97839087018545,
                        17.95143509272468,
                        11.526094151212552,
                        21.504068473609127,
                        19.20959201141227,
                        21.232884450784596,
                        26.10054778887304,
                        18.474356633380882,
                        19.26769757489301,
                        20.882915834522112,
                        16.05837375178317,
                        20.870727532097007,
                        13.755771754636234,
                        20.989203994293867,
                        20.922373751783166,
                        20.410174037089867,
                        26.63625677603423,
                        12.714356633380886,
                        15.845694721825963,
                        22.450881597717547,
                        4.061169757489301
                    ]
                }
            ]
        },
        "co2Status": {
            "labels": [
                "Co2 saved",
                "Co2 emitted"
            ],
            "datasets": [
                {
                    "label": "Co2",
                    "data": [
                        560.009129814551,
                        4660.6787071795125
                    ]
                }
            ]
        },
        "co2_emitted": {
            "labels": [
                "2020-01-12",
                "2020-01-13",
                "2020-01-14",
                "2020-01-15",
                "2020-01-16",
                "2020-01-17",
                "2020-01-18",
                "2020-01-19",
                "2020-01-20",
                "2020-01-21",
                "2020-01-22",
                "2020-01-23",
                "2020-01-24",
                "2020-01-25",
                "2020-01-26",
                "2020-01-27",
                "2020-01-28",
                "2020-01-29",
                "2020-01-30",
                "2020-01-31",
                "2020-02-01",
                "2020-02-02",
                "2020-02-03",
                "2020-02-04",
                "2020-02-05",
                "2020-02-06",
                "2020-02-07",
                "2020-02-08",
                "2020-02-09",
                "2020-02-10",
                "2020-02-11"
            ],
            "datasets": [
                {
                    "label": "Co2 emitted",
                    "data": [
                        139.7572082537921,
                        168.07811934018727,
                        191.21213579866847,
                        146.279372451263,
                        171.71628585647812,
                        134.91832205788992,
                        128.60579009770333,
                        128.37433824115004,
                        109.68967283754182,
                        177.19412104566553,
                        202.7451827329103,
                        147.63967192645285,
                        155.43144926666648,
                        117.25655170418426,
                        200.57651926485676,
                        107.25074398017308,
                        150.93634355440585,
                        186.78715962938298,
                        154.92222866492824,
                        188.67424349551965,
                        139.4849045634949,
                        166.24425609865028,
                        164.9062069329318,
                        118.83427601605757,
                        154.30660675482292,
                        180.72488044766808,
                        147.8493188969894,
                        147.76913589540234,
                        128.09442988670077,
                        136.92835694122323,
                        67.49087454575174
                    ]
                }
            ]
        },
        "trees": {
            "labels": [
                "2020-01-12",
                "2020-01-13",
                "2020-01-14",
                "2020-01-15",
                "2020-01-16",
                "2020-01-17",
                "2020-01-18",
                "2020-01-19",
                "2020-01-20",
                "2020-01-21",
                "2020-01-22",
                "2020-01-23",
                "2020-01-24",
                "2020-01-25",
                "2020-01-26",
                "2020-01-27",
                "2020-01-28",
                "2020-01-29",
                "2020-01-30",
                "2020-01-31",
                "2020-02-01",
                "2020-02-02",
                "2020-02-03",
                "2020-02-04",
                "2020-02-05",
                "2020-02-06",
                "2020-02-07",
                "2020-02-08",
                "2020-02-09",
                "2020-02-10",
                "2020-02-11"
            ],
            "datasets": [
                {
                    "label": "Trees Relaxed",
                    "data": [
                        -161.12280701754383,
                        -352.9101784418249,
                        -156.5182571264109,
                        -472.48271892284197,
                        -227.63540806366842,
                        -519.6622369046725,
                        -100.4380709262457,
                        -374.9404609955702,
                        -429.83357108892045,
                        -102.10826638636533,
                        -420.67352403834116,
                        -314.93745776709954,
                        -202.21217809144827,
                        -377.2643591861251,
                        -337.01038616512744,
                        -372.5067447506068,
                        -457.9043471732112,
                        -324.11151988387513,
                        -338.02978201566685,
                        -366.36694446530015,
                        -281.7258552944415,
                        -366.15311459819304,
                        -241.32932902870584,
                        -368.23164902269946,
                        -367.0591886277748,
                        -358.07322872087485,
                        -467.30275045674097,
                        -223.05888830492782,
                        -277.9946442425608,
                        -393.8751157494307,
                        -71.24859223665439
                    ]
                },
                {
                    "label": "Trees Burduned",
                    "data": [
                        2451.880846557756,
                        2948.7389357927595,
                        3354.5988736608497,
                        2566.3047798467196,
                        3012.5664185347036,
                        2366.9881062787704,
                        2256.241931538654,
                        2252.1813726517557,
                        1924.3802252200326,
                        3108.668790274834,
                        3556.933030401936,
                        2590.169682920226,
                        2726.8675309941486,
                        2057.1324860383206,
                        3518.886302892223,
                        1881.5919996521593,
                        2648.006027270279,
                        3276.9677127961922,
                        2717.9338362268113,
                        3310.0744472898186,
                        2447.1035888332435,
                        2916.5658964675495,
                        2893.091349700558,
                        2084.8118599308336,
                        2707.1334518389976,
                        3170.611937678387,
                        2593.8476999471814,
                        2592.4409806210933,
                        2247.2706997666796,
                        2402.2518761618107,
                        1184.0504306272235
                    ]
                }
            ]
        },
        "fuel": {
            "labels": [
                "2020-01-12",
                "2020-01-13",
                "2020-01-14",
                "2020-01-15",
                "2020-01-16",
                "2020-01-17",
                "2020-01-18",
                "2020-01-19",
                "2020-01-20",
                "2020-01-21",
                "2020-01-22",
                "2020-01-23",
                "2020-01-24",
                "2020-01-25",
                "2020-01-26",
                "2020-01-27",
                "2020-01-28",
                "2020-01-29",
                "2020-01-30",
                "2020-01-31",
                "2020-02-01",
                "2020-02-02",
                "2020-02-03",
                "2020-02-04",
                "2020-02-05",
                "2020-02-06",
                "2020-02-07",
                "2020-02-08",
                "2020-02-09",
                "2020-02-10",
                "2020-02-11"
            ],
            "datasets": [
                {
                    "label": "Fuel saved",
                    "data": [
                        -4.6494,
                        -9.663104422253923,
                        -4.164800285306704,
                        -12.46633694721826,
                        -6.107938373751783,
                        -13.54637717546362,
                        -2.7681261055634807,
                        -9.813428815977174,
                        -11.4642667617689,
                        -2.633422253922967,
                        -11.136630813124109,
                        -8.395006562054208,
                        -5.5396322396576325,
                        -9.774968901569187,
                        -8.539526961483595,
                        -9.542714978601996,
                        -11.718551212553495,
                        -8.905946362339515,
                        -8.910120684736091,
                        -9.267059058487874,
                        -7.404988587731811,
                        -9.85179457917261,
                        -6.559370328102711,
                        -9.985636519258204,
                        -9.86738858773181,
                        -9.562362624821683,
                        -11.782233380884449,
                        -5.989946362339514,
                        -7.634980313837375,
                        -10.616574607703281,
                        -1.971552068473609
                    ]
                },
                {
                    "label": "Fuel used",
                    "data": [
                        58.00010016298354,
                        72.9679627199936,
                        79.34283351009078,
                        61.72410244930885,
                        72.26473066194035,
                        56.01035402436413,
                        53.24689921740735,
                        55.34977513475096,
                        46.49118785549175,
                        73.00616159603099,
                        81.26434742652573,
                        62.33004390818952,
                        65.17492740665976,
                        49.887908642211634,
                        82.62474692465712,
                        45.47816772030024,
                        63.947641371682856,
                        75.91693606599462,
                        66.85874807036966,
                        77.46059768009948,
                        55.94374232860385,
                        68.9453933067781,
                        69.66198574122039,
                        50.636900742379446,
                        64.548431585913,
                        75.41665949641424,
                        61.50724462275343,
                        62.81496063898396,
                        53.58136179167125,
                        58.7128000557531,
                        27.09147782492325
                    ]
                }
            ]
        },
        "fuelStatus": {
            "labels": [
                "fuel saved",
                "fuel consumed"
            ],
            "datasets": [
                {
                    "label": "Fuel",
                    "data": [
                        260.23418687589174,
                        1948.2091306844472
                    ]
                }
            ]
        }
    }
}