import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MdOutlineCancel } from 'react-icons/md';
import { BsCheck } from 'react-icons/bs';
// import { fetchThemeData, setMode, setTheme } from '../../../features/theme/themeSlice'; // Import the theme-related actions
import "./ThemeSetting.css"
const ThemeSettings = ({Obj}) => {
  const {ThemeSetting, setThemeSetting}=Obj
const themeColor=localStorage.getItem("themeColor")
  const dispatch = useDispatch();
  const themeColors=[ {Bg:"#fffff",primary:"black",secondary:"#fea022"}  ,{Bg:"black",primary:"red",secondary:"orange"} ,{Bg:"#fffff",primary:"purple",secondary:"pink"} ,{Bg:"#fffff",primary:"yellow",secondary:"red"} ]
  // const {currentMode,currentColor} = useSelector((state) => state.theme);
    const currentMode = 'dark'
    const currentColor = '#0e0e0e'



  // useEffect(() => {
  //   // Fetch theme data when the component mounts
  //   dispatch(fetchThemeData());
  // }, [dispatch]);

  const handleModeChange = (mode) => {
    document.documentElement.style.setProperty('--db_primary', "#fea022");
    
    // dispatch(setMode(mode));
  };
const handle_PreDefined_ColorChange=(colors)=>{
  // dispatch(setTheme(colors));
}
  const handleColorChange = (e) => {
    // dispatch(setTheme({...currentColor,[e.target.name]:e.target.value}));
  };

  return (
    <div className="ThemeSetterContainer">
      <div className="ThemeSetterContainerCard">
        <div className='ThemeSetterContainerCardB1'>
          <h5>Settings</h5>
          <button className="CancelBtn"onClick={()=>setThemeSetting(false)}><MdOutlineCancel /></button>
        </div>
        <div className="ThemeSetterContainerCardB2">
          <p>Theme Option</p>
          <div >
            <input type="radio" id="light" name="theme" value="Light" onChange={() => handleModeChange('Light')} checked={currentMode === 'Light'}/>
            <label htmlFor="light">Light</label>
          </div>
          <div >
            <input type="radio"id="dark"name="theme"value="Dark"onChange={() => handleModeChange('Dark')}className="cursor-pointer"checked={currentMode === 'Dark'}/>
            <label htmlFor="dark" >Dark</label>
          </div>
        </div>
        <div className="ThemeSetterContainerCardB2">
          <p className="">Theme Colors</p>
          <div className="">
            {themeColors.map((item, index) => (
              <div className="" key={index}>  
                <button type="button" style={{backgroundColor:item.primary,padding: "1.5rem", outline:"1px solid lightgray"}}onClick={() => handle_PreDefined_ColorChange(item)}> <BsCheck className=""style={{display: item.primary === currentColor.primary ? "block" : "none" }} /> </button>
              </div>
            ))}
          </div>
        </div>
        <div className="ThemeSetterContainerCardB2">
          <p className="">Custom Colors</p>
          <div className="customColors">
            <div>
              <label htmlFor="Bg">background</label>
              <input name="Bg"type='color' style={{backgroundColor:`${currentColor.Bg }`}} value={currentColor.Bg} onChange={(e)=>handleColorChange(e)}/>
            </div>
            <div>
              <label htmlFor="primary">Primary</label>
              <input name="primary"type='color'style={{backgroundColor:`${currentColor.primary }`}}value={currentColor.primary} onChange={(e)=>handleColorChange(e)}/>
              </div>
              <div>
                <label htmlFor="secondary">Secondary</label>
                <input name="secondary"type='color'  style={{backgroundColor:`${currentColor.secondary }`}}value={currentColor.secondary} onChange={(e)=>handleColorChange(e)}/>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThemeSettings;
