import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaEdit, FaTrash } from 'react-icons/fa';
import CheckRole from '../../Utils/CheckRole/CheckRole';
import NotAuthorized from '../NotAuthorized/NotAuthorized';
import { getGroup, deleteGroup } from '../../Features/Group/GroupSlice';
import './Group.css';
import Loader from '../../Components/Loader/Loader';
import DataTable from '../../Components/DataTable/DataTable';

const Groups = () => {
  const { user } = useSelector((store) => store.auth);
  const { groups } = useSelector((store) => store.group);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getGroup());
      setLoading(false);
    };

    fetchData();
  }, [dispatch]);

  const activeGroups = groups?.filter((group) => group.isActive === 1).map((group, index) => ({ ...group, SrNo: index + 1 }));

  const columns = [
    { field: 'SrNo', headerName: 'Sr.No', width: 100 },
    { field: 'id', headerName: 'Group Id', width: 120 },
    { field: 'name', headerName: 'Group Name', width: 150 },
    {
      field: 'edit',
      headerName: 'Edit Group',
      width: 150,
      renderCell: (params) => (
        <Link className="editLink" to={`/update-group/${params.row.id}`}>
          <FaEdit />
        </Link>
      ),
    },
    {
      field: 'delete',
      headerName: 'Delete Group',
      width: 150,
      renderCell: (params) => (
        <button
          className="deleteButton"
          onClick={() => dispatch(deleteGroup(params.row.id)).then(() => dispatch(getGroup()))}
        >
          <FaTrash />
        </button>
      ),
    },
    {
      field: 'groupDetails',
      headerName: 'Group Details',
      width: 150,
      renderCell: (params) => (
        <Link className="btn" to={`/group-details/${params.row.id}`}>
          Group Details
        </Link>
      ),
    },
  ];

  return (
    (('owner' === CheckRole() || 'manager' === CheckRole()) ? (
      <div className="Group_CardContainer">
        {loading ? (
          <h5><Loader/></h5>
        ) : (
          <>
            {(activeGroups && activeGroups.length > 0) ? (
              <>
              <h4>All Groups detail</h4>
              <DataTable columns={columns} data={activeGroups} />
              </>

            ) : (
              <h5>No group added yet</h5>
            )}

            <div className='add_btn'>
              <Link to="/add-group" className='btn'>
                Add Group +
              </Link>
            </div>
          </>
        )}
      </div>
    ) : (
      <NotAuthorized />
    ))
  );
};

export default Groups;
