// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImpactCardP {
    width: 35%;
    text-align: center;
  }
  
  .CircularPathContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  
  .CircularPath {
    /* transform: rotate(-90deg); */
  }
  
  .OuterCircle {
    fill: transparent;
    stroke-width: 8;
    stroke-dasharray: 0 1000;
    stroke-dashoffset: 1000; 
    animation: revealCircle 2s ease forwards;
  }
  .InnerCircle {
    fill: transparent;
    stroke-width: 4;
    stroke-dasharray: 0 1000; 
    stroke-dashoffset: 1000; 
    animation: revealCircle 2s ease forwards;
  }
  
  @keyframes revealCircle {
    to {
      stroke-dasharray: 457 1000; /* Adjust this value to control the animation */
      stroke-dashoffset: 10;
    }
  }
  
  .HeadingandFigImpact {
    position: absolute;
    text-align: center;
    /* color:white */
  }
  

@media screen and (max-width: 650px) {

  .ImpactCardP {
    padding: 1rem;
    width: 100%;
    text-align: center;
  }
.CircularPath  {
  display: none;
}
.InnerCircle {
  display: none;
}

.OuterCircle{
  display: none;
}

.HeadingandFigImpact{
  position: relative;
  display: flex;
  gap: 1rem;
}

}
  
`, "",{"version":3,"sources":["webpack://./src/Components/ImpactPageComponents/ImpactCard/ImpactCard.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;EAChB;;;EAGA;IACE,+BAA+B;EACjC;;EAEA;IACE,iBAAiB;IACjB,eAAe;IACf,wBAAwB;IACxB,uBAAuB;IACvB,wCAAwC;EAC1C;EACA;IACE,iBAAiB;IACjB,eAAe;IACf,wBAAwB;IACxB,uBAAuB;IACvB,wCAAwC;EAC1C;;EAEA;IACE;MACE,0BAA0B,EAAE,+CAA+C;MAC3E,qBAAqB;IACvB;EACF;;EAEA;IACE,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;EAClB;;;AAGF;;EAEE;IACE,aAAa;IACb,WAAW;IACX,kBAAkB;EACpB;AACF;EACE,aAAa;AACf;AACA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,SAAS;AACX;;AAEA","sourcesContent":[".ImpactCardP {\n    width: 35%;\n    text-align: center;\n  }\n  \n  .CircularPathContainer {\n    position: relative;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-shrink: 0;\n  }\n\n  \n  .CircularPath {\n    /* transform: rotate(-90deg); */\n  }\n  \n  .OuterCircle {\n    fill: transparent;\n    stroke-width: 8;\n    stroke-dasharray: 0 1000;\n    stroke-dashoffset: 1000; \n    animation: revealCircle 2s ease forwards;\n  }\n  .InnerCircle {\n    fill: transparent;\n    stroke-width: 4;\n    stroke-dasharray: 0 1000; \n    stroke-dashoffset: 1000; \n    animation: revealCircle 2s ease forwards;\n  }\n  \n  @keyframes revealCircle {\n    to {\n      stroke-dasharray: 457 1000; /* Adjust this value to control the animation */\n      stroke-dashoffset: 10;\n    }\n  }\n  \n  .HeadingandFigImpact {\n    position: absolute;\n    text-align: center;\n    /* color:white */\n  }\n  \n\n@media screen and (max-width: 650px) {\n\n  .ImpactCardP {\n    padding: 1rem;\n    width: 100%;\n    text-align: center;\n  }\n.CircularPath  {\n  display: none;\n}\n.InnerCircle {\n  display: none;\n}\n\n.OuterCircle{\n  display: none;\n}\n\n.HeadingandFigImpact{\n  position: relative;\n  display: flex;\n  gap: 1rem;\n}\n\n}\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
