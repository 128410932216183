// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DrivingZone_S1{
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding: 1rem 0rem;
    gap:2rem;
    flex-wrap: wrap;
}
.DrivingZone_S1_C1{
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: start;
    gap:1rem;
    min-width: 60%;
    font-weight: 700;

}


@media screen and (max-width: 768px){
    .DrivingZone_S1{
        flex-direction: column;
        justify-content: center;
        width: 100%;
        gap: 2rem;
        padding: 2rem;
    }
    .DrivingZone_S1_C1{
        width: 100%;
        flex-direction:column ;
    }
}`, "",{"version":3,"sources":["webpack://./src/Pages/ProjectDetails/DrivingZone/AddDrivingZone.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;IAChB,kBAAkB;IAClB,QAAQ;IACR,eAAe;AACnB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,QAAQ;IACR,cAAc;IACd,gBAAgB;;AAEpB;;;AAGA;IACI;QACI,sBAAsB;QACtB,uBAAuB;QACvB,WAAW;QACX,SAAS;QACT,aAAa;IACjB;IACA;QACI,WAAW;QACX,sBAAsB;IAC1B;AACJ","sourcesContent":[".DrivingZone_S1{\n    display: flex;\n    justify-content: space-between;\n    align-items: end;\n    padding: 1rem 0rem;\n    gap:2rem;\n    flex-wrap: wrap;\n}\n.DrivingZone_S1_C1{\n    display: flex;\n    flex-direction: row;\n    justify-items: flex-start;\n    align-items: start;\n    gap:1rem;\n    min-width: 60%;\n    font-weight: 700;\n\n}\n\n\n@media screen and (max-width: 768px){\n    .DrivingZone_S1{\n        flex-direction: column;\n        justify-content: center;\n        width: 100%;\n        gap: 2rem;\n        padding: 2rem;\n    }\n    .DrivingZone_S1_C1{\n        width: 100%;\n        flex-direction:column ;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
