import React, { useState ,useEffect} from 'react';
// import './SignUp.css';
import { useDispatch,useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CheckRole from '../../../Utils/CheckRole/CheckRole'
import NotAuthorized from '../../NotAuthorized/NotAuthorized'
import { MdDeleteForever } from "react-icons/md";
import { MdPersonAddAlt1 } from "react-icons/md";
import { useNavigate,useParams,Link } from "react-router-dom";
import { inviteManager } from "../../../Features/ProjectDetail/ManagerSlice"
import Tooltip from "../../../Components/ToolTip/Tooltip";


const InviteManager = () => {

  const [data, setData] = useState({managerEmail: ['']});
  const { managerEmail } = data;

  const { user } = useSelector((store) => store.auth);

  const { projectId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onEmailChange = (index, value) => {
    const updatedEmails = [...managerEmail];
    updatedEmails[index] = value;
    setData((prevState) => ({ ...prevState, managerEmail: updatedEmails }));
  };

  const addEmailInput = () => {
    setData((prevState) => ({ ...prevState, managerEmail: [...managerEmail, ''] }));
  };

  const removeEmailInput = (index) => {
    const updatedEmails = [...managerEmail];
    updatedEmails.splice(index, 1);
    setData((prevState) => ({ ...prevState, managerEmail: updatedEmails }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (managerEmail[0] === "") {
      toast.info('Please provide manager email');
    } else {
      const managerData = {
        managerEmail,
        projectId
      };

      dispatch(inviteManager(managerData)).then((res)=>{res.payload.success==true && navigate(`/project-details/${projectId}/view-managers`)})
    }
  };

  useEffect(() => {
    if (!user) {
      navigate("/signin");
    }
  },[]);

  return (
    ( ('owner' === CheckRole() || 'manager' ===  CheckRole()) ? 
    (<div className='flex_row_center_center Project_Container'>
          <h4>Invite Managers to join</h4>
      <form onSubmit={onSubmit} className='flex_column form form_project'>
        <div style={{textAlign:'left', width:'100%'}}>
        </div>
        <div className='flex_column form_group form_group_project'>
          <label>Emails</label>
          {managerEmail.map((email, index) => (
            <div key={index} style={{display:'flex'}}>
              <input
                type='email'
                placeholder={`Enter email ${index + 1}`}
                value={email}
                onChange={(e) => onEmailChange(index, e.target.value)}
              />
              {index !== 0 && (
                <button style={{ fontSize: 'larger' }} type='button' onClick={() => removeEmailInput(index)}>
                  <MdDeleteForever/>
                </button>
              )}
            </div>
          ))}
          <div className='flex_column_center_center'>
          <button style={{fontSize:'larger'}} type='button' onClick={addEmailInput}>
            <MdPersonAddAlt1/>
            <Tooltip text={["click to add more members"]}/>
          </button>
          </div>
        </div>
        <div>
          <button className='btn' type='submit'>
            Invite Manager
          </button>
        </div>
      </form>
      <div className="nav_btn_group">
            <Link className='secondary_btn'to={`/project-details/${projectId}/employees/view-managers`}>View Managers</Link>
            <Link className="secondary_btn"to={`/project-details/${projectId}/employees/view-operators`}>View Operators</Link>
            <Link className="secondary_btn"to={`/project-details/${projectId}/employees/add-operator`}>Add Operator +</Link>
          </div>
    </div>) 
    : <NotAuthorized/>
    )
  );
};

export default InviteManager;
