import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { BarElement } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, BarElement,Title, Tooltip, Legend);

const width = window.innerWidth;
let height =250
if (width <= 900) {
  height = 250;
}

function HBarHorizontal({ data, title, showTitle, color,Xaxis }) {

  const options = {
    responsive: true,
    indexAxis: 'y', // Display the chart horizontally
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      title: {
        display: showTitle,
        text: title,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: Xaxis,
        },
        grid: {
          display: true,
        },
      },
      y: {
        title: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },
    maintainAspectRatio: false,
    borderRadius:'50',
    
  };
  if(color){
    data = {
     ...data,
     datasets: [
       {
         ...data.datasets[0],
         borderColor: color.primary || '#0e0e0e',
         backgroundColor:color.primary || '#0e0e0e',
         barThickness: 20, 
       }
     ]
   };
  }

  return (
    <Bar options={options} data={data} height={height} style={{ backgroundColor: 'transparent' }} />
  );
}

export default HBarHorizontal;
