import React from 'react';
import './UnderConstruction.css'; // You can create a CSS file for styling
import {MdConstruction} from 'react-icons/md'
const UnderConstruction = () => {
  return (
    <div className="under-construction-container site_padding">
      <div className="construction-icon">
        <MdConstruction/>
      </div>
      <h1 className="construction-heading">Page Under Construction</h1>
      <p className="construction-text">
        We are working hard to bring you an amazing experience. Please check back later.
      </p>
    </div>
  );
};

export default UnderConstruction;
