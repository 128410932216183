import React, {useEffect} from 'react'
import { projectDetailCards } from '../../Data/Data'
import { useSelector } from 'react-redux'
import { Outlet, useParams, useNavigate } from 'react-router-dom'
import CheckRole from '../../Utils/CheckRole/CheckRole'
import NotAuthorized from '../NotAuthorized/NotAuthorized'
import SideBar from '../../Components/SideBar/SideBar'
import MobileNav from '../../Components/MobileNav/MobileNav'



const ProjectDetails = () => {
    const {user}=useSelector((store)=>store.auth)
    const navigate= useNavigate()
    const { projectId } = useParams();

    useEffect(()=>{
        if(!user){
          navigate("/signin")
        }
    },[])
return (
( ('owner' === CheckRole() || 'manager' ===  CheckRole()) ? 
(<section className='DemoDashboard'>
    <MobileNav projectId={projectId}/> 
    <SideBar/>
    <section className='Right_container'>
    <Outlet/>
    </section>

</section>) : <NotAuthorized/>
)
)}

export default ProjectDetails
